import React from "react";

import {
  Layout, Button, Input, Table, Select, Modal, message, Tabs, Pagination, Drawer, Tree, Tooltip, Badge
} from "antd";
import {
  getdeviceList, getunitList, getIscAreaList, addcamera, deletedevice, addlogoperate, getdevicelog, getIscCamerasByIndexCode, insertDoorCameraRelation, selectDoorDevices, selectDoorCameraRelation, getIscArgs, selectOnlineDeviceSum
} from '../axios';

import "./device.scss";
import moment from 'moment';
import Socket from "./socket";
import CardReader from './cardReader'
// const datatype = {
//   1: '心跳数据',
//   2: '报警数据',
//   3: '上电数据',
//   4: '下线数据',
// }

const { DirectoryTree } = Tree;
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoListDataSource: [],
      unbindcameralist: [],
      guardlist: [],
      device_ip: null,
      bdcameralist: [],
      typenone: "inline-block",
      pageNum: 1,
      pageNumSize: 10,
      pageNums: 1,
      pageNumSizes: 10,
      unitlist: [],
      sensorlist: [],
      cameralist: [],
      cameradis: 'none',
      guarddis: 'block',
      unlook: 'none',
      deviceList: JSON.parse(localStorage.getItem('unitTree')),
      tabchange: localStorage.getItem('tabtype') || "2",
      isclist: [],

      // openSocket: false
    };
  }

  componentWillMount () {
    document.title = "设备管理";
    let arr = localStorage.getItem('menubtnlist').split(',')
    if (arr.indexOf('2022') !== -1) {
      this.setState({
        deviceadddis: 'inline'
      })
    } else {
      this.setState({
        deviceadddis: 'none'
      })
    }
    // // // //console.log(arr.indexOf('2019'))
    if (arr.indexOf('2019') !== -1) {
      this.setState({
        devicequerydis: false,
        mjlook: 'block',
      })
    } else {
      this.setState({
        devicequerydis: true,
        mjlook: 'none',
      })
    }


    if (arr.indexOf('2020') !== -1) {
      this.setState({
        cameradislook: false,
        cameralook: 'block',
      })
    } else {
      this.setState({
        cameradislook: true,
        cameralook: 'none',
      })
    }

    if (arr.indexOf('2020') === -1 && arr.indexOf('2019') === -1) {
      this.setState({
        unlook: 'flex',
      })
    }




    selectOnlineDeviceSum([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          cameraOfflineSum: res.data.data.cameraOfflineSum,
          doorOfflineSum: res.data.data.doorOfflineSum,
        })
      }
    });

  }

  componentDidMount () {
    this.getcameraList()
    this.getmenjinList()
    this.getunitList()
  }


  getmenjinList = () => {
    getdeviceList([
      localStorage.getItem('token'),
      1,
      31,
      this.state.cityid,
      this.state.areaid,
      this.state.siteId,
      this.state.searchname,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        let arr = []
        for (let i in res.data.data) {
          arr.push(res.data.data[i].id)
        }
        this.setState({
          sensorlist: res.data.data
        }, function () {
          let arr = []
          for (let i in this.state.sensorlist) {
            if ((new Date().getTime() - new Date(this.state.sensorlist[i].lastConnect).getTime()) > 3600 * 1000 * 24) {
              arr.push(this.state.sensorlist[i])
            }
          }
          this.setState({
            unusenum: arr.length
          })
          if (this.state.sensorlist.length > 10) {
            this.setState({
              sensorpage: true,
            })
          } else {
            this.setState({
              sensorpage: false,
            })
          }
        })
      }
    });
  }


  getcameraList = () => {
    getdeviceList([
      localStorage.getItem('token'),
      2,
      31,
      this.state.cityid,
      this.state.areaid,
      this.state.siteIds,
      this.state.keytext,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        let arr = []
        for (let i in res.data.data) {
          arr.push(res.data.data[i].id)
        }
        this.setState({
          cameraids: arr.join(','),
          cameralist: res.data.data
        }, function () {
          if (this.state.cameralist.length > 10) {
            this.setState({
              camerapage: true,
            })
          } else {
            this.setState({
              camerapage: false,
            })
          }
        })
      }
    });
  }

  //打开添加设备弹窗
  adddevice = () => {
    this.getunitList()
    this.setState({
      devicevisible: true,
    })
  }

  //获取单位列表
  getunitList = () => {
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        let arr = []
        for (let i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }


  //关闭弹窗
  handleCancel = () => {
    this.setState({
      changevisible: false,
      devicevisible: false,
      devicedeletevisible: false,
      cameravisible: false,
      devicechangevisible: false,
      recordvisible: false,
      sensorid: undefined,
      cameraid: undefined,
      deviceIds: undefined,
      unitnames: undefined,
      locations: undefined,
      deviceId: undefined,
      imei: undefined,
      unitname: undefined,
      location: undefined,
      camerarecord: undefined,
      activevisible: false,
      devicetype: undefined,
      cameraname: undefined,
      iscArgsId: undefined,
    })
  }

  //关闭弹窗
  handleCancels = () => {
    this.setState({
      videovisible: false,
      videourl: undefined,
    })
  }


  //设备类型选择
  devicetype = (value) => {
    // // //console.log(value)
    if (value === 1) {
      this.setState({
        devicetype: value,
        cameradis: 'none',
        guarddis: 'block'
      })

      // selectDoorDevices([

      // ]).then(res => {
      //     if (res.data && res.data.message === "success") {
      //         this.setState({
      //             guardlist: res.data.data.data.list
      //         }, function () {
      //             // // //console.log(this.state.guardlist)
      //         })
      //     }
      // });
    }
    if (value === 2) {
      this.setState({
        devicetype: value,
        cameradis: 'block',
        guarddis: 'none'
      })
    }

  }



  //传感器编号
  deviceIds = (value) => {
    // // //console.log(value)
    this.setState({
      deviceIds: value
    })
  }

  //iscId
  changeisc = (value) => {
    this.setState({
      iscArgsId: value
    }, function () {
      selectDoorDevices([
        this.state.iscArgsId
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          if (!res.data.data.data) {
            this.setState({
              guardlist: []
            })
          } else {
            this.setState({
              guardlist: res.data.data.data.list
            }, function () {
              // // //console.log(this.state.guardlist)
            })
          }

        }
      });
    })
  }

  //设备位置
  location = (e) => {
    this.setState({
      location: e.target.value
    })
  }

  //传感器设备位置
  locations = (e) => {
    this.setState({
      locations: e.target.value
    })
  }

  //所属单位
  unitname = (value) => {
    this.setState({
      unitname: value
    })
  }

  //传感器所属单位
  unitnames = (value) => {
    this.setState({
      unitnames: value
    })
  }

  //添加摄像头
  cameraOk = () => {
    addcamera([
      this.state.devicetype,
      this.state.deviceIds,
      this.state.unitnames,
      this.state.locations,
      this.state.iscArgsId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('添加成功')

        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname")}在${this.state.unitnames}单位中添加了一个${this.state.devicetype === 1 ? "门禁" : "摄像头"}，(其中设备编号为${this.state.deviceIds}
                   ，设备位置为 ${this.state.locations})。`,
          3,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "设备管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });

        this.setState({
          cameravisible: false,
          cameraid: undefined
        })
        this.handleCancel()
        this.getcameraList()
        this.getmenjinList()
      } else {
        message.error(res.data.message)
      }
    });
  }

  devicechangeOk = () => {
    addcamera([
      this.state.devicetype,
      this.state.deviceIds,
      this.state.unitnames,
      this.state.locations,
      this.state.iscArgsId,
      this.state.cameraid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('修改成功')
        this.setState({
          devicechangevisible: false,
          cameraid: undefined
        })
        this.handleCancel()
        this.getcameraList()
        this.getmenjinList()
      } else {
        message.error(res.data.message)
      }
    });
  }


  //打开删除设备弹窗
  devicedelete = (text, record, index) => {
    this.setState({
      devicedeletevisible: true,
      deviceid: record.id,
      personunitname: record.unit
    })
  }

  //编辑设备
  cameraedit = (text, record, index) => {
    // //console.log(record)
    this.getunitList()
    this.setState({
      deviceIds: record.deviceId,
      devicetype: record.deviceType,
      cameraid: record.id,
      iscArgsId: record.iscArgsId,
      cameraname: record.deviceId,
      unitnames: record.unitId.toString(),
      locations: record.location,
      devicechangevisible: true
    })

    if (record.deviceType === 1) {
      this.setState({
        cameradis: 'none',
        guarddis: 'block'
      })
    }
    if (record.deviceType === 2) {
      this.setState({
        cameradis: 'block',
        guarddis: 'none'
      })
    }
  }

  //删除设备
  deletedevice = () => {
    deletedevice([
      this.state.deviceid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')

        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "删除了" + this.state.personunitname + "下面设备编号为" + this.state.deviceid + "的设备"} `,
          4,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "设备管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });
        this.setState({
          devicedeletevisible: false,
        })
        this.getcameraList()
        this.getmenjinList()
      } else {
        message.error(res.data.message)
      }
    });
  }

  //设备位置选择
  addresschange = (e) => {
    // //console.log(e)
    this.setState({
      addresslist: e,
      cityid: e[0] === undefined ? null : e[0],
      areaid: e[1] === undefined ? null : e[1],
      siteId: e[2] === undefined ? null : e[2]
    });
  }

  //搜索框录入
  searchname = (e) => {
    this.setState({
      searchname: e.target.value
    })
  }



  //imei号输入
  imei = (e) => {
    this.setState({
      imei: e.target.value.replace(/[^0-9.]/g, '').length > 15 ? e.target.value.substring(0, 15) : e.target.value.replace(/[^0-9.]/g, '')
    })
  }


  //搜索
  onsearch = () => {
    this.getmenjinList()
  }

  //输入关键字
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })

  }

  //搜索
  query = () => {
    this.getcameraList()
  }


  //打开添加设备弹窗
  addcamera = () => {
    if (this.state.tabchange === '3') {
      // //console.log('111 :>> ', 111);
      this.CardChild.addCard()
    } else if (this.state.tabchange === '4') {
      this.Child.openSocket()
    } else {
      this.getunitList()
      getIscArgs([

      ]).then(res => {
        if (res.data && res.data.message === "success") {
          this.setState({
            isclist: res.data.data
          })
        }
      })
      this.setState({
        cameravisible: true,
        devicetype: undefined,
        cameradis: 'none',
        guarddis: 'block'
      })
    }
  }




  //传感器上下线记录
  lookcamera = (text, record, index) => {
    // //console.log(record)
    this.setState({
      deviceId: record.deviceId
    }, function () {
      getdevicelog([
        this.state.pageNum,
        this.state.pageNumSize,
        this.state.deviceId,
        2,
        [3, 4].join(',')
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          this.setState({
            recordlist: res.data.data.list,
            camerarecord: true,
            cameratotal: res.data.data.total
          })
        }
      });
    })

  }


  //传感器页数变化
  pagechange = (page, b) => {
    // //console.log(page, b)
    this.setState({
      pageNum: page,
      pageNumSize: b,
    }, function () {
      getdevicelog([
        this.state.pageNum,
        this.state.pageNumSize,
        this.state.deviceId,
        2,
        [3, 4].join(',')
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          this.setState({
            recordlist: res.data.data.list,
            camerarecord: true,
            cameratotal: res.data.data.total
          })
        }
      });
    })
  }





  //传感器数据导出
  export = () => {
    // if (this.state.cameraids === "") {
    //     message.error('请选择要导出的数据')
    // } else {
    //     window.open(url + '/api/v1/device/export?access_token=' + localStorage.getItem('token') + "&deviceType=2&ids=" + this.state.cameraids, "_self")
    // }
  }


  //打开摄像头绑定弹窗
  bindcamera = (text, record, index) => {
    // //console.log(record)
    this.setState({
      doorDeviceId: record.id,
      cameralocation: record.location,
      changevisible: true
    })


    getdeviceList([
      localStorage.getItem('token'),
      2,
      31,
      this.state.cityid,
      this.state.areaid,
      record.unitId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          bdcameralist: res.data.data
        })
      }
    });



    selectDoorCameraRelation([
      text,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        let arr = []
        for (let i in res.data.data) {
          arr.push(res.data.data[i].id.toString())
        }
        this.setState({
          worktype: arr,
        })
      }
    });


  }




  //传感器数据筛选
  cameralistchange = (a, b, c, d) => {
    // //console.log(d)
    // //console.log(b.deviceType)
    if (b.deviceType === null) {
      this.setState({
        deviceType: null,
      }, function () {
        this.getcameraList()
      })
    } else {
      this.setState({
        deviceType: b.deviceType.toString(),
      }, function () {
        this.getcameraList()
      })
    }
    // let arr = []
    // for (let i in d.currentDataSource) {
    //     arr.push(d.currentDataSource[i].id)
    // }
    // this.setState({
    //     cameraids: arr.join(',')
    // })
  }


  //打开实时画面
  oprealLive = (text, record, index) => {
    localStorage.setItem('cameraindexcode', record.deviceId)
    localStorage.setItem('cameralocation', record.location)
    localStorage.setItem('camerahotelname', record.unit)
    localStorage.setItem('cameraappkey', record.iscArgsId === 2 ? "27517347" : "21918920")
    localStorage.setItem('cameraappsecret', record.iscArgsId === 2 ? "ehPknvh9I9UGAHY1jGdC" : "J9s2irFNCyhA6TSKosBc")
    localStorage.setItem('camerahost', record.iscArgsId === 2 ? "122.224.50.67" : "118.178.57.33")
    localStorage.setItem('cameraport', record.iscArgsId === 2 ? 81 : 4443)
    window.location.href = '/app/playback';
  }

  movieback = (text, record, index) => {
    let begin = moment((new Date().getTime() - 1000 * 60 * 60 * 24 * 7))
    let end = moment((new Date().getTime() - 1000 * 60 * 60 * 24))
    localStorage.setItem('cameraindexcode', record.deviceId)
    localStorage.setItem('cameralocation', record.location)
    localStorage.setItem('camerahotelname', record.unit)
    localStorage.setItem('alarmbegin', begin)
    localStorage.setItem('alarmend', end)
    localStorage.setItem('backtype', "device")
    localStorage.setItem('cameraappkey', record.iscArgsId === 2 ? "27517347" : "21918920")
    localStorage.setItem('cameraappsecret', record.iscArgsId === 2 ? "ehPknvh9I9UGAHY1jGdC" : "J9s2irFNCyhA6TSKosBc")
    localStorage.setItem('camerahost', record.iscArgsId === 2 ? "122.224.50.67" : "118.178.57.33")
    localStorage.setItem('cameraport', record.iscArgsId === 2 ? 81 : 4443)
    window.location.href = '/app/videoback';
  }



  //摄像头列表
  iscquery = () => {
    if (!this.state.iscArgsId) {
      message.error('请选择ISC平台')
    } else {
      getIscAreaList([
        this.state.iscArgsId
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          this.setState({
            iscvisible: true
          })
          const ass = (data) => {
            let item = [];
            data.forEach((list, i) => {
              let newData = {};
              newData.key = list.indexCode;
              newData.value = list.indexCode;
              newData.title = list.name;
              newData.children = list.children ? ass(list.children) : [];    //如果还有子集，就再次调用自己
              item.push(newData);
            });
            return item;
          }

          this.setState({
            treeData: ass(res.data.data),
          })


        }
      });
    }


  }



  //table选择
  onSelectChange = (selectedRowKeys, b) => {
    // //console.log(b)
    // //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({
      selectedRowKeys,
      indexCode: b[0].cameraIndexCode,
      cameraname: b[0].cameraName,
      deviceIds: b[0].cameraIndexCode,
    }, function () {
      // //console.log(this.state.cameraname)
    });
  };

  //树形图选择
  onSelects = (keys, event) => {
    // //console.log(keys)
    // //console.log(event)
    getIscCamerasByIndexCode([
      keys.join(','),
      this.state.iscArgsId
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        for (let i in res.data.data.list) {
          res.data.data.list[i].key = res.data.data.list[i].cameraIndexCode
        }
        this.setState({
          iscdata: res.data.data.list
        })
      }
    });
  }



  //关闭摄像头弹窗
  submitcamera = () => {
    this.setState({
      iscvisible: false,
    })
  }

  //关闭抽屉
  onClose = () => {
    this.setState({
      iscvisible: false,
    })
  }

  //摄像头
  worktype = (value) => {
    // //console.log(value)
    this.setState({
      worktype: value,
      cameraDeviceId: value.join(',')
    })
  }

  //绑定摄像头
  submit = () => {
    insertDoorCameraRelation([
      this.state.doorDeviceId,
      this.state.cameraDeviceId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('绑定成功')
        this.setState({
          changevisible: false,
        })
      } else {
        message.error(res.data.message)
      }
    });

  }

  //选择单位
  changeunit = (value) => {
    this.setState({
      siteId: value
    }, function () {
      this.getmenjinList()
    })
  }

  //选择单位
  changeunits = (value) => {
    this.setState({
      siteIds: value
    }, function () {
      this.getcameraList()
    })
  }


  //切换面板
  tabchange = (value) => {
    // // //console.log(value)
    localStorage.setItem('tabtype', value)
    this.setState({
      tabchange: value,
    })
  }


  render () {

    const prooptions = this.state.unitlist.map((province) => <Option key={province.id}  >{province.name}</Option>);
    const guardoptions = this.state.guardlist.map((province) => <Option key={province.indexCode}  >{province.name}</Option>);
    const iscoptions = this.state.isclist.map((province) => <Option key={province.id}  >{province.name}</Option>);
    const cameraoptions = this.state.bdcameralist.map((province) => <Option key={province.id}  >{province.location}</Option>);

    this.camerarecordColumns = [
      {
        title: "数据类型",
        dataIndex: "dataType",
        render: (text, record, index) => {
          if (text === 3) {
            return (
              <div style={{ color: '#1eb333' }}>
                在线
              </div>
            )
          }
          if (text === 4) {
            return (
              <div style={{ color: '#f55238' }}>
                离线
              </div>
            )
          }
        }
      },
      {
        title: "设备编号",
        dataIndex: "deviceId",
      },
      {
        title: "上报时间",
        dataIndex: "gmtCreate",
      }
    ];


    // this.recordColumns = [
    //   {
    //     title: "数据类型",
    //     dataIndex: "dataType",
    //     render: (text, record, index) => {
    //       return (
    //         <div>
    //           {datatype[text]}
    //         </div>
    //       )
    //     }
    //   },
    //   {
    //     title: "设备编号",
    //     dataIndex: "deviceId",
    //   },
    //   {
    //     title: "上报时间",
    //     dataIndex: "gmtCreate",
    //   }
    // ];

    if (localStorage.getItem('menubtnlist').split(',').indexOf("2021") === -1 || localStorage.getItem('menubtnlist').split(',').indexOf("2023") === -1) {
      this.sensorColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        }, {
          title: "所属安防平台",
          dataIndex: "iscName",
        },
        {
          title: "安装位置",
          dataIndex: "location",
        },
        {
          title: "摄像头绑定",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.bindcamera(text, record, index)}
              >
                查看
              </div>
            )
          }
        },
        {
          title: "设备状态",
          dataIndex: "statusConnect",
          filters: [
            { text: "在线", value: 1 },
            { text: "离线", value: 0 },
          ],
          onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
          render: (text, record, index) => {
            if (text === 1) {
              return (
                <div style={{ color: '#1eb333', cursor: 'pointer' }}
                >
                  在线
                </div>
              )
            }
            if (text === 0) {
              return (
                <div style={{ color: '#f55238', cursor: 'pointer' }}
                >
                  离线
                </div>
              )
            }
          }
        },
        {
          title: "最新一次上报时间",
          dataIndex: "lastConnect",
          align: 'center',
          // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              if ((new Date().getTime() - new Date(text).getTime()) > 3600 * 1000 * 24) {
                return (
                  <div style={{ color: 'red' }}>
                    {text}
                  </div>
                )
              } else {
                return (
                  <div>
                    {text}
                  </div>
                )
              }
            }

          }
        }, {
          title: "添加时间",
          dataIndex: "gmtCreate",
          align: 'center',
          // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        },
      ];
    } else {
      this.sensorColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        }, {
          title: "所属安防平台",
          dataIndex: "iscName",
        },
        {
          title: "安装位置",
          dataIndex: "location",
        },
        {
          title: "摄像头绑定",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.bindcamera(text, record, index)}
              >
                查看
              </div>
            )
          }
        },
        {
          title: "设备状态",
          dataIndex: "statusConnect",
          filters: [
            { text: "在线", value: 1 },
            { text: "离线", value: 0 },
          ],
          onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
          render: (text, record, index) => {
            if (text === 1) {
              return (
                <div style={{ color: '#1eb333', cursor: 'pointer' }}
                >
                  在线
                </div>
              )
            }
            if (text === 0) {
              return (
                <div style={{ color: '#f55238', cursor: 'pointer' }}
                >
                  离线
                </div>
              )
            }
          }
        },
        {
          title: "最新一次上报时间",
          dataIndex: "lastConnect",
          align: 'center',
          // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              if ((new Date().getTime() - new Date(text).getTime()) > 3600 * 1000 * 24) {
                return (
                  <div style={{ color: 'red' }}>
                    {text}
                  </div>
                )
              } else {
                return (
                  <div>
                    {text}
                  </div>
                )
              }
            }
          }
        }, {
          title: "添加时间",
          dataIndex: "gmtCreate",
          align: 'center',
          // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.cameraedit(text, record, index)}
                >
                  <img src={require('../images/edit.png')} alt="" />
                </span>
                <span style={{ marginLeft: '10px' }}
                  onClick={() => this.devicedelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        }
      ];
    }



    if (localStorage.getItem('menubtnlist').split(',').indexOf("2021") === -1 || localStorage.getItem('menubtnlist').split(',').indexOf("2023") === -1) {
      this.cameraColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        }, {
          title: "所属安防平台",
          dataIndex: "iscName",
        },
        {
          title: "安装位置",
          dataIndex: "location",
        },
        {
          title: "设备状态",
          dataIndex: "statusConnect",
          align: 'center',
          filters: [
            { text: "在线", value: 1 },
            { text: "离线", value: 0 },
          ],
          onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
          render: (text, record, index) => {
            if (text === 1) {
              return (
                <div style={{ color: '#1eb333', cursor: 'pointer' }}
                // onClick={() => this.lookcamera(text, record, index)} 
                >
                  在线
                </div>
              )
            }
            if (text === 0) {
              return (
                <div style={{ color: '#f55238', cursor: 'pointer' }}
                // onClick={() => this.lookcamera(text, record, index)}
                >
                  离线
                </div>
              )
            }
          }
        }
        // , {
        //     title: "激活状态",
        //     dataIndex: "isActive",
        //     align: 'center',
        //     render: (text, record, index) => {
        //         if (text === true) {
        //             return (
        //                 <div style={{ color: '#1EB333', cursor: 'pointer' }}
        //                 // onClick={() => this.lookcamera(text, record, index)} 
        //                 >
        //                     激活
        //                 </div>
        //             )
        //         }
        //         if (text === false || !text) {
        //             return (
        //                 <div style={{ color: '#F55238', cursor: 'pointer' }}
        //                 // onClick={() => this.lookcamera(text, record, index)}
        //                 >
        //                     未激活
        //                 </div>
        //             )
        //         }
        //     }
        // }
        , {
          title: "实时画面",
          dataIndex: "statusConnect",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.oprealLive(text, record, index)}
              >
                查看
              </div>
            )
          }
        }, {
          title: "视频回放",
          dataIndex: "statusConnect",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.movieback(text, record, index)}
              >
                查看
              </div>
            )
          }
        },
        // {
        //     title: "短信推送",
        //     dataIndex: "notifier",
        //     render: (text, record, index) => {
        //         return (
        //             <div>
        //                 <Switch defaultChecked
        //                     checked={text}
        //                     onChange={() => this.changefeedback(text, index, record)}
        //                 />
        //             </div>
        //         );
        //     }
        // }, 
        {
          title: "添加时间",
          dataIndex: "gmtCreate",
          align: 'center',
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        }
      ];
    } else {
      this.cameraColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        }, {
          title: "所属安防平台",
          dataIndex: "iscName",
        },
        {
          title: "安装位置",
          dataIndex: "location",
        },
        {
          title: "设备状态",
          dataIndex: "statusConnect",
          align: 'center',
          filters: [
            { text: "在线", value: 1 },
            { text: "离线", value: 0 },
          ],
          onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
          render: (text, record, index) => {
            if (text === 1) {
              return (
                <div style={{ color: '#1eb333', cursor: 'pointer' }}
                // onClick={() => this.lookcamera(text, record, index)} 
                >
                  在线
                </div>
              )
            }
            if (text === 0) {
              return (
                <div style={{ color: '#f55238', cursor: 'pointer' }}
                // onClick={() => this.lookcamera(text, record, index)}
                >
                  离线
                </div>
              )
            }
          }
        }
        , {
          title: "实时画面",
          dataIndex: "statusConnect",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.oprealLive(text, record, index)}
              >
                查看
              </div>
            )
          }
        }, {
          title: "视频回放",
          dataIndex: "statusConnect",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.movieback(text, record, index)}
              >
                查看
              </div>
            )
          }
        },
        // {
        //     title: "短信推送",
        //     dataIndex: "notifier",
        //     render: (text, record, index) => {
        //         return (
        //             <div>
        //                 <Switch defaultChecked
        //                     checked={text}
        //                     onChange={() => this.changefeedback(text, index, record)}
        //                 />
        //             </div>
        //         );
        //     }
        // }, 
        {
          title: "添加时间",
          dataIndex: "gmtCreate",
          align: 'center',
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.cameraedit(text, record, index)}
                >
                  <img src={require('../images/edit.png')} alt="" />
                </span>
                <span style={{ marginLeft: '10px' }} onClick={() => this.devicedelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        }
      ];
    }



    const components = {
      // body: {
      //     row: EditableFormRow,
      //     cell: EditableCell,
      // },
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      type: "radio",
    };

    this.isclistcolumns = [

      {
        title: "设备名称",
        dataIndex: "cameraName",
        key: 'cameraName',
      }, {
        title: "设备类型",
        dataIndex: "cameraTypeName",
        key: 'cameraTypeName',
      }, {
        title: "IndexCode",
        dataIndex: "cameraIndexCode",
        key: 'cameraIndexCode',
      }, {
        title: "创建时间",
        dataIndex: "createTime",
        key: 'createTime',
        render: (text, record, index) => {
          return (
            <div>
              {moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          )
        }
      },
    ];


    const operations = <Button type="primary" onClick={this.addcamera} style={{ display: this.state.deviceadddis }} >
      添加设备
    </Button>;

    const listion = <img src={require('../images/close.png')} alt="" />


    return (
      <Layout id="devices" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <div>
              <Tabs defaultActiveKey="1" tabBarExtraContent={operations} activeKey={this.state.tabchange} onChange={this.tabchange}  >
                <TabPane tab={<Badge count={this.state.doorOfflineSum} showZero={false} size="small" >
                  门禁设备
                </Badge>} key="1" disabled={this.state.devicequerydis}>
                  <div className="contentmain" style={{ display: this.state.mjlook }}>
                    <div className="contain_main">
                      <div>
                        <span>所属单位：</span>
                        <Select
                          style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                          placeholder="请选择所属单位"
                          onChange={this.changeunit}
                          value={this.state.siteId}
                          allowClear
                        >
                          {prooptions}
                        </Select>
                        <Search placeholder="请输入设备编号"
                          onSearch={this.onsearch}
                          onChange={this.searchname}
                          value={this.state.searchname}
                          enterButton
                          style={{ marginBottom: '20px', width: '300px' }}
                        />
                      </div>

                    </div>
                    <Table
                      dataSource={this.state.sensorlist}
                      columns={this.sensorColumns}
                      pagination={this.state.sensorpage}
                      components={components}
                      rowKey={record => record.id}
                    />
                  </div>
                </TabPane>
                <TabPane tab={<Badge count={this.state.cameraOfflineSum} showZero={false} size="small" >
                  摄像头
                </Badge>} key="2" disabled={this.state.cameradislook}>
                  <div className="contentmain" style={{ display: this.state.cameralook }}>
                    <span>所属单位：</span>
                    <Select
                      style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                      placeholder="请选择所属单位"
                      onChange={this.changeunits}
                      value={this.state.siteIds}
                      allowClear
                    >
                      {prooptions}
                    </Select>
                    <Search placeholder="请输入设备编号"
                      onSearch={this.query}
                      onChange={this.keytext}
                      value={this.state.keytext}
                      enterButton style={{ marginBottom: '20px', width: '300px' }}
                    />
                    <div>
                      <Table
                        dataSource={this.state.cameralist}
                        columns={this.cameraColumns}
                        pagination={this.state.camerapage}
                        rowKey={record => record.id}
                      />
                    </div>
                  </div>
                  <div className="unpower" style={{ display: this.state.unlook }}>
                    您暂无权限查看
                  </div>
                </TabPane>
                <TabPane tab={<Badge count={0} showZero={false} size="small" >
                  读卡器
                </Badge>} key="3" >
                  <CardReader unitlist={this.state.unitlist} onRef={node => this.CardChild = node} />
                </TabPane>
                <TabPane tab={<Badge count={0} showZero={false} size="small" >
                  插座
                </Badge>} key="4" disabled={this.state.cameradislook}>
                  <Socket unitlist={this.state.unitlist} onRef={node => this.Child = node} />
                </TabPane>
              </Tabs>

            </div>
          </Content>
          <Modal
            title="删除设备"
            open={this.state.devicedeletevisible}
            onOk={this.deletedevice}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该设备吗？
          </Modal>
          <Modal
            title="传感器上下线记录"
            open={this.state.camerarecord}
            width="550px"
            centered
            footer={null}
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <div className="modeltable">
              <Table
                dataSource={this.state.recordlist}
                columns={this.camerarecordColumns}
                pagination={false}
                bordered
              />
            </div>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                onShowSizeChange={this.onShowSizeChange}
                defaultCurrent={1}
                onChange={this.pagechange}
                total={this.state.cameratotal}
                hideOnSinglePage={true}
              />
            </div>
          </Modal>
          <Modal
            title="绑定摄像头"
            open={this.state.changevisible}
            onCancel={this.handleCancel}
            onOk={this.submit}
            centered
            okText="确认"
            width="400px"
          >
            <span>门禁位置：</span>
            <Input placeholder="请输入门禁位置"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px', textAlign: 'left' }}
              autoComplete="off"
              disabled
              value={this.state.cameralocation}
            />
            <span>绑定摄像头：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择具备业务功能"
              onChange={this.worktype}
              value={this.state.worktype}
              mode="tags"
            >
              {cameraoptions}
            </Select>
          </Modal>
          <Modal
            title="添加设备"
            open={this.state.cameravisible}
            onOk={this.cameraOk}
            width="400px"
            okText="确认"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
            destroyOnClose
            zIndex={1}
          >

            <span>设备类型：</span>
            <Select placeholder="请选择设备类型" style={{ width: "100%", marginBottom: "10px", marginTop: '10px' }} onChange={this.devicetype} value={this.state.devicetype}>
              <Option value={1}>门禁</Option>
              <Option value={2}>摄像头</Option>
            </Select>

            {/* <Search
                            placeholder="请选择门禁设备"
                            enterButton="门禁设备列表"
                            size="middle"
                            onSearch={this.iscquery}
                            value={this.state.cameraname}
                            onChange={this.cameraname}
                            style={{ width: '100%', marginBottom: "5px", marginTop: '5px', fontSize: '14px', display: this.state.guarddis }}
                        /> */}
            {/* <AutoComplete
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px', display: this.state.guarddis }}
                            dataSource={this.state.guardlist}
                            placeholder="请选择门禁设备"
                            onChange={this.deviceIds}
                            filterOption={(inputValue, option) =>
                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        /> */}
            <span>ISC平台列表：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择ISC平台"
              onChange={this.changeisc}
              value={this.state.iscArgsId}
            >
              {iscoptions}
            </Select>
            <span style={{ display: this.state.guarddis }}>设备编号：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px', display: this.state.guarddis }}
              placeholder="请选择设备编号"
              onChange={this.deviceIds}
              value={this.state.deviceIds}
            >
              {guardoptions}
            </Select>
            <span style={{ display: this.state.cameradis }}>摄像头：</span>
            <Search
              placeholder="请选择摄像头"
              enterButton="摄像头列表"
              size="middle"
              onSearch={this.iscquery}
              value={this.state.cameraname}
              style={{ width: '100%', marginBottom: "5px", marginTop: '5px', fontSize: '14px', display: this.state.cameradis }}
            />
            <span>所属单位：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择所属单位"
              onChange={this.unitnames}
              value={this.state.unitnames}
            >
              {prooptions}
            </Select>
            <span>设备位置：</span>
            <Input placeholder="请输入设备位置"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.locations}
              value={this.state.locations}
            />
          </Modal>

          <Modal
            title="修改设备"
            open={this.state.devicechangevisible}
            onOk={this.devicechangeOk}
            width="400px"
            okText="确认"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
            destroyOnClose
            zIndex={1}
          >

            <span>设备类型：</span>
            <Select placeholder="请选择设备类型" style={{ width: "100%", marginBottom: "10px", marginTop: '10px' }} onChange={this.devicetype}
              disabled
              value={this.state.devicetype}>
              <Option value={1}>门禁</Option>
              <Option value={2}>摄像头</Option>
            </Select>
            <span style={{ display: 'block' }}>设备编号：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px', display: this.state.guarddis }}
              placeholder="请选择设备编号"
              onChange={this.deviceIds}
              value={this.state.deviceIds}
            >
              {guardoptions}
            </Select>
            <Search
              placeholder="请选择摄像头"
              enterButton="摄像头列表"
              size="middle"
              onSearch={this.iscquery}
              value={this.state.cameraname}
              style={{ width: '100%', marginBottom: "5px", marginTop: '5px', fontSize: '14px', display: this.state.cameradis }}
            />
            <span>所属单位：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择所属单位"
              onChange={this.unitnames}
              value={this.state.unitnames}
            >
              {prooptions}
            </Select>
            <span>设备位置：</span>
            <Input placeholder="请输入设备位置"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.locations}
              value={this.state.locations}
            />
          </Modal>

          <Drawer
            title="设备列表"
            width={1100}
            onClose={this.onClose}
            open={this.state.iscvisible}
            bodyStyle={{ paddingBottom: 80 }}
            zIndex={999}
          >
            <div className="drawermain">
              <div>
                <DirectoryTree
                  fieldNames={{ title: 'title', key: 'key' }}
                  multiple
                  defaultExpandAll
                  onSelect={this.onSelects}
                  // onExpand={onExpand}
                  treeData={this.state.treeData}
                />
              </div>
              <div style={{ width: '650px' }}>
                < Table
                  // components={components} 
                  dataSource={this.state.iscdata}
                  rowSelection={rowSelection}
                  columns={this.isclistcolumns}
                  bordered
                />
                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                  <Button type="primary" onClick={this.submitcamera}>确定</Button>
                </div>
              </div>
            </div>

          </Drawer>


        </Layout >
      </Layout >
    );
  }
}

export default App;
