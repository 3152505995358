import React, { Component } from 'react';
import { } from 'antd';
import './App.css';
import {
  Layout,
  Menu,
  ConfigProvider
} from "antd";
import {
  selectOnlineDeviceSum
} from './axios';

import { Route, Switch, Link } from 'react-router-dom';
import { DashboardOutlined, PieChartOutlined, AlertOutlined, CopyOutlined, BankOutlined, LaptopOutlined, UserOutlined, ReadOutlined, UsergroupDeleteOutlined, VideoCameraOutlined, ThunderboltOutlined, TeamOutlined, FileTextOutlined, FileProtectOutlined, SettingOutlined } from '@ant-design/icons';
import alarm from "./alarm/alarm";
import unit from "./unit/unit";
import device from "./device/device";
import user from "./user/user";
import log from "./log/log";
import person from "./person/person";
import statistics from "./statistics/statistics";
import record from "./record/record";
import playback from "./playback/playback";
import alarmback from "./alarmback/alarmback";
import videoback from "./videoback/videoback";
import warn from "./warn/warn";
import videoboard from "./videoboard/videoboard";
import power from "./power/power";
import workdetail from "./workdetail/workdetail";
import workdreport from "./workdreport/workdreport";
import workmain from "./workmain/workmain";
import stay from './stay/stay'
import air from './airsterilization/index'

import Headers from './headers';
import moment from 'moment';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const { Content, Sider, Header } = Layout;
const SubMenu = Menu.SubMenu;

const icon = {
  2000: <BankOutlined />,
  2007: <VideoCameraOutlined />,
  2024: <ThunderboltOutlined />,
  2009: <UserOutlined />,
  2008: <FileTextOutlined />,
  2006: < LaptopOutlined />,
  2005: <ReadOutlined />,
  2004: <PieChartOutlined />,
  2003: <AlertOutlined />,
  2002: <AlertOutlined />,
  100: <UsergroupDeleteOutlined />,
  2052: <CopyOutlined />,
  2053: <TeamOutlined />,
  2054: <FileProtectOutlined />
}

// const path = {
//   6: '/app/statistics',
//   3: '/app/alarm',
//   2: '/app/device',
//   5: '/app/unit',
//   4: '/app/user',
//   10: '/app/person',
//   9: '/app/log',
//   11: '/app/monitor',
//   18: '/app/logging',
//   19: '/app/air',
// }

class App extends Component {
  state = {
    mode: "inline",
    openKey: "",
    usertypenone: 'block',
    disnone: "none",
    datavdis: "none",
    menulist: [],
    menumain: [],
    menuChildren: [],
    menuid: localStorage.getItem("menuid")
  };

  componentwillMount = () => {


  }
  componentDidMount () {
    // //console.log()
    var arr = []
    var a = JSON.parse(localStorage.getItem('menulist'))
    for (var i in a) {
      if (a[i].parentId === 0) {
        arr.push(a[i])
      }
    }

    for (var j in arr) {
      if (arr[j].path === this.props.location.pathname) {
        //console.log(arr[j])
        this.setState({
          menuid: arr[j].menuId ? arr[j].menuId.toString() : null
        })
      }
    }
    let children = arr.map(el => {
      if (el.menuName === '用户管理' || el.menuName === '权限管理' || el.menuName === '日志管理' || el.menuName === '个人中心') {
        return el
      } else {
        return false
      }
    })
    children = children.filter(el => el !== false)
    // console.log('arr :>> ', children);
    let newarr = arr.map(el => {
      const item = children.find(i => el.menuId === i.menuId)
      // console.log('item :>> ', item);
      if (!item) {
        return el
      } else {
        return false
      }
    })
    newarr = newarr.filter(el => el !== false)
    // console.log('newarr :>> ', newarr);
    newarr = newarr.filter(el => el.menuName !== '工作报告' && el.menuName !== '查询统计')
    // console.log('newarr :>> ', newarr);
    // newarr = newarr.map(el => {
    //   return this.getItem(el.menuName, el.menuId, icon[item.menuId])
    // })
    // console.log('newarr :>> ', newarr);
    console.log('children :>> ', children);
    this.setState({
      menumain: newarr,
      menuChildren: children
    }, function () {


    })

    selectOnlineDeviceSum([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        //console.log(1)
        this.setState({
          totalnum: res.data.data.cameraOfflineSum + res.data.data.doorOfflineSum,
        }, function () {
          //console.log(this.state.totalnum)
        })
      }
    });

  }

  menuClick = e => {
    //console.log(e.key)
    this.setState({
      menuid: e.key
    })
    localStorage.setItem("menuid", e.key)
    //console.log(localStorage.getItem("menuid"))
  };

  getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  render () {
    // const menuoption = this.state.menulist.map((province) =>
    //   <Menu.Item key={province.id}
    //   >
    //     <Link to={path[province.id]}>
    //       {icon[province.id]}
    //       <span>{province.name}</span>
    //     </Link>
    //   </Menu.Item>
    // );
    return (
      <ConfigProvider locale={zh_CN}>
        <div className="bodymain">
          <Header >
            <Headers />
          </Header>
          <Layout>
            <div className="bodyleft">
              <Sider
                onMouseEnter={this.mouseenter}
                onMouseLeave={this.onmouseleave}
                collapsed={this.state.collapsed}
              // style={{
              //   overflow: 'auto',
              //   height: '100vh',
              //   position: 'fixed',
              //   left: 0,
              // }}
              >
                <Menu theme="dark"
                  onClick={this.menuClick}
                  mode="inline"
                  // defaultSelectedKeys={'3'}
                  selectedKeys={this.state.menuid}
                >
                  <Menu.Item key="8"
                    style={{ display: this.state.datavdis }}
                  >
                    <a href="/#" style={{ color: 'rgba(255, 255, 255, 0.65)' }} rel="noopener noreferrer" >
                      <DashboardOutlined />
                      <span>驾驶舱</span>
                    </a>
                  </Menu.Item>
                  {this.state.menumain.map((item) => {
                    return (
                      <Menu.Item key={item.menuId}
                      >
                        <Link to={item.path}>
                          {icon[item.menuId]}
                          <span>
                            {item.menuName}
                          </span>
                        </Link>
                      </Menu.Item>
                    )
                  })}
                  <SubMenu
                    key="sub20"
                    title={
                      <span>
                        <SettingOutlined />
                        <span className="ml14" >系统管理</span>
                      </span>
                    }
                  >
                    {this.state.menuChildren.map((item) => {
                      return (
                        <Menu.Item key={item.menuId}
                        >
                          <Link to={item.path}>
                            {icon[item.menuId]}
                            <span>
                              {item.menuName}
                            </span>
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                </Menu>

              </Sider>
            </div>
            <Layout>
              <Content id="Farmer">
                <Switch>
                  <Route exact path='/app' component={alarm} />
                  <Route path="/app/alarm" component={alarm} />
                  <Route path="/app/unit" component={unit} />
                  <Route path="/app/device" component={device} />
                  <Route path="/app/user" component={user} />
                  <Route path="/app/log" component={log} />
                  <Route path="/app/statistics" component={statistics} />
                  <Route path="/app/person" component={person} />
                  <Route path="/app/record" component={record} />
                  <Route path="/app/playback" component={playback} />
                  <Route path="/app/alarmback" component={alarmback} />
                  <Route path="/app/videoback" component={videoback} />
                  <Route path="/app/warn" component={warn} />
                  <Route path="/app/videoboard" component={videoboard} />
                  <Route path="/app/power" component={power} />
                  <Route path="/app/workdetail" component={workdetail} />
                  <Route path="/app/workdreport" component={workdreport} />
                  <Route path="/app/workmain" component={workmain} />
                  <Route path="/app/stay" component={stay} />
                  <Route path="/app/airsterilization" component={air} />
                </Switch>
              </Content>
            </Layout>
          </Layout>

        </div>
      </ConfigProvider >
    )
  }
}

export default App;
