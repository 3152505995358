import React from "react";
import {
  Card,
  Layout,
  Button,
  Input,
  Table,
  Drawer,
  Switch,
  Modal,
  message,
  Tree,
  Select,
  Cascader,
  Form
} from "antd";
import {
  getaccount, getrole, addrole, deleterole,
  addaccount, deleteaccount, updateaccount
  , insertRoleMenu, changeaccount, getallmemu, addlogoperate
} from '../axios';
import validator from "../validator"
import "./user.scss";
import moment from 'moment';

const rolelists = {
  1: "超级管理员",
  2: "单位管理员",
  3: "区域管理员",
  4: "市级管理员",
}


const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const TreeNode = Tree.TreeNode;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rolelist: [],
      permissionlist: [],
      deviceList: JSON.parse(localStorage.getItem('unitTree')),
      deviceLists: JSON.parse(localStorage.getItem('unitTree'))
    };

    if (localStorage.getItem('menubtnlist').split(',').indexOf("2027") === -1) {
      this.rolecolumn = [
        {
          title: "角色",
          dataIndex: "name",
          align: 'left',
        },
        {
          title: "用户数量",
          dataIndex: "userNum",
        }, {
          title: "权限分配",
          dataIndex: "address",
          render: (text, record, index) => {
            return (
              <div>
                <span onClick={() => this.showModal(text, index, record)} style={{ color: '#fd7a12', cursor: 'pointer' }}>
                  选择权限
                </span>
                <span style={{ marginLeft: '10px' }}>
                </span>
              </div>
            );
          },
        },
      ];
    } else {
      this.rolecolumn = [
        {
          title: "角色",
          dataIndex: "name",
          align: 'left',
        },
        {
          title: "用户数量",
          dataIndex: "userNum",
        }, {
          title: "权限分配",
          dataIndex: "address",
          render: (text, record, index) => {
            return (
              <div>
                <span onClick={() => this.showModal(text, index, record)} style={{ color: '#fd7a12', cursor: 'pointer' }}>
                  选择权限
                </span>
                <span style={{ marginLeft: '10px' }}>
                </span>
              </div>
            );
          },
        },
        {
          title: "操作",
          dataIndex: "deviceQuantity",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span onClick={() => this.onDelete(text, record, index)} style={{ cursor: 'pointer' }}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        }
      ];
    }


    if (localStorage.getItem('menubtnlist').split(',').indexOf("2030") !== -1 || localStorage.getItem('menubtnlist').split(',').indexOf("2029") !== -1) {
      this.nodeInfoTableColumns = [
        {
          title: "用户名",
          dataIndex: "userName",
          align: 'left',
        },
        {
          title: "姓名",
          dataIndex: "realName",
        }, {
          title: "联系电话",
          dataIndex: "phone",
        },
        {
          title: "邮箱",
          dataIndex: "email",
        },
        {
          title: "所属单位",
          dataIndex: "unit",
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        },
        {
          title: "角色",
          dataIndex: "userType",
          filters: [
            { text: "超级管理员", value: 1 },
            { text: "单位管理员", value: 2 },
            { text: "区域管理员", value: 3 },
            { text: "市级管理员", value: 4 },
          ],
          onFilter: (value, record) => record.userType == value,  //eslint-disable-line 
          render: (text, record, index) => {
            return (
              <div >
                {rolelists[text]}
              </div>
            )
          }
        },
        {
          title: "告警通知",
          dataIndex: "sendMsg",
          render: (text, record, index) => {
            return (
              <div >
                <Switch
                  checked={text}
                  checkedChildren="开启" unCheckedChildren="关闭"
                  onChange={() => this.switchchange(text, record, index)}
                />
              </div>
            )
          }
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span style={{ marginLeft: '10px', display: this.state.userchangedis }} onClick={() => this.edit(text, record, index)}>
                  <img src={require('../images/edit.png')} alt="" />
                </span>
                <span style={{ marginLeft: '10px', display: this.state.userdeletedis }} onClick={() => this.accountdelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div >
            );
          }
        },

      ];
    } else {
      this.nodeInfoTableColumns = [
        {
          title: "用户名",
          dataIndex: "userName",
          align: 'left',
        },
        {
          title: "姓名",
          dataIndex: "realName",
        }, {
          title: "联系电话",
          dataIndex: "phone",
        },
        {
          title: "邮箱",
          dataIndex: "email",
        },
        {
          title: "所属单位",
          dataIndex: "unit",
          render: (text, record, index) => {
            if (text === null) {
              return (
                <div>
                  无
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        },
        {
          title: "角色",
          dataIndex: "userType",
          filters: [
            { text: "超级管理员", value: 1 },
            { text: "单位管理员", value: 2 },
            { text: "区域管理员", value: 3 },
            { text: "市级管理员", value: 4 },
          ],
          onFilter: (value, record) => record.userType == value,  //eslint-disable-line 
          render: (text, record, index) => {
            return (
              <div >
                {rolelists[text]}
              </div>
            )
          }
        },
      ];
    }


  }

  componentWillMount () {
    document.title = "用户管理";
    var arr = localStorage.getItem('menubtnlist').split(',')
    if (arr.indexOf('2031') !== -1) {
      this.setState({
        userdis: 'inline'
      })
    } else {
      this.setState({
        userdis: 'none'
      })
    }

    if (arr.indexOf('101') !== -1) {
      this.setState({
        roledis: 'inline'
      })
    } else {
      this.setState({
        roledis: 'none'
      })
    }

    if (arr.indexOf('2027') !== -1) {
      this.setState({
        roledelete: 'block'
      })
    } else {
      this.setState({
        roledelete: 'none'
      })
    }


    if (arr.indexOf('2029') !== -1) {
      this.setState({
        userchangedis: 'block'
      })
    } else {
      this.setState({
        userchangedis: 'none'
      })
    }

    if (arr.indexOf('2030') !== -1) {
      this.setState({
        userdeletedis: 'block'
      })
    } else {
      this.setState({
        userdeletedis: 'none'
      })
    }



  }

  componentDidMount () {
    //console.log(localStorage.getItem('menubtnlist').split(',').indexOf("2029"))
    this.accountlist()
  }

  accountlist = () => {
    getaccount([
      localStorage.getItem('token')
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          userlist: res.data.data
        }, function () {
          if (this.state.userlist.length > 10) {
            this.setState({
              page: true
            })
          } else {
            this.setState({
              page: false
            })
          }
        })
      }
    });
  }

  rolelist = () => {
    getrole([
      localStorage.getItem('token')
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          roledata: res.data.data
        }, function () {
          var arr = []
          for (var i in this.state.roledata) {
            arr.push({
              "id": this.state.roledata[i].id,
              "value": this.state.roledata[i].name
            })
          }
          this.setState({
            rolelist: arr
          })
          if (this.state.roledata.length < 10) {
            this.setState({
              rolepage: false
            })
          } else {
            this.setState({
              rolepage: true
            })
          }
        })
      }
    });
  }

  //检测状态
  switchchange = (text, record, index) => {
    updateaccount([
      localStorage.getItem('token'),
      record.id,
      record.sendMsg === true ? 0 : 1
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success("状态修改成功");
        this.accountlist()
      } else {
        message.error(res.data.message)
      }
    });
  }


  //编辑单位
  edit = (text, record, index) => {
    //console.log(record)
    setTimeout(() => {
      var that = this
      that.formRef.current.setFieldsValue({
        email: record.email,
        realname: record.realName,
        phone: record.phone,
      })
    }, 100)
    this.setState({
      email: record.email,
      realname: record.realName,
      account: record.userName,
      phone: record.phone,
      emailago: record.email,
      realnameago: record.realName,
      phoneago: record.phone,
      accountid: record.id,
      changeaccountvisible: true,
    })
  }

  changesubmit = () => {
    changeaccount([
      this.state.accountid,
      this.state.realname,
      this.state.phone,
      this.state.email,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('修改成功')
        var list = ""
        if (this.state.email !== this.state.emailago) {
          list += "把" + this.state.emailago + "修改为" + this.state.email + "，"
        }
        if (this.state.realname !== this.state.realnameago) {
          list += "把" + this.state.realnameago + "修改为" + this.state.realname + "，"
        }
        if (this.state.phone !== this.state.phoneago) {
          list += "把" + this.state.phoneago + "修改为" + this.state.phone + "，"
        }
        list = list.substring(0, list.length - 1)
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "修改" + this.state.account + "账号：" + list + "。"}`,
          5,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "用户管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });

        this.setState({
          changeaccountvisible: false,
        })
        this.accountlist()
      }
    });
  }


  //打开角色管理抽屉
  openrole = () => {
    this.rolelist()
    this.setState({
      rolevisible: true,
    })
  }

  //关闭角色管理抽屉
  onClose = () => {
    this.setState({
      rolevisible: false,
    })
  }

  //打开删除角色弹窗
  onDelete = (text, record, index) => {
    this.setState({
      deletevisible: true,
      roleid: record.id
    })
  }


  //打开删除账号弹窗
  accountdelete = (text, record, index) => {
    this.setState({
      deleteuservisible: true,
      userid: record.id,
      realname: record.realName,
    })
  }



  //打开添加角色弹窗
  addrole = () => {
    this.setState({
      addrolevisible: true,
    })
  }


  //打开添加账号弹窗
  addaccount = () => {
    this.rolelist()
    this.setState({
      addaccountvisible: true,
    })
  }

  formRef = React.createRef()

  //关闭弹窗
  handleCancel = () => {
    // this.formRef.current.resetFields()
    this.setState({
      addrolevisible: false,
      addaccountvisible: false,
      roletype: undefined,
      cityid: undefined,
      areaid: undefined,
      siteId: undefined,
      username: undefined,
      addresslist: undefined,
      changeaccountvisible: false,
      email: undefined,
      realname: undefined,
      phone: undefined,
    })
  }

  //关闭弹窗
  handleCancels = () => {
    // this.formRef.current.resetFields()
    this.setState({
      menuvisible: false,
      deleteuservisible: false,
      deletevisible: false,
    })
  }

  //角色名称输入
  rolename = (e) => {
    this.setState({
      rolename: e.target.value.replace(/\s*/g, '')
    })
  }


  //添加角色
  addOk = () => {
    if (!this.state.rolename) {
      message.error('请输入角色名称')
    } else {
      addrole([
        localStorage.getItem('token'),
        this.state.rolename,
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          message.success('添加成功')
          this.setState({
            addrolevisible: false,
            rolename: undefined
          })
          this.rolelist()
        } else {
          message.error(res.data.message)
        }
      });
    }
  }

  //添加账户
  accountsubmit = () => {
    if (!this.state.roletype) {
      message.error('请选择角色类型')
    } else if (!this.state.cityid && !this.state.cityid && !this.state.cityid) {
      message.error('请选择所属单位')
    } else if (!this.state.realname) {
      message.error('请选择真实姓名')
    } else {
      addaccount([
        localStorage.getItem('token'),
        this.state.roletype,
        31,
        this.state.cityid,
        this.state.areaid,
        this.state.siteId,
        this.state.username,
        this.state.password,
        this.state.realname,
        this.state.phone,
        this.state.email,
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          message.success('添加成功')
          addlogoperate([
            localStorage.getItem("realname"),
            `${localStorage.getItem("realname") + "添加了" + this.state.rolename + "，(其中单位id：" + this.state.siteId
            + "，用户名：" + this.state.username + "，真实姓名：" + this.state.realname + "，联系电话：" + this.state.phone + "，邮箱：" + this.state.email + ")。"}`,
            3,
            3,
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "用户管理"
          ]).then(res => {
            if (res.data && res.data.status === 200) {

            } else {
              // message.error(res.data.message)
            }
          });
          this.setState({
            addaccountvisible: false,
            roletype: undefined,
            cityid: undefined,
            areaid: undefined,
            siteId: undefined,
            username: undefined,
            realname: undefined,
            addresslist: undefined,
          })
          this.accountlist()
        } else {
          message.error(res.data.message)
        }
      });
    }

  }

  //删除角色
  deleteOk = () => {
    deleterole([
      localStorage.getItem('token'),
      this.state.roleid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        this.setState({
          deletevisible: false,
        })
        this.rolelist()
      } else {
        message.error(res.data.message)
      }
    });
  }

  //删除账号
  deleteuser = () => {
    deleteaccount([
      localStorage.getItem('token'),
      this.state.userid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "删除了" + this.state.realname + "的账号"}`,
          4,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "用户管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });
        this.setState({
          deleteuservisible: false,
        })
        this.accountlist()
      }
    });
  }

  //选择角色类型
  roletype = (value, b) => {
    //console.log(value)
    //console.log(b)
    this.setState({
      roletype: value,
      rolename: b.children,
      addresslist: [],
      cityid: undefined,
      areaid: undefined,
      siteId: undefined
    }, function () {
      if (this.state.roletype === "3") {
        this.setState({
          deviceLists: JSON.parse(localStorage.getItem('AreaTree'))
        })
      } else {
        this.setState({
          deviceLists: JSON.parse(localStorage.getItem('unitTree'))
        })
      }
    })
  }

  //添加账号设备位置选择
  addresschange = (e) => {
    //console.log(e)
    this.setState({
      addresslist: e,
      cityid: e[0] === undefined ? null : e[0],
      areaid: e[1] === undefined ? null : e[1],
      siteId: e[2] === undefined ? null : e[2]
    });
  }

  //设备位置选择
  findArea = (e) => {
    //console.log(e)
    this.setState({
      addresslists: e,
      cityids: e[0] === undefined ? null : e[0],
      areaids: e[1] === undefined ? null : e[1],
      siteIds: e[2] === undefined ? null : e[2]
    });
  }



  //用户名
  username = (e) => {
    this.setState({
      username: e.target.value.replace(/\s*/g, '')
    })
  }

  //密码
  password = (e) => {
    //console.log(e.target.value.replace(/\s*/g, '').replace(/[^0-9.]/g, ''))
    this.setState({
      password: e.target.value.replace(/\s*/g, ''),
    })
  }

  //真实姓名
  realname = (e) => {
    this.setState({
      realname: e.target.value
    })
  }

  //手机号
  phone = (e) => {
    this.setState({
      phone: e.target.value.replace(/[^0-9.]/g, '').length > 11 ? e.target.value.substring(0, 11) : e.target.value.replace(/[^0-9.]/g, '')
    })
  }

  //邮箱
  email = (e) => {
    this.setState({
      email: e.target.value
    })
  }


  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.label} key={item.id} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      } else {
        return (
          <TreeNode title={item.label} key={item.id} dataRef={item}>
          </TreeNode>
        );
      }
    });
  }


  //打开权限弹框
  showModal = (text, index, record) => {
    this.setState({
      roleid: record.id
    })
    getallmemu([
      record.id
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        var arr = res.data.data.checkedKeys
        var newarr = []
        for (var i in arr) {
          //console.log(arr[i])
          if (arr[i] !== 2000 && arr[i] !== 2002 && arr[i] !== 2003 && arr[i] !== 2005 && arr[i] !== 2004 && arr[i] !== 2006
            && arr[i] !== 2024 && arr[i] !== 2008 && arr[i] !== 2007 && arr[i] !== 100 && arr[i] !== 2008 && arr[i] !== 2009) {
            arr[i] = arr[i].toString()
            newarr.push(arr[i])
          }
        }
        this.setState({
          permissionlist: res.data.data.menus,
          menuvisible: true,
          checkedKeys: newarr,
        })
      }
    });

  }

  onExpand = (expandedKeys) => {
    //console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: true,
    });
  }

  onCheck = (checkedKey, info) => {
    // //console.log('onCheck', checkedKey.checked);
    // var arr = checkedKey.checked
    // arr.push(b.halfCheckedKeys.join(','))
    // //console.log(arr)
    //后台权限需要用到，没有全部勾选获取父节点
    let checkedKeys = [...checkedKey, ...info.halfCheckedKeys];
    //console.log(checkedKeys)
    // //console.log(checkedKeys)
    this.setState({
      checkedKeys: checkedKeys,
    });
  }

  // onSelect = (selectedKeys, info) => {
  //     //console.log('onSelect', info);
  //     this.setState({ selectedKeys });
  // }

  //修改权限
  saveOk = () => {
    insertRoleMenu([
      this.state.roleid,
      this.state.checkedKeys.join(','),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('修改成功')
        this.setState({
          menuvisible: false,
        })
      }
    });
  }

  //搜索框文字输入
  keyword = (e) => {
    this.setState({
      keyword: e.target.value
    })
  }

  //搜索
  onsearch = () => {
    getaccount([
      localStorage.getItem('token'),
      31,
      this.state.cityids,
      this.state.areaids,
      this.state.siteIds,
      this.state.keyword
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          userlist: res.data.data
        }, function () {
          if (this.state.userlist.length > 10) {
            this.setState({
              page: true
            })
          } else {
            this.setState({
              page: false
            })
          }
        })
      }
    });
  }

  render () {

    const prooptions = this.state.rolelist.map((province) => <Option key={province.id}  >{province.value}</Option>);

    const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    const rolecolumn = this.rolecolumn.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    const listion = <img src={require('../images/close.png')} alt="" />

    return (
      <Layout id="alarm" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="账号管理" headStyle={{ color: '#2a2a2a', fontSize: '18px' }}
              extra={
                <div>
                  <Button type="primary" style={{ marginRight: '10px', display: this.state.roledis }} onClick={this.openrole}  >
                    角色管理
                  </Button>
                  <Button type="primary" onClick={this.addaccount} style={{ display: this.state.userdis }} >
                    添加账号
                  </Button>
                </div>}
            >
              <div>
                <span>所属单位：</span>
                <Cascader
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={this.state.deviceList}
                  onChange={this.findArea}
                  value={this.state.addresslists}
                  changeOnSelect
                  style={{ width: "300px", marginRight: '10px' }}
                  placeholder="请选择单位" />
                <Search placeholder="搜索用户名/姓名/手机号"
                  onSearch={this.onsearch}
                  onChange={this.keyword}
                  value={this.state.keyword}
                  enterButton
                  style={{ marginBottom: '20px', width: '300px' }}
                />
                <Table
                  dataSource={this.state.userlist}
                  columns={nodeInfoTableColumns}
                  pagination={this.state.page}
                  rowKey={record => record.id}
                />
              </div>
            </Card>
          </Content>
          <Modal
            title="删除角色"
            open={this.state.deletevisible}
            onOk={this.deleteOk}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancels}
            closeIcon={listion}
          >
            您确定要删除该角色吗？
          </Modal>
          <Modal
            title="删除账号"
            open={this.state.deleteuservisible}
            onOk={this.deleteuser}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancels}
            closeIcon={listion}
          >
            您确定要删除该账号吗？
          </Modal>
          <Modal
            title="添加角色"
            open={this.state.addrolevisible}
            onOk={this.addOk}
            width="400px"
            okText="添加"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <span>角色名称：</span>
            <Input placeholder="请输入角色名称"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.rolename}
              value={this.state.rolename}
            />
          </Modal>
          <Modal
            title="添加账号"
            open={this.state.addaccountvisible}
            // onOk={this.accountsubmit}
            width="400px"
            okText="确定"
            footer={null}
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
            destroyOnClose
          >
            <Form
              ref={this.formRef}
              layout="vertical"
              onFinish={this.accountsubmit}
            >
              <span>角色类型：</span>
              <Select
                style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                placeholder="请选择角色类型"
                onChange={this.roletype}
                value={this.state.roletype}
              >
                {prooptions}
              </Select>
              <span>所属单位：</span>
              <Cascader
                fieldNames={{ label: 'name', value: 'id' }}
                options={this.state.deviceLists}
                onChange={this.addresschange}
                value={this.state.addresslist}
                changeOnSelect
                style={{ width: "100%", marginTop: '10px', marginBottom: "10px" }}
                placeholder="请选择单位" />
              <Form.Item
                name="username"
                label="用户名"
                rules={[
                  {
                    required: true,
                    validator: validator.username
                  },
                ]}
              >
                <Input placeholder="请输入用户名"
                  style={{ width: '100%', }}
                  autoComplete="off"
                  onChange={this.username}
                  value={this.state.username}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="密码"
                rules={[
                  {
                    required: true,
                    validator: validator.password
                  },
                ]}
              >
                <Input placeholder="请输入密码"
                  style={{ width: '100%', }}
                  autoComplete="off"
                  onChange={this.password}
                  value={this.state.password}
                />
              </Form.Item>
              <span>真实姓名：</span>
              <Input placeholder="请输入真实姓名"
                style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                autoComplete="off"
                onChange={this.realname}
                value={this.state.realname}
              />
              <Form.Item
                name="phone"
                label="联系电话"
                rules={[
                  {
                    required: true,
                    validator: validator.phone
                  },
                ]}
              >
                <Input placeholder="请输入联系电话"
                  style={{ width: '100%', }}
                  autoComplete="off"
                  onChange={this.phone}
                  value={this.state.phone}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="邮箱"
                rules={[
                  {
                    required: true,
                    validator: validator.email
                  },
                ]}
              >
                <Input placeholder="请输入邮箱"
                  style={{ width: '100%', }}
                  autoComplete="off"
                  onChange={this.email}
                  value={this.state.email}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  确认
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="修改账号"
            open={this.state.changeaccountvisible}
            onOk={this.changesubmit}
            width="400px"
            okText="确定"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <Form
              ref={this.formRef}
              layout="vertical"
            >
              <span>真实姓名：</span>
              <Input placeholder="请输入真实姓名"
                style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                autoComplete="off"
                onChange={this.realname}
                value={this.state.realname}
              />
              <Form.Item
                name="phone"
                label="联系电话"
                rules={[
                  {
                    // required: true,
                    validator: validator.phone
                  },
                ]}
              >
                <Input placeholder="请输入联系电话"
                  style={{ width: '100%', }}
                  autoComplete="off"
                  onChange={this.phone}
                  value={this.state.phone}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="邮箱"
                rules={[
                  {
                    // required: true,
                    validator: validator.email
                  },
                ]}
              >
                <Input placeholder="请输入邮箱"
                  style={{ width: '100%', }}
                  autoComplete="off"
                  onChange={this.email}
                  value={this.state.email}
                />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="选择权限"
            open={this.state.menuvisible}
            onOk={this.saveOk}
            onCancel={this.handleCancels}
            okText="保存"
            width="400px"
            mask={false}
            closeIcon={listion}
            destroyOnClose
            centered
          >
            <Tree
              checkable={true}
              // checkStrictly={true}
              // onExpand={this.onExpand}
              // expandedKeys={this.state.expandedKeys}
              // autoExpandParent={this.state.autoExpandParent}
              // defaultCheckedKeys={this.state.tree}
              onCheck={this.onCheck}
              defaultCheckedKeys={this.state.checkedKeys}
            // checkedKeys={this.state.checkedKeys}
            // onSelect={this.onSelect}
            // selectedKeys={this.state.selectedKeys}
            >
              {this.renderTreeNodes(this.state.permissionlist)}
            </Tree>
          </Modal>
          <Drawer
            title="角色管理"
            width={550}
            onClose={this.onClose}
            open={this.state.rolevisible}
          >
            <div style={{ width: '100%' }}>
              < Table
                dataSource={this.state.roledata}
                columns={rolecolumn}
                bordered
                pagination={this.state.rolepage}
                rowKey={record => record.id}
              />
              <div className="addrole" onClick={this.addrole} style={{ display: this.state.roledelete }}>
                <img src={require('../images/add.png')} alt="" style={{ marginRight: '5px' }} />   添加角色
              </div>
            </div>
          </Drawer>
        </Layout>
      </Layout >
    );
  }
}

export default App;
