import React, { Component } from "react";
import { message } from "antd";
import { getInfo } from '../axios';
import "./SignIns.scss";

class SignIn extends Component {
  state = {
    userID: "",
    password: ""
  };

  componentwillMount = () => {
    localStorage.clear();


  }
  componentDidMount = () => {
    let loginurl = window.location.href;
    // var loginurl = "https://xcsmoke.terabits.cn/signIns?access_token=f7c9f281-35ba"
    loginurl = loginurl.split('?access_token=');
    var errmeg = window.location.href.split('?errMsg=');

    if (errmeg.length > 1) {
      message.error(decodeURI(errmeg[1]))
      setTimeout(function () {
        window.location.href = "/";
      }, 1000);
    } else {
      if (loginurl.length > 1) {
        getInfo([
          loginurl[1],
        ]).then(res => {
          if (res.data && res.data.message === "success") {
            localStorage.setItem('token', loginurl[1]);
            localStorage.setItem('usertype', res.data.data.type);
            localStorage.setItem('realname', res.data.data.realname);
            localStorage.setItem('menuid', 3)
            localStorage.setItem("menulist", JSON.stringify(res.data.data.menu));
            localStorage.setItem("unitTree", res.data.data.unitTree.length === 0 ? null : JSON.stringify(res.data.data.unitTree[0].children));
            localStorage.setItem("AreaTree", res.data.data.AreaTree.length === 0 ? null : JSON.stringify(res.data.data.AreaTree[0].children));
            window.location.href = '/app/alarm';
          } else {
            message.error(res.data.message)
          }
        });
      }
    }

  };




  render() {
    return (
      <div id="signbody">

      </div>

    );
  }
}

export default SignIn;