import React, { Component } from "react";
import { Button, Input, message, Tabs } from "antd";
import { url, addlogoperate } from '../axios';
import http from 'axios';
import "./SignIn.scss";
import moment from 'moment';

// const { TabPane } = Tabs;

function callback (key) {
  //console.log(key);
}


class SignIn extends Component {
  state = {
    userID: "",
    password: "",
    submitLoading: false
  };

  componentwillMount = () => {
    localStorage.clear();


  }
  componentDidMount = () => {
    let loginurl = window.location.href;
    loginurl = loginurl.split('?==');
    //console.log(loginurl)
    if (loginurl.length > 1) {
      let loginurls = loginurl[1].split('&')
      http.defaults.headers.get['Content-Type'] = "application/x-www-form-urlencoded"
      http.get(url + '/login?username=' + loginurls[0] + '&password=' + loginurl[2] + '&type=user&grant_type=password', {
        auth: {
          username: "webApp",
          password: 'webApp',
        }
      }).then(res => {
        if (res.data.status === 1003) {
          message.error("用户名不存在！");
        }
        if (res.data.status === 1004) {
          message.error("密码错误");
        }
        if (res.data.status === -1) {
          message.error("账号已禁用");
        }
        if (res.data.status === 1) {
          localStorage.setItem('token', res.data.data.access_token);
          localStorage.setItem('usertype', res.data.data.type);
          localStorage.setItem('realname', res.data.data.realname);
          localStorage.setItem('menuid', 3)
          localStorage.setItem("menulist", JSON.stringify(res.data.data.menu));
          localStorage.setItem("unitTree", JSON.stringify(res.data.data.unitTree[0].children));
          localStorage.setItem("AreaTree", JSON.stringify(res.data.data.AreaTree[0].children));
          localStorage.setItem("AreaTree", JSON.stringify(res.data.data.AreaTree[0].children));
          window.location.href = '/app/unit';
        }
      })
    }
  };


  logindown = (e) => {
    if (e.keyCode === 13) {
      this.handleSubmit()
    }
  }

  handleSubmit = (e) => {
    if (this.state.userID === "") {
      message.error("请输入账号");
    }
    else if (this.state.password === "") {
      message.error("请输入密码");
    } else {
      this.setState({
        submitLoading: true,
      })
      //console.log(url)
      http.defaults.headers.get['Content-Type'] = "application/x-www-form-urlencoded"
      http.get(url + '/login?username=' + this.state.userID + '&password=' + this.state.password + '&type=user&grant_type=password', {
        auth: {
          username: "webApp",
          password: 'webApp',
        }
      }).then(res => {
        if (res.data.status === 1003) {
          message.error("用户名不存在！");
          this.setState({
            submitLoading: false,
          })
        }
        if (res.data.status === 1004) {
          message.error("密码错误");
          this.setState({
            submitLoading: false,
          })
        }
        if (res.data.status === -1) {
          message.error("账号已禁用");
          this.setState({
            submitLoading: false,
          })
        }
        if (res.data.status === 1) {
          message.success('登录成功');
          this.setState({
            submitLoading: false,
          })

          var arr = []
          for (var i in res.data.data.menu) {
            arr.push(res.data.data.menu[i].menuId)
          }
          localStorage.setItem('token', res.data.data.access_token);
          localStorage.setItem('usertype', res.data.data.type);
          localStorage.setItem('realname', res.data.data.realname);
          localStorage.setItem('menuid', 3)
          localStorage.setItem("menulist", JSON.stringify(res.data.data.menu));
          localStorage.setItem("menubtnlist", arr);
          localStorage.setItem("unitTree", JSON.stringify(res.data.data.unitTree[0].children));
          localStorage.setItem("AreaTree", JSON.stringify(res.data.data.AreaTree[0].children));

          addlogoperate([
            res.data.data.realname,
            `${res.data.data.realname + "登录了平台"}`,
            1,
            3,
            moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "登录模块"
          ]).then(res => {
            if (res.data && res.data.status === 200) {

            } else {
              // message.error(res.data.message)
            }
          });

          setTimeout(() => {
            window.location.href = '/app/unit';
          }, 1000)
        }
      })
    }
  }

  render () {
    const items = [
      {
        label: '账号登录',
        key: '1',
        children: <>
          <div>
            <Input
              size="large"
              className="SignIn-Input"
              placeholder="请输入用户名"
              prefix={
                <span style={{ borderRight: '1px solid #d9d9d9', paddingRight: '10px' }}>
                  <img src={require('../images/user.png')} alt="" style={{ width: "25px" }} />
                </span>
              }
              onChange={e => this.setState({ userID: e.target.value })}
              value={this.state.userID}
              onKeyDown={this.logindown}
            />
          </div>
          <div>
            <Input
              size="large"
              className="SignIn-Inputs"
              placeholder="请输入密码"
              prefix={
                <span style={{ borderRight: '1px solid #d9d9d9', paddingRight: '10px' }}>
                  <img src={require('../images/pass.png')} alt="" style={{ width: "25px" }} />
                </span>
              }
              type="password"
              onChange={e => this.setState({ password: e.target.value })}
              value={this.state.password}
              onKeyDown={this.logindown}
            />
          </div>
          <div>
            <Button
              className="SignIn-requestbutton"
              onClick={() => {
                this.handleSubmit();
              }}
              loading={this.state.submitLoading}
              style={{ height: '40px', width: '100%', fontSize: '18px', background: '#24ABEA', color: 'white', border: 'none' }}
            >
              <span>登录</span>
            </Button>
          </div>
        </>
      }
    ]
    return (
      <div id="signbody">
        <div style={{ display: "flex", flexDirection: 'column' }}>
          <div className="SignIn-body">
            <div className="cover">
              <div className="logo">
                <img src='https://mainimg.terabits.cn/icon.png' alt="" style={{ width: "70px", marginRight: '20px' }} />
                上城卫健执法队医美手术室安全智能监管平台
              </div>
              <div className="loginmain">
                <div className="loginl">

                </div>
                <div className="loginr">


                  <Tabs
                    //  defaultActiveKey="1"
                    items={items}
                    onChange={callback}>
                    {/* <TabPane tab="账号登录" key="1">
                      
                    </TabPane> */}
                    {/* <TabPane tab="浙政钉登录" key="2">
                      <iframe src="https://login-pro.ding.zj.gov.cn/oauth2/auth.htm?response_type=code&client_id=air_dingoa&redirect_uri=http://air.terabits.cn/api/security/codeScanningLogin&scope=get_user_info&authType=QRCODE&embedMode=true"
                        width="315"
                        height="300"
                        style={{ border: 'none', marginLeft: '-35px', overflow: 'hidden', marginTop: '-100px' }}
                        scrolling="no"
                        onLoad={() => {
                          window.addEventListener('message', function (event) {
                            var arr = 'http://air.terabits.cn/api/security/codeScanningLogin?code=' + JSON.stringify(event.data.code).replace(/\"/g, "")
                            window.location.href = arr
                          });

                        }}
                      >

                      </iframe>

                    </TabPane> */}
                  </Tabs>


                </div>
              </div>
              <div className="bombtn">
                平台服务商：&nbsp;&nbsp;<a href="http://www.terabits.cn/" target="_blank" rel="noopener noreferrer" style={{ color: '#666666' }}>杭州钛比科技有限公司</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：&nbsp;&nbsp;0571-87755736
              </div>
              <div className="bombtns">
                浙ICP备16003817号-1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;网站标识码：3610782
              </div>
              <div className="bombtns">
                <img src={require('../images/bot.png')} alt="" style={{ width: '20px', marginRight: '10px' }} />
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602007808" target="_blank" rel="noopener noreferrer" style={{ color: '#666666' }}>浙公网安备33010602009975号</a>
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default SignIn;
