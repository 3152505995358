import React from "react";
import {
  Layout,
  Tabs
} from "antd";
import { getunitList } from '../axios';
import "./stay.scss";
import OperatingRoom from './operatingRoom'
import InjectionRoom from './injectionRoom'
import NurseRoom from './nurseRoom'
import WashBasin from './washBasin'
const { Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unitlist: [],
      stayList: [],
      stayPage: 0,
      pageNum: 1,
      pageSize: 10,
      unitId: null,
      begin: null,
      end: null,
      tabNum: 1
    };

  }
  componentDidMount () {
    // this.init()
    this.getunitList()
  }
  //获取单位列表
  getunitList = () => {
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        var arr = []
        for (var i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }
  // // 获取列表
  // init () {
  //   const { pageNum, pageSize, unitId, begin, end } = this.state
  //   getStayList({
  //     pageNum,
  //     pageSize,
  //     unitId,
  //     begin,
  //     end
  //   }).then(res => {
  //     // //console.log('res :>> ', res);
  //     if (res.data && res.data.message === 'success') {
  //       // //console.log('res.data.data :>> ', res.data.data);
  //       this.setState({
  //         stayList: res.data.data.list,
  //         stayPage: res.data.data.total
  //       })
  //     } else {
  //       message.error(res.data.message)
  //     }
  //   })
  // }
  // //tab切换
  // tabchange = (value) => {
  //   // console.log(value)
  //   // this.setState({
  //   //   begintime: undefined,
  //   //   endtime: undefined
  //   // })
  //   this.setState({
  //     tabNum: value
  //   })
  // }
  // // 单位筛选
  // changeUnit = (value) => {
  //   // console.log('value :>> ', value);
  //   this.setState({
  //     unitId: value,
  //     pageNum: 1
  //   }, () => {
  //     this.init()
  //   })
  // }
  // // 时间筛选
  // changeTime = (value) => {
  //   this.setState({
  //     begin: value[0],
  //     end: value[1],
  //     pageNum: 1
  //   }, () => {
  //     this.init()
  //   })
  // }
  // // 分页筛选
  // checkPage = (value) => {
  //   this.setState({
  //     pageNum: value
  //   }, () => {
  //     this.init()
  //   })
  // }
  render () {
    // tabs各项内容定义
    const items = [
      {
        label: '手术室',
        key: '1',
        children: <OperatingRoom onRef={node => this.OperatingRoom = node} unitlist={this.state.unitlist} />
      },
      {
        label: '注射室',
        key: '2',
        children: <InjectionRoom onRef={node => this.InjectionRoom = node} unitlist={this.state.unitlist} />
      },
      {
        label: '看护室',
        key: '3',
        children: <NurseRoom onRef={node => this.NurseRoom = node} unitlist={this.state.unitlist} />
      },
      {
        label: '洗手台',
        key: '4',
        children: <WashBasin onRef={node => this.WashBasin = node} unitlist={this.state.unitlist} />
      }
    ]

    return (
      <Layout id="log" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <div>
              <Tabs defaultActiveKey="1" onChange={this.tabchange} items={items} >

              </Tabs>

            </div>
          </Content>

        </Layout>
      </Layout >
    );
  }
}

export default App;
