import React from "react";
import {
  Table,
  Layout,
  Row, Col, Card
} from "antd";
import {
  getStaticsWeek
} from "../axios";
import "./statistics.scss";

const { Content } = Layout;




class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list1: [],
      list2: [],
      list3: [],
    };


  }


  componentWillMount () {
    document.title = "运维监测";
  }

  componentDidMount () {

    getStaticsWeek([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          list1: res.data.data.管理提醒,
          list2: res.data.data.风险推送,
          list3: res.data.data.出入记录,
        })
        //console.log(res.data.data)
      }
    });
  }





  render () {


    this.Columns1 = [
      {
        title: "酒店名称",
        dataIndex: "unit",
        align: 'left',
      },
      {
        title: "提醒数量",
        dataIndex: "alarmSum",
        align: 'right',
        sorter: (a, b) => a.alarmSum - b.alarmSum,
        defaultSortOrder: 'descend',
      },
    ]

    this.Columns2 = [
      {
        title: "酒店名称",
        dataIndex: "unit",
        align: 'left',
      },
      {
        title: "推送数量",
        dataIndex: "alarmSum",
        align: 'right',
        sorter: (a, b) => a.alarmSum - b.alarmSum,
        defaultSortOrder: 'descend',
      },
    ]



    this.Columns3 = [
      {
        title: "酒店名称",
        dataIndex: "unit",
        align: 'left',
      },
      {
        title: "出入数量",
        dataIndex: "alarmSum",
        align: 'right',
        sorter: (a, b) => a.alarmSum - b.alarmSum,
        defaultSortOrder: 'descend',
      },
    ]



    return (
      <Layout id="uphold">
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="查询统计" headStyle={{ fontSize: '18px' }}>
              <div>
                <Row gutter={24}>
                  <Col className="gutter-row" span={8}>
                    <div className="colmain">
                      <div className="linemains">
                        <span className="lineboder"></span>
                        <span className="linetitle">近一周管理提醒统计</span>
                      </div>
                      <Table
                        dataSource={this.state.list1}
                        columns={this.Columns1}
                        pagination={this.state.list1.length > 10 ? true : false}
                        rowKey={record => record.unitId + Math.random()}
                      // scroll={{ y: 320 }}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <div className="colmain">
                      <div className="linemains">
                        <span className="lineboder"></span>
                        <span className="linetitle">近一周风险推送统计</span>
                      </div>
                      <Table
                        dataSource={this.state.list2}
                        columns={this.Columns2}
                        pagination={this.state.list2.length > 10 ? true : false}
                        rowKey={record => record.unitId + Math.random()}
                      // scroll={{ y: 320 }}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <div className="colmain">
                      <div className="linemains">
                        <span className="lineboder"></span>
                        <span className="linetitle">近一周出入记录统计</span>
                      </div>
                      <Table
                        dataSource={this.state.list3}
                        columns={this.Columns3}
                        pagination={this.state.list3.length > 10 ? true : false}
                        rowKey={record => record.unitId + Math.random()}
                      // scroll={{ y: 320 }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Content>
        </Layout>
      </Layout >
    );
  }
}

export default App;
