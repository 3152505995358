import React, { Component } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
class Headers extends Component {
  state = {
    user: '',
    visible: false,
    collapsed: false,
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  out = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.reload();
  }
  componentWillMount = () => {

  }
  render () {
    return (
      <div className="headermain">
        <div className="headerleft">
          <img src='https://mainimg.terabits.cn/icon.png' alt="" style={{ marginRight: '15px', width: '30px' }} /> 上城卫健执法队医美手术室安全智能监管平台
        </div>
        <div className="headerright">

          <span style={{ fontSize: '16x', display: 'flex', alignItems: 'center' }}>
            {/* <img src={require('./touxiang.jpg')} alt="" style={{ width: '35px', marginTop: '-8px', marginRight: '10px' }} /> */}
            欢迎您，
            <Avatar icon={<UserOutlined />} />
          </span>
          <span style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
            {localStorage.getItem("realname")}
          </span>
          <span onClick={this.out} style={{ cursor: 'pointer', marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
            <img src={require('./out.png')} alt="" style={{ marginRight: '10px', width: '20px' }} />
            退出
          </span>
        </div>
      </div>
    )
  }
}
export default Headers;
