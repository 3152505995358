import React from "react";
import {
  Input, Table, Select, Modal, Form, message, Popconfirm
} from "antd";
import moment from 'moment'
import {
  getdeviceList, addCard, deletedevice, addlogoperate
} from '../axios';
const { Search } = Input;
// const { Option } = Select;
class card extends React.Component {
  cardForm = React.createRef()
  constructor(props) {
    super(props);
    // //console.log('props :>> ', props);
    this.state = {
      // 读卡器
      cardSiteId: '',
      cardKey: '',
      cardList: [],
      cardPage: 0,
      cardVisible: false
    }
  }
  componentDidMount () {
    this.props.onRef && this.props.onRef(this);
    this.init()
  }
  // 获取列表
  init () {
    getdeviceList([
      localStorage.getItem('token'),
      3,
      31,
      this.state.cityid,
      this.state.areaid,
      this.state.siteId,
      this.state.searchname,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        // //console.log('res.data :>> ', res.data);
        this.setState({
          cardList: res.data.data
        })
      }
    });
  }
  // 单位筛选
  cardChangeUint = (value) => {
    // //console.log('value :>> ', value);
    this.setState({
      siteId: value
    }, () => {
      this.init()
    })
  }
  //搜索
  cardQuery = () => {
    // this.getmenjinList()
    this.init()
  }

  //输入关键字
  keytext = (e) => {
    this.setState({
      searchname: e.target.value
    })

  }
  // 添加
  addCard = () => {
    this.setState({
      cardVisible: true,
      actionType: 'add'
    })
  }
  // 修改
  cardEdit = (text, record) => {
    this.setState({
      cardVisible: true,
      actionType: 'edit',
      editId: record.id
    }, () => {
      // //console.log('this :>> ', this.cardForm.current);
      this.cardForm.current
        .setFieldsValue({
          devicetype: '读卡器',
          location: record.location,
          unitId: record.unitId,
          deviceId: record.deviceId,
          scenario: record.scenario
        })
    })
  }
  // 删除
  cardDelete = (text, record) => {
    deletedevice([
      text
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除设备成功')
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "删除了" + record.unit + "下面读卡器ID为" + record.deviceId + "的设备"} `,
          4,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "设备管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          }
        })
        this.init()
      } else {
        message.error(res.data.message)
      }
    });
  }
  // 
  cardCancel = () => {
    this.cardForm.current.resetFields()
    this.setState({
      cardVisible: false
    })
  }
  // 确认添加 确认修改
  cardSumbit = () => {
    this.cardForm.current
      .validateFields()
      .then(values => {
        const { actionType } = this.state
        const { unitlist } = this.props
        const unitname = unitlist.filter(el => el.id === values.unitId)[0].name
        if (actionType === 'add') {
          const params = {
            type: 3,
            location: values.location,
            unitId: values.unitId,
            deviceId: values.deviceId,
            scenario: values.scenario
          }
          addCard(params).then(res => {
            // //console.log('res :>> ', res);
            if (res.data && res.data.message === "success") {
              message.success('添加设备成功')
              addlogoperate([
                localStorage.getItem("realname"),
                `${localStorage.getItem("realname")}在${unitname}单位中添加了一个读卡器，(其中设备编号为${values.deviceId}
                   ，设备位置为 ${values.location})。`,
                3,
                3,
                moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                "设备管理"
              ]).then(res => {
                if (res.data && res.data.status === 200) {

                }
              });
              this.cardCancel()
              this.init()

            } else {
              message.error(res.data.message)
            }
          })
        } else {
          const params = {
            type: 3,
            location: values.location,
            unitId: values.unitId,
            deviceId: values.deviceId,
            id: this.state.editId,
            scenario: values.scenario
          }
          addCard(params).then(res => {
            // //console.log('res :>> ', res);
            if (res.data && res.data.message === "success") {
              message.success('修改设备成功')
              this.cardCancel()
              this.init()
            } else {
              message.error(res.data.message)
            }
          })
        }
        // form.resetFields()
      })
      .catch(info => {
        //console.log('Validate Failed:', info);
      });
  }
  cardLocationChange = (e) => {
    this.setState({
      cardLocation: e.target.value
    })
  }
  cardIDChange = (e) => {
    this.setState({
      cardId: e.target.value
    })
  }

  render () {
    // //console.log('this.props.unitlist :>> ', this.props.unitlist);
    const unitlist = this.props.unitlist.map(el => {
      return {
        value: el.id,
        label: el.name
      }
    })
    const listion = <img src={require('../images/close.png')} alt="" />
    // 读卡器
    const cardColumns = [
      {
        title: "所属单位",
        dataIndex: "unit"
      },
      {
        title: "安装场景",
        dataIndex: "scenario",
        align: 'center',
        // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
        render: (text, record, index) => {
          if (text) {
            return (
              <div>
                {text === 1 ? '手术室' : text === 2 ? '注射室' : text === 3 ? '看护室' : '洗手台'}
              </div>
            )
          } else {
            return (
              <div>
                -
              </div>
            )
          }
        }
      },
      {
        title: "安装位置",
        dataIndex: "location",
      },
      {
        title: "读卡器ID",
        dataIndex: "deviceId",
      },
      {
        title: "设备状态",
        dataIndex: "statusConnect",
        // filters: [
        //   { text: "在线", value: 1 },
        //   { text: "离线", value: 0 },
        // ],
        // onFilter: (value, record) => record.statusConnect == value,  //eslint-disable-line 
        render: (text, record, index) => {
          // if (text === 1) {
          return (
            <div style={{ color: '#1eb333', cursor: 'pointer' }}
            >
              在线
            </div>
          )
          // }
          // if (text === 0) {
          //   return (
          //     <div style={{ color: '#f55238', cursor: 'pointer' }}
          //     >
          //       离线
          //     </div>
          //   )
          // }
        }
      },
      {
        title: "最后上报时间",
        dataIndex: "lastConnect",
        align: 'center',
        // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
        render: (text, record, index) => {
          if (text === null) {
            return (
              <div>
                无
              </div>
            )
          } else {
            if ((new Date().getTime() - new Date(text).getTime()) > 3600 * 1000 * 24) {
              return (
                <div style={{ color: 'red' }}>
                  {text}
                </div>
              )
            } else {
              return (
                <div>
                  {text}
                </div>
              )
            }
          }
        }
      }, {
        title: "添加时间",
        dataIndex: "gmtCreate",
        align: 'center',
        // sorter: (a, b) => new Date(a.lastConnect) > new Date(b.lastConnect) ? 1 : -1,
        render: (text, record, index) => {
          if (text === null) {
            return (
              <div>
                无
              </div>
            )
          } else {
            return (
              <div>
                {text}
              </div>
            )
          }
        }
      },
      {
        title: "操作",
        dataIndex: "id",
        render: (text, record, index) => {
          return (
            <div>
              <span
                onClick={() => this.cardEdit(text, record, index)}
                style={{ marginRight: '10px' }}
              >
                <img src={require('../images/edit.png')} alt="" />
              </span>

              <Popconfirm
                title="您确定要删除该设备吗？"
                onConfirm={() => this.cardDelete(text, record, index)}
                okText="确定"
                cancelText="取消"
                placement="topRight"
              >
                <img src={require('../images/delete.png')} alt="" />
              </Popconfirm>
            </div>
          );
        }
      }
    ]
    return (
      <>
        <div className="contentmain">
          <span>所属单位：</span>
          <Select
            style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
            placeholder="请选择所属单位"
            onChange={this.cardChangeUint}
            value={this.state.siteId}
            allowClear
            options={unitlist}
          />
          <Search placeholder="请输入设备编号"
            onSearch={this.cardQuery}
            onChange={this.keytext}
            value={this.state.searchname}
            enterButton style={{ marginBottom: '20px', width: '300px' }}
          />
          <div>
            <Table
              dataSource={this.state.cardList}
              columns={cardColumns}
              rowKey={record => record.id}
            />
          </div>
        </div>
        {/* <div className="unpower" style={{ display: this.state.unlook }}>
          您暂无权限查看
        </div> */}
        {/* 读卡器 */}
        <Modal
          title={this.state.actionType === 'add' ? "添加设备" : '编辑设备'}
          open={this.state.cardVisible}
          onOk={this.cardSumbit}
          width="400px"
          okText="确认"
          forceRender={true}
          centered
          onCancel={this.cardCancel}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}
        >
          <Form
            ref={this.cardForm}
            layout='vertical'
            name="register"
            scrollToFirstError
          >
            <Form.Item
              name="devicetype"
              label="设备类型："
            >
              <Input
                autoComplete="off"
                disabled
                placeholder='读卡器'
              />
            </Form.Item>
            <Form.Item
              name="unitId"
              label="所属单位"
              rules={[
                {
                  required: true,
                  message: '请选择所属单位!',
                },
              ]}
            >
              <Select placeholder="请选择所属单位" options={unitlist} />
            </Form.Item>
            <Form.Item
              name="scenario"
              label="安装场景"
              rules={[
                {
                  required: true,
                  message: '请选择安装场景!',
                },
              ]}
            >
              <Select placeholder="请选择安装场景" options={[
                {
                  value: 1,
                  label: '手术室'
                },
                {
                  value: 2,
                  label: '注射室'
                },
                {
                  value: 3,
                  label: '看护室'
                },
                {
                  value: 4,
                  label: '洗手台'
                }
              ]} />
            </Form.Item>
            <Form.Item
              name="location"
              label="安装位置："
              rules={[
                {
                  required: true,
                  message: '请输入安装位置！',
                },
              ]}
            >
              <Input placeholder="请输入安装位置" />
            </Form.Item>
            <Form.Item
              name="deviceId"
              label="读卡器ID："
              rules={[
                {
                  required: true,
                  message: '请输入读卡器ID!',
                },
              ]}
            >
              <Input placeholder="请输入读卡器ID" />
            </Form.Item>


          </Form>
        </Modal>
      </>
    )
  }
}

export default card;
