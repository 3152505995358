import React from "react";
import {
  Card,
  Layout,
  Button,
  Table,
  Modal,
  DatePicker, message, Select, Pagination, Tooltip,
} from "antd";

import { deleteinoutlist, getinoutList, getunitList } from '../axios';


import "./warn.scss";
import moment from 'moment';

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

function disabledDate (current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoListDataSource: [],
      device_ip: null,
      scenetimelist: [],
      devicealarmlist: [],
      typenone: "inline-block",
      pageNum: 1,
      pageNums: 1,
      pageNumSize: 10,
      doorimg: [],
      cameraimg: [],
      unitlist: [],
    };

    if (localStorage.getItem('menubtnlist').split(',').indexOf("2012") === -1) {
      this.unnormalColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "位置",
          dataIndex: "deviceLocation",
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
        }, {
          title: "抓拍照片",
          dataIndex: "cameraImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },

        {
          title: "人脸照片",
          dataIndex: "doorImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openfaceimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "出入时间",
          dataIndex: "gmtOccur",
          align: 'right',
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
      ]
    } else {
      this.unnormalColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "位置",
          dataIndex: "deviceLocation",
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
        }, {
          title: "抓拍照片",
          dataIndex: "cameraImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },

        {
          title: "人脸照片",
          dataIndex: "doorImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openfaceimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "出入时间",
          dataIndex: "gmtOccur",
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span style={{ marginLeft: '10px' }} onClick={() => this.unitdelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        },

      ]
    }



  }

  componentWillMount () {
    document.title = "出入记录";
  }

  componentDidMount () {
    this.getunitList()
    this.unnormallist()
  }




  unnormallist = () => {
    getinoutList([
      localStorage.getItem('token'),
      this.state.pageNums,
      this.state.pageNumSize,
      this.state.cityid,
      this.state.areaid,
      this.state.siteIds,
      this.state.begintime,
      this.state.endtime,
      this.state.searchname,
      2,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          unnormallist: res.data.data.list,
          unnormatotal: res.data.data.total,
        })
      }
    });


  }

  //关键字录入
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })
  }


  //打开抓拍照片弹窗
  openimg = (text, record, index) => {
    //console.log(text)
    if (text) {
      var arr = []
      for (var i in JSON.parse(text)) {
        arr.push("http://scym.terabits.cn" + JSON.parse(text)[i])
      }
      this.setState({
        imgvisible: true,
        cameraimg: arr
      })
    } else {
      message.error('暂无图片')
    }
  }

  //打开人脸照片弹窗
  openfaceimg = (text, record, index) => {
    //console.log(text)
    if (text) {
      this.setState({
        imgvisibles: true,
        doorimg: "http://scym.terabits.cn" + JSON.parse(text)[0]
      })
    } else {
      message.error('暂无图片')
    }
  }



  //关闭添加单位弹窗
  handleCancel = () => {
    this.setState({
      deleteunitvisible: false,
      imgvisible: false,
      imgvisibles: false,
    })
  }





  //打开删除出入记录弹窗
  unitdelete = (text, record, index) => {
    this.setState({
      deleteunitvisible: true,
      eventId: record.id
    })
  }

  //删除单位
  deleteunit = () => {
    deleteinoutlist([
      this.state.eventId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        this.setState({
          deleteunitvisible: false,
        })
        this.unnormallist()
      } else {
        message.error(res.data.message)
      }
    });
  }


  //搜索框录入
  searchname = (e) => {
    this.setState({
      searchname: e.target.value
    })
  }

  //搜索
  inoutquery = () => {
    this.setState({
      pageNum: 1,
    }, function () {
      this.unnormallist()
    })

  }





  //分页
  unnormalpagechange = (page, b) => {
    this.setState({
      pageNums: page,
      pageNumSize: b,
    }, function () {
      this.unnormallist()
    })
  }




  //报警时间筛选
  alarmtime = (value, b) => {
    if (!value) {
      this.setState({
        begintime: null,
        endtime: null
      }, function () {
        this.unnormallist()
      })
    } else {
      this.setState({
        begintime: moment(value[0]).format("YYYY-MM-DD 00:00:00"),
        endtime: moment(value[1]).format("YYYY-MM-DD 23:59:59"),
      }, function () {
        this.unnormallist()
      })
    }

  }


  //获取单位列表
  getunitList = () => {
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        var arr = []
        for (var i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }


  //选择单位
  changeunits = (value) => {
    this.setState({
      siteIds: value,
      pageNums: 1,
    }, function () {
      this.unnormallist()
    })
  }




  render () {

    const prooptions = this.state.unitlist.map((province) => <Option key={province.id}  >{province.name}</Option>);

    const unnormalColumns = this.unnormalColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });




    const listion = <img src={require('../images/close.png')} alt="" />



    return (
      <Layout id="alarm" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="管理提醒" headStyle={{ fontSize: '18px' }}>
              <div>
                &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
                <Select
                  style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                  placeholder="请选择所属单位"
                  onChange={this.changeunits}
                  value={this.state.siteIds}
                  allowClear
                >
                  {prooptions}
                </Select>
                &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
                <RangePicker style={{ marginRight: '20px' }}
                  onChange={this.alarmtime}
                  disabledDate={disabledDate}
                  ranges={{
                    "今日": [moment(), moment()],
                    '本月': [moment().startOf('month'), moment().endOf('month')],
                  }}
                />
                <Button type="primary" onClick={this.inoutquery}>查询</Button>
                <Table
                  dataSource={this.state.unnormallist}
                  columns={unnormalColumns}
                  pagination={false}
                />
                <div style={{ textAlign: 'right', marginTop: '10px' }}>
                  <Pagination
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={1}
                    onChange={this.unnormalpagechange}
                    total={this.state.unnormatotal}
                    hideOnSinglePage={true}
                    current={this.state.pageNums}
                  />
                </div>
              </div>
            </Card>
          </Content>
          <Modal
            title="删除记录"
            visible={this.state.deleteunitvisible}
            onOk={this.deleteunit}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该记录吗？
          </Modal>
          <Modal
            title="抓拍照片"
            visible={this.state.imgvisible}
            width="500px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            <div>
              {this.state.cameraimg.map((item) => {
                return (
                  <img src={item} alt="" style={{ width: '100%' }} />
                )
              })}
            </div>
            {/* <img src={this.state.cameraimg} alt="" style={{ width: '100%' }} /> */}
          </Modal>
          <Modal
            title="人脸照片"
            visible={this.state.imgvisibles}
            width="500px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            <img src={this.state.doorimg} alt="" style={{ width: '100%' }} />
          </Modal>
        </Layout>
      </Layout >
    );
  }
}

export default App;
