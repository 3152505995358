import React from "react";
import {
  Table,
  Layout,
  Button,
  message, Modal, Card
} from "antd";
import { getinoutLists, getalarmList, getinoutList } from '../axios';


import "./workdreport.scss";
import moment from 'moment';
// import { func } from "prop-types";

const { Content } = Layout;
// const { RangePicker } = DatePicker;
// const { RangePicker } = DatePicker;
// const { TextArea } = Input;
// const { Option } = Select;
// function disabledDate(current) {
//     // Can not select days before today and today
//     return current && current > moment().endOf('day');
// }


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inoutlist: [],
      alarmlist: [],
      alarmimg: [],
      anonymouslist: [],
      anonymousimg: []
    };



  }

  componentWillMount () {
    document.title = "工作报告";
    this.inoutlist()
    this.alarmlist()
    this.anonymouslist()
  }

  componentDidMount () {

  }


  inoutlist = () => {
    getinoutLists([
      localStorage.getItem('token'),
      this.state.inoutpageNum,
      this.state.pageNumSize,
      localStorage.getItem('siteId'),
      moment(localStorage.getItem('unitdate')).format('YYYY-MM-DD 00:00:00'),
      moment(localStorage.getItem('unitdate')).format('YYYY-MM-DD 23:59:59'),
      1,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          inoutlist: res.data.data.list,
          inoutotal: res.data.data.total,
        })
      }
    });
  }

  alarmlist = () => {
    getalarmList([
      localStorage.getItem('token'),
      this.state.alarmpageNum,
      this.state.pageNumSize,
      moment(localStorage.getItem('unitdate')).format('YYYY-MM-DD 00:00:00'),
      moment(localStorage.getItem('unitdate')).format('YYYY-MM-DD 23:59:59'),
      null,
      localStorage.getItem('siteId'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          alarmlist: res.data.data.list,
          alarmtotal: res.data.data.total
        })
      }
    });
  }

  anonymouslist = () => {
    getinoutList([
      localStorage.getItem('token'),
      this.state.anonymouspageNum,
      this.state.pageNumSize,
      this.state.cityid,
      this.state.areaid,
      localStorage.getItem('siteId'),
      moment(localStorage.getItem('unitdate')).format('YYYY-MM-DD 00:00:00'),
      moment(localStorage.getItem('unitdate')).format('YYYY-MM-DD 23:59:59'),
      undefined,
      2,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          anonymouslist: res.data.data.list,
          anonymoustotal: res.data.data.total,
        })
      }
    });
  }

  //打开抓拍图片弹窗
  openimg = (text, record, index) => {
    var arr = []
    for (var i in JSON.parse(record.alarmPicture)) {
      arr.push("http://scym.terabits.cn" + JSON.parse(record.alarmPicture)[i])
    }
    //console.log(arr)
    this.setState({
      imgvisible: true,
      alarmimg: arr
    })
  }


  //关闭弹窗
  handleCancel = () => {
    this.setState({
      imgvisible: false,
      anonymousvisible: false,
      anonymousimg: []
    })
  }


  //返回工作详情
  back = () => {
    window.location.href = '/app/workdetail';
  }


  //查看管理提醒照片
  lookanonymousimg = (text, record, index) => {
    var arr = []
    if (JSON.parse(record.cameraImages).length === 0) {
      message.error('暂无图片')
    } else {
      for (var i in JSON.parse(record.cameraImages)) {
        arr.push("http://scym.terabits.cn" + JSON.parse(record.cameraImages)[i])
      }
    }
    this.setState({
      anonymousimg: arr,
      anonymousvisible: true,
    })
  }

  render () {

    const inoutColumns = [
      {
        title: "设备位置",
        dataIndex: "deviceLocation",
        align: 'left',
      },
      {
        title: "出入人员",
        dataIndex: "eventUserName",
        align: 'left',
      },
      {
        title: "人员身份",
        dataIndex: "eventPosition",
        align: 'left',
      },
      {
        title: "出入时间",
        dataIndex: "gmtOccur",
        width: 160,
        align: 'left',
      },
      {
        title: "查看照片",
        dataIndex: "doorImages",
        width: 100,
        align: 'right',
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }}
            // onClick={() => this.lookinoutimg(text, record, index)}
            >
              {/* 查看 */}
              <a href={"http://scym.terabits.cn" + JSON.parse(text)[0]} target="_blank" rel="noopener noreferrer">
                <img src={"http://scym.terabits.cn" + JSON.parse(text)[0]} alt="" style={{ width: '50px', height: '50px' }} />
              </a>
            </div>
          )
        }
      }
    ];


    const alarmColumns = [
      {
        title: "设备位置",
        dataIndex: "devicePosition",
        align: 'left',
      }, {
        title: "报警内容",
        dataIndex: "number",
        align: 'left',
        render: (text, record, index) => {
          return (
            <div
            >
              {text}人进入手术室
            </div>
          )
        }
      },
      {
        title: "出入人员",
        dataIndex: "eventUserName",
        align: 'left',
      },
      {
        title: "人员身份",
        dataIndex: "eventPosition",
        align: 'left',
      },
      {
        title: "告警时间",
        dataIndex: "gmtOccur",
        align: 'left',
        width: 160,
      },
      {
        title: "报警图片",
        dataIndex: "doorImages",
        align: 'right',
        render: (text, record, index) => {
          return (
            <div>
              <span
                style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.openimg(text, record, index)}>
                查看
              </span>
            </div>
          );
        }
      }
    ];


    const anonymousColumns = [
      {
        title: "设备位置",
        dataIndex: "deviceLocation",
        align: 'left',
      },
      {
        title: "卡号",
        dataIndex: "eventCardNo",
        align: 'left',
      },
      {
        title: "出入时间",
        dataIndex: "gmtOccur",
        align: 'left',
      },
      {
        title: "人脸照片",
        dataIndex: "doorImages",
        align: 'right',
        render: (text, record, index) => {
          if (JSON.parse(text).length === 0) {
            return (
              <div style={{ color: '#11A7D7', }}
              >
                暂无照片
              </div>
            )
          } else {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
              >
                <a href={"http://scym.terabits.cn" + JSON.parse(text)[0]} target="_blank" rel="noopener noreferrer">
                  <img src={"http://scym.terabits.cn" + JSON.parse(text)[0]} alt="" style={{ width: '50px', height: '50px' }} />
                </a>
              </div>
            )
          }

        }
      },
      {
        title: "抓拍图片",
        dataIndex: "doorImages",
        align: 'right',
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }}
              onClick={() => this.lookanonymousimg(text, record, index)}
            >
              查看
            </div>
          )
        }
      }
    ];


    const listion = <img src={require('../images/close.png')} alt="" />

    return (
      <Layout id="workdreport" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="工作详情 > 工作报告" headStyle={{ fontSize: '18px' }}
              extra={
                <div>
                  <Button type="primary" onClick={this.back} >
                    返回
                  </Button>
                </div>}>
              {/* <div>
                                单位：  {localStorage.getItem('unitname')}
                            </div> */}
              <div className="reportmain">
                <div className="reportheader">
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>

                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                  <span className="reportfang">

                  </span>
                </div>
                <div className="hoteltitle">
                  {localStorage.getItem('unitname')}工作详情报告
                </div>
                <span className="reportdate">
                  {localStorage.getItem('unitdate')}
                </span>

                <div className="reportcont">
                  <div style={{ marginBottom: "30px" }}>
                    <span className="reporttitle">
                      出入记录
                    </span>
                    <span className="reportk">
                      (<span className="reportnum">{this.state.inoutlist.length}</span>条)
                    </span>
                  </div>
                  <div>
                    <Table
                      dataSource={this.state.inoutlist}
                      columns={inoutColumns}
                      style={{ width: '70%' }}
                      className="tablescroll"
                      scroll={{ y: 220 }}
                      bordered
                      pagination={false}
                    />
                  </div>


                  <div style={{ marginBottom: "30px", marginTop: '60px' }}>
                    <span className="reporttitle">
                      风险推送
                    </span>
                    <span className="reportk">
                      (<span className="reportnum">{this.state.alarmlist.length || 0}</span>条)
                    </span>
                  </div>
                  <div>
                    <Table
                      dataSource={this.state.alarmlist}
                      columns={alarmColumns}
                      style={{ width: '70%' }}
                      className="tablescroll"
                      scroll={{ y: 220 }}
                      bordered
                      pagination={false}
                    />
                  </div>



                  <div style={{ marginBottom: "30px", marginTop: '60px' }}>
                    <span className="reporttitle">
                      管理提醒
                    </span>
                    <span className="reportk">
                      (<span className="reportnum">{this.state.anonymouslist.length || 0}</span>条)
                    </span>
                  </div>
                  <div>
                    <Table
                      dataSource={this.state.anonymouslist}
                      columns={anonymousColumns}
                      style={{ width: '80%' }}
                      className="tablescroll"
                      scroll={{ y: 220 }}
                      bordered
                      pagination={false}
                      rowKey={record => record.id}
                    />
                  </div>


                </div>

              </div>
            </Card>
          </Content>
          <Modal
            title="报警图片"
            open={this.state.imgvisible}
            width="500px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            {this.state.alarmimg.map((item) => {
              return (
                <img src={item} alt="" style={{ width: '100%' }} />
              )
            })}

          </Modal>

          <Modal
            title="管理提醒抓拍照片"
            destroyOnClose
            footer={null}
            open={this.state.anonymousvisible}
            centered
            width='600px'
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            {this.state.anonymousimg.map((item) => {
              return (
                <img src={item} alt="" style={{ width: '100%' }} />
              )
            })}

          </Modal>

        </Layout>
      </Layout >
    );
  }
}

export default App;
