import React from "react";
import {
  Table,
  Layout,
  Button,
  Input,
  Pagination,
  message, Modal, DatePicker, Select, Card, Tooltip
} from "antd";
import { getalarmList, addResult, getunitList } from '../axios';


import "./alarm.scss";
import moment from 'moment';

const { Content } = Layout;
// const { RangePicker } = DatePicker;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
function disabledDate (current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoListDataSource: [],
      device_ip: null,
      typenone: "inline-block",
      pageNum: 1,
      pageNumSize: 10,
      pageNums: 1,
      pageNumSizes: 10,
      deviceList: JSON.parse(localStorage.getItem('unitTree')),
      deviceLists: JSON.parse(localStorage.getItem('unitTree')),
      imglist: [],
      videourl: undefined,
      alarmimg: [],
      remark: [],
      unitlist: [],
    };


    if (localStorage.getItem('menubtnlist').split(',').indexOf("2013") === -1) {
      this.nodeInfoTableColumns = [
        {
          title: "所属单位",
          dataIndex: "unitName",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.alarmCamera}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "设备位置",
          dataIndex: "devicePosition",
        }, {
          title: "报警详情",
          dataIndex: "number",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.alarmdetail(text, record, index)}>
                  详情
                </span>
              </div>
            );
          }
        }, {
          title: "出入人员",
          dataIndex: "eventUserName",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "人员身份",
          dataIndex: "eventPosition",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        },
        {
          title: "报警图片",
          dataIndex: "alarmType",
          width: 120,
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.openimg(text, record, index)}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "视频回放",
          dataIndex: "statusConnect",

          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.movieback(text, record, index)}
              >
                查看
              </div>
            )
          }
        }, {
          title: "告警时间",
          dataIndex: "gmtOccur",
          align: 'right',
        },
      ];
    } else {
      this.nodeInfoTableColumns = [
        {
          title: "所属单位",
          dataIndex: "unitName",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.alarmCamera}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "设备位置",
          dataIndex: "devicePosition",
        }, {
          title: "报警详情",
          dataIndex: "number",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.alarmdetail(text, record, index)}>
                  详情
                </span>
              </div>
            );
          }
        }, {
          title: "出入人员",
          dataIndex: "eventUserName",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "人员身份",
          dataIndex: "eventPosition",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        },
        {
          title: "报警图片",
          dataIndex: "alarmType",
          width: 120,
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.openimg(text, record, index)}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "处置结果",
          dataIndex: "result",
          render: (text, record, index) => {
            if (text) {
              return (
                <div>
                  <span style={{ color: 'rgb(8, 192, 77)', cursor: 'pointer' }} onClick={() => this.dealdetail(text, record, index)}>已处置</span>
                </div>
              )
            } else {
              return (
                <div>
                  <span style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.adddetail(text, record, index)}>添加</span>
                </div>
              )
            }

          }
        },
        {
          title: "视频回放",
          dataIndex: "statusConnect",

          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.movieback(text, record, index)}
              >
                查看
              </div>
            )
          }
        }, {
          title: "告警时间",
          dataIndex: "gmtOccur",
          align: 'right',
        },
        // {
        //     title: "操作",
        //     dataIndex: "gmtCreate",
        //     align: 'right',
        //     render: (text, record, index) => {
        //         return (
        //             <div>
        //                 <span style={{ marginLeft: '10px' }} onClick={() => this.alarmDelete(text, record, index)}>
        //                     <a><img src={require('../images/delete.png')} alt="" /></a>
        //                 </span>
        //             </div>
        //         );
        //     }
        // },
      ];
    }

  }

  componentWillMount () {
    document.title = "告警管理";
  }

  componentDidMount () {
    this.cameraalarm()
    this.getunitList()
  }

  cameraalarm = () => {
    getalarmList([
      localStorage.getItem('token'),
      this.state.pageNum,
      this.state.pageNumSize,
      this.state.begintime,
      this.state.endtime,
      this.state.keytext,
      this.state.siteId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          alarmlist: res.data.data.list,
          alarmtotal: res.data.data.total
        })
      }
    });
  }



  //关键字录入
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })
  }

  //跳转回放
  movieback = (text, record, index) => {
    var begin = moment((new Date(record.gmtOccur).getTime() - 1000 * 60))
    var end = moment((new Date(record.gmtOccur).getTime() + 1000 * 60 * 10))
    localStorage.setItem('cameraindexcode', record.alarmCamera)
    localStorage.setItem('cameralocation', record.devicePosition)
    localStorage.setItem('alarmbegin', begin)
    localStorage.setItem('alarmend', end)
    localStorage.setItem('backtype', "alarm")
    localStorage.setItem('cameraappkey', record.iscArgsId === 2 ? "27517347" : "21918920")
    localStorage.setItem('cameraappsecret', record.iscArgsId === 2 ? "ehPknvh9I9UGAHY1jGdC" : "J9s2irFNCyhA6TSKosBc")
    localStorage.setItem('camerahost', record.iscArgsId === 2 ? "122.224.50.67" : "118.178.57.33")
    localStorage.setItem('cameraport', record.iscArgsId === 2 ? 81 : 4443)
    window.location.href = '/app/alarmback';
  }




  //设备位置选择
  addresschange = (e) => {
    //console.log(e)
    this.setState({
      addresslist: e,
      cityid: e[0] === undefined ? null : e[0],
      areaid: e[1] === undefined ? null : e[1],
      siteId: e[2] === undefined ? null : e[2]
    });
  }


  //打开报警详情弹窗
  alarmdetail = (text, record, index) => {
    this.setState({
      detailvisible: true,
      number: record.number
    })
  }

  //打开抓拍图片弹窗
  openimg = (text, record, index) => {
    var arr = []
    for (var i in JSON.parse(record.alarmPicture)) {
      arr.push("http://scym.terabits.cn" + JSON.parse(record.alarmPicture)[i])
    }
    //console.log(arr)
    this.setState({
      imgvisible: true,
      alarmimg: arr
    })
  }

  //打开处置详情弹窗
  dealdetail = (text, record, index) => {
    this.setState({
      chuzhivisible: true,
      result: record.result,
      resulttime: record.gmtDispose,
      resultpeople: record.disposeAdminName,
    })
  }


  //打开删除弹窗
  alarmDelete = (text, record, index) => {
    this.setState({
      eventId: record.id,
      alarmdeletevisible: true,
    })
  }

  //打开添加说明弹窗
  adddetail = (text, record, index) => {
    this.setState({
      describevisible: true,
      eventId: record.id,
    })
  }

  //关闭
  handleCancel = () => {
    this.setState({
      videovisible: false,
      videourl: undefined,
      alarmdeletevisible: false,
      detailvisible: false,
      chuzhivisible: false,
      imgvisible: false,
      describevisible: false,
      remark: [],
    })
  }



  //查询
  cameraquery = () => {
    this.setState({
      pageNum: 1
    }, function () {
      this.cameraalarm()
    })

  }



  //关键字
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })
  }



  //摄像头页数变化
  pagechange = (page, b) => {
    //console.log(page, b)
    this.setState({
      pageNum: page,
      pageNumSize: b,
    }, function () {
      this.cameraalarm()
    })
  }

  //报警时间筛选
  alarmtime = (value, b) => {
    if (!value) {
      this.setState({
        begintime: null,
        endtime: null
      })
    } else {
      this.setState({
        begintime: moment(value[0]).format("YYYY-MM-DD 00:00:00"),
        endtime: moment(value[1]).format("YYYY-MM-DD 23:59:59"),
      })
    }

  }



  //填写备注说明
  remarkchange = (e) => {
    this.setState({
      remark: e.target.value.slice(0, 200),
    })
  }

  //确认报警说明提交
  explainok = () => {
    addResult([
      this.state.eventId,
      this.state.remark,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('添加成功')
        this.setState({
          describevisible: false,
        })
        this.cameraalarm()
      }
    });
  }


  //获取单位列表
  getunitList = () => {
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        var arr = []
        for (var i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }



  //选择单位
  changeunit = (value) => {
    this.setState({
      siteId: value,
      pageNum: 1,
    }, function () {
      this.cameraalarm()
    })
  }

  render () {
    const listion = <img src={require('../images/close.png')} alt="" />
    const prooptions = this.state.unitlist.map((province) => <Option key={province.id}  >{province.name}</Option>);
    const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });


    return (
      <Layout id="alarm" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="风险推送" headStyle={{ fontSize: '18px' }}>
              <div>
                <div>
                  &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
                  <Select
                    style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                    placeholder="请选择所属单位"
                    onChange={this.changeunit}
                    value={this.state.siteId}
                    allowClear
                  >
                    {prooptions}
                  </Select>
                  &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
                  <RangePicker style={{ marginRight: '20px' }}
                    onChange={this.alarmtime}
                    disabledDate={disabledDate}
                    ranges={{
                      "今日": [moment(), moment()],
                      '本月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                  />
                  <Button type="primary" onClick={this.cameraquery}>查询</Button>
                  <div>
                    <Table
                      dataSource={this.state.alarmlist}
                      columns={nodeInfoTableColumns}
                      pagination={false}
                    />
                  </div>
                  <div style={{ textAlign: 'right', marginTop: '10px' }}>
                    <Pagination
                      onShowSizeChange={this.onShowSizeChange}
                      defaultCurrent={1}
                      onChange={this.pagechange}
                      total={this.state.alarmtotal}
                      hideOnSinglePage={true}
                      current={this.state.pageNum}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Content>
          <Modal
            title="报警详情"
            visible={this.state.detailvisible}
            width="300px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            {this.state.number || 1}人进入手术室
          </Modal>
          <Modal
            title="处置详情"
            visible={this.state.chuzhivisible}
            width="350px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            <div className="resultflex">
              <span>处置详情</span>
              <span className="resultright">{this.state.result}</span>
            </div>
            <div className="resultflex">
              <span>处置人员</span>
              <span className="resultright">{this.state.resultpeople}</span>
            </div>
            <div className="resultflex">
              <span>处置时间</span>
              <span className="resultright">{this.state.resulttime}</span>
            </div>

          </Modal>
          <Modal
            title="报警图片"
            visible={this.state.imgvisible}
            width="500px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            {this.state.alarmimg.map((item) => {
              return (
                <img src={item} alt="" style={{ width: '100%' }} />
              )
            })}

          </Modal>
          <Modal
            title="删除记录"
            visible={this.state.alarmdeletevisible}
            onOk={this.deletedevice}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该条记录吗？
          </Modal>
          <Modal
            title="添加说明"
            destroyOnClose
            onOk={this.explainok}
            visible={this.state.describevisible}
            centered
            width='400px'
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <TextArea rows={4} style={{ width: '100%', height: '200px' }}
              onChange={this.remarkchange}
              value={this.state.remark}
              maxLength="200"
              placeholder="请输入说明"
            />
            <span style={{ position: 'relative', left: '295px', bottom: '27px' }}>{this.state.remark.length}/200</span>
          </Modal>
        </Layout>
      </Layout >
    );
  }
}

export default App;
