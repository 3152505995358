import React from 'react'
// import { WebControl } from './utils/jsWebControl-1.0.0.min.js'
import $ from 'jquery'
import './videoboard.scss'
import JSEncrypt from 'jsencrypt'
import {
  Layout,
  Button,
  Row, Col, Spin, Tree, Tabs, DatePicker, message
} from "antd";
import { completeDeviceTree } from '../axios';
import moment from 'moment';
import { UndoOutlined, VideoCameraOutlined } from '@ant-design/icons'


function disabledDate (current) {
  // Can not select days before today and today
  return current && current > moment().subtract(0, 'days')
}


const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
const { DirectoryTree } = Tree;
const { TabPane } = Tabs
let oWebControl = null
let WebControl = null
//声明公用变量
var initCount = 0;
var pubKey = '';
export const injectScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.head.appendChild(script)
  })
}



class App extends React.Component {
  state = {
    collapsed: false,
    thirdpartylist: [],
    permissionlist: [],
    videoStatus: '0',
    falg: false,
    warningListDataSource: [],
    timedis: 'none',
    // appsecret: '22460026',
    // appkey: 'a9IK1qITKMSiuSYdamVG',
    // host: '118.178.57.33',
    // port: 4443,
    appsecret: '22357570',
    appkey: '1TF6fFpQ2cYfZr4rteOA',
    host: '122.224.50.67',
    port: 81,
    // number: "a8945fb3a658472ab0b5a8e454664727",
    number: localStorage.getItem('indexCode'),
    treeheight: 0
  };

  componentWillUnmount () {
    if (this.state.flag === true) {
      oWebControl.JS_Disconnect().then(function () { // oWebControl 为 WebControl 的对象 // 断开与插件服务的连接成功 
      }, function () { // 断开与插件服务的连接失败 
      });
    }

  }


  componentDidMount () {
    this.getlist()
    if (this.props.onRef !== undefined) {
      this.props.onRef(this);
    }
    const height = document.getElementsByClassName('ant-tree-list')[0].offsetHeight
    this.setState({
      begintime: moment().subtract(2, 'days'),
      endtime: moment().subtract(0, 'days'),
      treeheight: height
    })
    let that = this
    injectScript('https://mainimg.terabits.cn/jsWebControl-1.0.0.min.js').then(res => {
      WebControl = window.WebControl
      //加载后要做的事情
      that.initPlugin()
    })
    // console.log('object :>> ', document.getElementsByClassName('ant-tree-list')[0].offsetHeight);
  }

  // 创建WebControl实例与启动插件
  initPlugin (val) {
    //console.log(val)
    const con1 = document.getElementsByClassName('ant-col-18')
    const con2 = document.getElementsByClassName('ant-layout-sider-children')
    let width = 850
    let height = 500
    // //console.log(this.props.videoStatus)
    if (con1[0]) {
      width = con1[0].offsetWidth
      height = con2[0].offsetHeight - 40
    }
    let that = this
    oWebControl = new WebControl({
      szPluginContainer: "playWnd",                       //指定容器id
      iServicePortStart: 15900,                           //指定起止端口号，建议使用该值
      iServicePortEnd: 15909,
      cbConnectSuccess: function () {
        that.setState({
          flag: true,
        })
        // setCallbacks();
        //实例创建成功后需要启动服务
        oWebControl.JS_StartService("window", {
          dllPath: "./VideoPluginConnect.dll"
        }).then(function () {
          oWebControl.JS_CreateWnd("playWnd", width, height).then(function () {         //JS_CreateWnd创建视频播放窗口，宽高可设定
            //console.log("JS_CreateWnd success");
            that.init(val);                                 //创建播放实例成功后初始化
          });
        }, function () {

        });
      },
      cbConnectError: function () {
        //console.log("cbConnectError");
        oWebControl = null;
        $("#playWnd").html("插件未启动，正在尝试启动，请稍候...");
        that.setState({
          flag: false,
        })
        WebControl.JS_WakeUp("VideoWebPlugin://");        //程序未启动时执行error函数，采用wakeup来启动程序
        initCount++;
        if (initCount < 3) {
          setTimeout(function () {
            that.initPlugin();
          }, 3000)
        } else {
          that.setState({
            flag: false,
          })
          $("#playWnd").html("插件启动失败，请检查插件是否安装！");
        }
      },
      cbConnectClose: function () {
        //console.log("cbConnectClose");
        oWebControl = null;
      }
    });

  }

  //初始化
  init (val) {
    //console.log(val)

    // oWebControl.JS_RepairPartWindow(0, 0, 100, 100);
    const con1 = document.getElementsByClassName('ant-col-18')
    const con2 = document.getElementsByClassName('ant-layout-sider-children')
    let width = 850
    let height = 500
    // //console.log(this.props.videoStatus)
    if (con1[0]) {
      width = con1[0].offsetWidth
      height = con2[0].offsetHeight - 40
    }
    this.getPubKey(() => {

      var appkey = this.state.appsecret;                           //综合安防管理平台提供的appkey，必填
      var secret = this.setEncrypt(this.state.appkey);   //综合安防管理平台提供的secret，必填
      var ip = this.state.host;                           //综合安防管理平台IP地址，必填

      // var appkey = '22460026';                           //综合安防管理平台提供的appkey，必填
      // var secret = this.setEncrypt('a9IK1qITKMSiuSYdamVG');   //综合安防管理平台提供的secret，必填
      // var ip = '118.178.57.33';
      var port = this.state.port;
      var playMode = val ? Number(val) : 0;                                  //初始播放模式：0-预览，1-回放
      var snapDir = "D:\\SnapDir";                       //抓图存储路径
      var videoDir = "D:\\VideoDir";                     //紧急录像或录像剪辑存储路径
      var layout = "1x1";                                //playMode指定模式的布局
      var enableHTTPS = 1;                               //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
      var encryptedFields = 'secret';					   //加密字段，默认加密领域为secret
      var showToolbar = 1;                               //是否显示工具栏，0-不显示，非0-显示
      var showSmart = 0;                                 //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
      var buttonIDs = "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769";  //自定义工具条按钮
      //var reconnectTimes = 2;                            // 重连次数，回放异常情况下有效
      //var reconnectTime = 4;                             // 每次重连的重连间隔 >= reconnectTime
      ////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

      oWebControl.JS_RequestInterface({
        funcName: "init",
        argument: JSON.stringify({
          appkey: appkey,                           //综合安防管理平台提供的appkey，必填
          secret: secret,   //综合安防管理平台提供的secret，必填
          ip: ip,                           //综合安防管理平台IP地址，必填
          //API网关IP地址
          playMode: playMode,                        //播放模式（决定显示预览还是回放界面）
          port: port,                                //端口
          snapDir: snapDir,                          //抓图存储路径
          videoDir: videoDir,                        //紧急录像或录像剪辑存储路径
          layout: layout,                            //布局
          enableHTTPS: enableHTTPS,                  //是否启用HTTPS协议
          encryptedFields: encryptedFields,          //加密字段
          showToolbar: showToolbar,                  //是否显示工具栏
          showSmart: showSmart,                      //是否显示智能信息
          buttonIDs: buttonIDs                       //自定义工具条按钮
          //reconnectTimes：reconnectTimes,            //重连次数
          //reconnectDuration：reconnectTime           //重连间隔
        })
      }).then(function (oData) {

        oWebControl.JS_Resize(width, height);  // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
      });
    });


    setTimeout(() => {
      if (this.state.indexCode) {
        if (this.state.videoStatus === '0') {
          this.boardPreivew(this.state.indexCode)
        } else {
          this.startPlayBack(this.state.indexCode)
        }
      }
    }, 1700)
  }





  // 获取公钥
  getPubKey (callback) {
    oWebControl.JS_RequestInterface({
      funcName: "getRSAPubKey",
      argument: JSON.stringify({
        keyLength: 1024
      })
    }).then(function (oData) {
      //console.log(oData);
      if (oData.responseMsg.data) {
        pubKey = oData.responseMsg.data;
        callback()
      }
    })
  }


  // RSA加密
  setEncrypt = (value) => {
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubKey);
    return encrypt.encrypt(value);
  }
  // 预览
  boardPreivew = (cameraIndexCode) => {
    var streamMode = 0;                                     //主子码流标识：0-主码流，1-子码流
    var transMode = 1;                                      //传输协议：0-UDP，1-TCP
    var gpuMode = 0;                                        //是否启用GPU硬解，0-不启用，1-启用
    var wndId = -1;                                         //播放窗口序号（在2x2以上布局下可指定播放窗口）
    oWebControl.JS_RequestInterface({
      funcName: "startPreview",
      argument: JSON.stringify({
        cameraIndexCode: cameraIndexCode,                //监控点编号
        streamMode: streamMode,                         //主子码流标识
        transMode: transMode,                           //传输协议
        gpuMode: gpuMode,                               //是否开启GPU硬解
        wndId: wndId                                     //可指定播放窗口
      })
    })

  }
  // 回放
  startPlayBack = (cameraIndexCode) => {
    var startTimeStamp = this.state.begintime;
    var endTimeStamp = this.state.endtime;
    //console.log(moment(endTimeStamp).format(), endTimeStamp)
    var recordLocation = 1;                                     //录像存储位置：0-中心存储，1-设备存储
    var transMode = 1;                                          //传输协议：0-UDP，1-TCP
    var gpuMode = 0;                                            //是否启用GPU硬解，0-不启用，1-启用
    var wndId = -1;                                             //播放窗口序号（在2x2以上布局下可指定播放窗口）
    // //console.log(this.state.begintime)
    // //console.log(this.state.endtime)
    oWebControl.JS_RequestInterface({
      funcName: "startPlayback",
      argument: JSON.stringify({
        cameraIndexCode: cameraIndexCode,                   //监控点编号
        startTimeStamp: Math.floor(startTimeStamp / 1000).toString(),  //录像查询开始时间戳，单位：秒
        endTimeStamp: Math.floor(endTimeStamp / 1000).toString(),      //录像结束开始时间戳，单位：秒
        recordLocation: recordLocation,                     //录像存储类型：0-中心存储，1-设备存储
        transMode: transMode,                               //传输协议：0-UDP，1-TCP
        gpuMode: gpuMode,                                   //是否启用GPU硬解，0-不启用，1-启用
        wndId: wndId                                         //可指定播放窗口
      })
    })
  }


  endPlayback = () => {
    oWebControl.JS_RequestInterface({
      funcName: "stopAllPlayback"
    })
  }

  //打开全屏
  openfull = () => {
    oWebControl.JS_RequestInterface({
      funcName: "setFullScreen"
    })
  }

  download = () => {
    window.open("https://mainimg.terabits.cn/VideoWebPlugin.exe", '_self')
  }


  number = (e) => {
    this.setState({
      number: e.target.value
    })
  }



  back = () => {
    window.location.href = '/app/device';
  }


  // 切换标签
  changeTab = (val) => {
    this.setState({
      indexCode: null
    })
    if (val === "1") {
      this.setState({
        timedis: 'inline-block'
      })
    } else {
      this.setState({
        timedis: 'none'
      })
    }
    //console.log(val)

    this.destroy()
    this.initPlugin(val)
    this.setState({
      videoStatus: val
    })
  }

  // 销毁插件
  destroy = () => {
    if (this.state.flag === true) {
      oWebControl.JS_DestroyWnd().then(function () { // oWebControl 为 WebControl 的对象
        // 销毁插件窗口成功
      }, function () {
        // 销毁插件窗口失败
      })
    }
  }


  // 初始化请求设备数据
  getlist = () => {
    this.setState({
      loading: true
    })
    completeDeviceTree([

    ]).then(res => {
      if (res.data && res.data.message === "success") {
        //console.log(res.data.data)
        const ass = (data) => {
          let item = [];
          data.forEach((list, i) => {
            let newData = {};
            newData.key = list.indexCode;
            newData.value = list.indexCode;
            newData.title = list.name;
            newData.appkey = list.appkey;
            newData.appsecret = list.appsecret;
            newData.host = list.host
            newData.children = list.children ? ass(list.children) : list(list.indexCode);    //如果还有子集，就再次调用自己
            item.push(newData);
          });
          return item;
        }
        // console.log('ass(res.data.data) :>> ', ass(res.data.data));
        this.setState({
          treeData: ass(res.data.data),
          loading: false,
        })
      }
    });
  }

  onSelect = (val, item) => {

    // var that = this
    //console.log(val, item)

    if (this.state.falg === false) {
      this.setState({
        appkey: item.node.appkey,
        appsecret: item.node.appsecret,
        indexCode: val[0],
        falg: true,
        host: item.node.host ? item.node.host.split(':')[0] : null,
        port: item.node.host ? parseInt(item.node.host.split(':')[1]) : null,
      }, function () {
        this.destroy()
        this.initPlugin(this.state.videoStatus)
        setTimeout(() => {
          this.setState({
            falg: false
          })
        }, 1000)
        //console.log(this.state.appkey)
        //console.log(this.state.appsecret)
        //console.log(this.state.host)
        //console.log(this.state.port)
      })
    }


    // this.setState({
    //   treenodes: val[0]
    // })
  }


  //时间选择
  timeonChange = (value, dateString) => {
    //console.log(dateString[0] + " 00:00:00")
    if (dateString[0] === "") {
      this.setState({
        begintime: undefined
      })
    } else {
      this.setState({
        begintime: moment(dateString[0] + " 00:00:00"),
      });
    }
    if (dateString[1] === "") {
      this.setState({
        endtime: undefined
      })
    } else {
      this.setState({
        endtime: moment(dateString[1] + " 23:59:59"),
      });
    }
  }

  onOpenChange = (value) => {
    if (value === true) {
      oWebControl.JS_CuttingPartWindow(0, 0, 10000, 310);
    } else {
      oWebControl.JS_RepairPartWindow(0, 0, 10000, 310);
    }
  }

  findtime = () => {
    if (!this.state.indexCode) {
      message.error('请选择摄像头')
    } else {
      this.startPlayBack(this.state.indexCode)
    }

  }


  render () {
    const { treenodes, treeData } = this.state
    const operations = <div style={{ background: "white" }}>
      <div className="item" style={{ marginBottom: '10px', marginTop: '10px', }}>
        <span style={{ paddingRight: '10px', display: this.state.timedis }}>
          起止时间：
          <RangePicker
            style={{ width: '240px' }}
            format={dateFormat}
            ranges={{ 今天: [moment().startOf('day'), moment().endOf('day')], '本月': [moment().startOf('month'), moment().endOf('month')] }}
            onChange={this.timeonChange}
            onOpenChange={this.onOpenChange}
            disabledDate={disabledDate}
            value={[this.state.begintime, this.state.endtime]}
          />
          <Button type="primary" onClick={() => this.findtime()} style={{ marginLeft: '10px', marginRight: '10px' }}>查询</Button>
        </span>
        <Button type="primary" onClick={() => this.download()}>插件下载</Button>
        {/* （若无插件请点击此按钮进行插件下载并安装） */}

      </div>



    </div>;
    return (
      <Layout style={{ margin: "20px 0px", }} id="videoboard">
        {/* <Card title={localStorage.getItem('cameralocation') + "实时画面"} headStyle={{ color: '#2a2a2a', fontSize: '18px' }}
          extra={
            <div>
              <Button type="primary" onClick={this.boardPreivew} >
                播放实时画面
              </Button>
              <Button type="primary" onClick={this.back} style={{ marginLeft: "15px" }} >
                返回
              </Button>
            </div>}
        >
        </Card> */}
        <Row >
          <Col span={6}>

            <Spin spinning={this.state.loading} tip="设备列表正在加载中...">

              <DirectoryTree
                // multiple
                // expandedKeys={this.state.expandedKeys}
                // expandedKeys={expandFlag}
                onSelect={this.onSelect}
                onExpand={this.onExpand}
                // onExpand={onExpand}
                // height={'100%'}
                height={this.state.treeheight}
                style={{ height: "81vh" }}
                selectedKeys={treenodes}
                treeData={treeData}
                fieldNames={{ title: 'title', key: 'key', children: 'children' }}
              />
            </Spin>
          </Col>
          <Col span={18}>
            <Tabs defaultActiveKey="0" onChange={this.changeTab} tabBarExtraContent={operations}>
              <TabPane
                tab={
                  <span>
                    <VideoCameraOutlined />
                    实时预览
                  </span>
                }
                key="0"
              >
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <UndoOutlined />
                    录像回放
                  </span>
                }
                key="1"
              >

              </TabPane>
            </Tabs>
            <div id="playWnd" className="playWnds" ></div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default App;
