import React from "react";
import {
  Card,
  Layout,
  Button,
  Input,
  Table,
  Modal,
  Cascader, message, Select, Drawer, Form, AutoComplete, Tooltip, Badge
} from "antd";
import { getunitList, addunit, deleteunit, getpersonList, addperson, getdeviceList, deleteperson, selectNotBach, addlogoperate, getListByName, getListByNames, getByIdBydoctor } from '../axios';

import validator from "../validator"
import "./unit.scss";
import moment from 'moment';



const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const AMap = window.AMap;
// const dateFormat = 'YYYY-MM-DD';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addLoading: false,
      addName: '添加单位',
      videoListDataSource: [],
      device_ip: null,
      scenetimelist: [],
      devicealarmlist: [],
      sensorlist: [],
      typenone: "inline-block",
      weekback: 'orange',
      yearback: '#11A7D7',
      longitude: 122.11404,
      typedis: 'none',
      uniteditdis: 'none',
      unitdeletedis: 'none',
      latitude: 30.01978,
      monthback: '#11A7D7',
      otherInformation: [],
      professionInformation: [],
      allpersonlist: [],
      deviceList: JSON.parse(localStorage.getItem('AreaTree')),
      dateKey: 1,
      unbindColumns: [
        {
          title: "卡号",
          dataIndex: "cardNo",
          align: "center"
        },
        {
          title: "设备编号",
          dataIndex: "deviceId",
          align: "center"
        },
        {
          title: "设备位置",
          dataIndex: "deviceLocation",
          align: "center"
        }
      ]
    };

    if (localStorage.getItem('menubtnlist').split(',').indexOf("2034") === -1 && localStorage.getItem('menubtnlist').split(',').indexOf("2035") === -1) {
      this.personColumns = [
        {
          title: "姓名",
          dataIndex: "name",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div>
                <span
                >
                  {text}
                </span>
              </div>
            );

          }
        },
        {
          title: "卡号",
          dataIndex: "rfidTagNo",
          align: 'left',
        },
        {
          title: "人脸识别号",
          dataIndex: "cardNo",
          align: 'left',
        }, {
          title: "职级",
          dataIndex: "position",
          align: 'left',
        },
        {
          title: "所属设备",
          dataIndex: "deviceLocation",
          align: 'center',
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        }, {
          title: "详情",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            if (record.doctorId) {
              return (
                <div>
                  <span
                    style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.lookdetail(text, record, index)}>
                    查看
                  </span>
                </div>
              );
            } else {
              return (
                <div>
                  <span
                    style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.lookdetail(text, record, index)}>
                    无
                  </span>
                </div>
              );
            }

          }
        },
      ];
    } else {
      this.personColumns = [
        {
          title: "姓名",
          dataIndex: "name",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div>
                <span
                >
                  {text}
                </span>
              </div>
            );

          }
        },
        {
          title: "卡号",
          dataIndex: "rfidTagNo",
          align: 'left',
        },
        {
          title: "人脸识别号",
          dataIndex: "cardNo",
          align: 'left',
        }, {
          title: "职级",
          dataIndex: "position",
          align: 'left',
        },
        {
          title: "所属设备",
          dataIndex: "deviceLocation",
          align: 'center',
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        }, {
          title: "详情",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            if (record.doctorId) {
              return (
                <div>
                  <span
                    style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.lookdetail(text, record, index)}>
                    查看
                  </span>
                </div>
              );
            } else {
              return (
                <div>
                  <span
                    style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.lookdetail(text, record, index)}>
                    无
                  </span>
                </div>
              );
            }

          }
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span onClick={() => this.changeperson(text, record, index)} style={{ display: this.state.editdis, cursor: 'pointer' }}>
                  <img src={require('../images/edit.png')} alt="" />
                </span>
                <span style={{ marginLeft: '10px', display: this.state.deletedis, cursor: 'pointer' }} onClick={() => this.persondelete(text, record, index)} >
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        },
      ];
    }


    if (localStorage.getItem('menubtnlist').split(',').indexOf("2011") === -1 && localStorage.getItem('menubtnlist').split(',').indexOf("2010") === -1) {
      this.nodeInfoTableColumns = [
        {
          title: "单位名称",
          dataIndex: "unit",
          align: 'left'
        },
        {
          title: "详细地址",
          dataIndex: "address",
          align: 'left',
        }, {
          title: "设备数量",
          dataIndex: "deviceQuantity",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span>{text} </span>
              </div>
            )
          }
        }, {
          title: "人员管理",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.persondetail(text, record, index)}
                  style={{ color: '#11A7D7', cursor: 'pointer' }}>
                  <Tooltip title="与人员信息表已匹配上的人数 / 医护人员总人数">
                    <span style={{ color: '#29DB6F' }}>
                      {(record.sumStaff - record.notPairPersonSum) || 0}
                    </span>/
                    <Badge dot count={record.notEnterPersonSum || 0}>
                      <span>
                        {record.sumStaff}
                      </span>
                    </Badge>
                  </Tooltip>



                </span>
              </div>
            );
          }
        },
        {
          title: "负责人",
          dataIndex: "id",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.unitdetail(text, record, index)}
                  style={{ color: '#11A7D7', cursor: 'pointer' }}>
                  详情
                </span>
              </div>
            );
          }
        },

        {
          title: "创建时间",
          dataIndex: "gmtCreate",
          width: 200,
          align: 'right',
          sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
      ];
    } else {
      this.nodeInfoTableColumns = [
        {
          title: "单位名称",
          dataIndex: "unit",
          align: 'left'
        },
        {
          title: "详细地址",
          dataIndex: "address",
          align: 'left',
        }, {
          title: "设备数量",
          dataIndex: "deviceQuantity",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span>{text} </span>
              </div>
            )
          }
        }, {
          title: "人员管理",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.persondetail(text, record, index)}
                  style={{ color: '#11A7D7', cursor: 'pointer' }}>
                  <Tooltip title="与人员信息表已匹配上的人数 / 医护人员总人数">
                    <span style={{ color: '#29DB6F', fontSize: "14px" }}>
                      {(record.sumStaff - record.notPairPersonSum) || 0}
                    </span>/
                    <Badge dot count={record.notEnterPersonSum || 0}>
                      <span style={{ fontSize: "14px" }}>
                        {record.sumStaff}
                      </span>
                    </Badge>
                  </Tooltip>
                </span>
              </div>
            );
          }
        },
        {
          title: "负责人",
          dataIndex: "id",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.unitdetail(text, record, index)}
                  style={{ color: '#11A7D7', cursor: 'pointer' }}>
                  详情
                </span>
              </div>
            );
          }
        },

        {
          title: "创建时间",
          dataIndex: "gmtCreate",
          width: 200,
          align: 'center',
          sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span onClick={() => this.edit(text, record, index)} style={{ display: this.state.uniteditdis }}>
                  <img src={require('../images/edit.png')} alt="" />
                </span>
                <span style={{ marginLeft: '10px', display: this.state.unitdeletedis }} onClick={() => this.unitdelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        },

      ];
    }

  }

  componentWillMount () {
    var arr = localStorage.getItem('menubtnlist').split(',')
    if (arr.indexOf('2001') !== -1) {
      this.setState({
        typedis: 'inline'
      })
    } else {
      this.setState({
        typedis: 'none'
      })
    }

    if (arr.indexOf('2011') !== -1) {
      this.setState({
        unitdeletedis: 'inline'
      })
    } else {
      this.setState({
        unitdeletedis: 'none'
      })
    }

    if (arr.indexOf('2010') !== -1) {
      this.setState({
        uniteditdis: 'inline'
      })
    } else {
      this.setState({
        uniteditdis: 'none'
      })
    }

    if (arr.indexOf('2032') !== -1) {
      this.setState({
        lookpeople: true
      })
    } else {
      this.setState({
        lookpeople: false
      })
    }

    if (arr.indexOf('2033') !== -1) {
      this.setState({
        adddis: "inline"
      })
    } else {
      this.setState({
        adddis: "none"
      })
    }

    if (arr.indexOf('2035') !== -1) {
      this.setState({
        deletedis: "inline"
      })
    } else {
      this.setState({
        deletedis: "none"
      })
    }

    if (arr.indexOf('2034') !== -1) {
      this.setState({
        editdis: "inline"
      })
    } else {
      this.setState({
        editdis: "none"
      })
    }


    document.title = "单位管理";


    // this.initMap()

  }

  componentDidMount () {

    this.unitlist()


  }

  personlist = () => {
    getpersonList([
      localStorage.getItem('token'),
      this.state.unitid
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          personlist: res.data.data,
        })
      }
      // else {
      //     message.error(res.data.message)
      // }
    });
  }

  persondetail = (text, record, index) => {
    if (this.state.lookpeople === false) {
      message.error('您暂无权限查看')
    } else {
      this.setState({
        personunitname: record.unit,
        personvisible: true,
        unitid: record.id
      }, function () {
        this.personlist()
      })
    }
  }

  unitlist = () => {
    getunitList([
      localStorage.getItem('token'),
      this.state.searchname
    ]).then(res => {
      //console.log(res)
      if (res.data && res.data.message === "success") {
        this.setState({
          sitelist: res.data.data
        }, function () {
          if (this.state.sitelist.length > 10) {
            this.setState({
              page: true
            })
          } else {
            this.setState({
              page: false
            })
          }
        })
      }
    });
  }



  //调用地图
  initMap () {
    // setTimeout(() => {
    var that = this
    var map = new AMap.Map("mapContainer", {
      resizeEnable: true,
      keyboardEnable: false,
      // center: [122.11404, 30.01978],//地图中心点
      center: [this.state.longitude, this.state.latitude],//地图中心点
      zoom: 15,//地图显示的缩放级别
    });

    var marker = new AMap.Marker({
      position: new AMap.LngLat(this.state.longitude, this.state.latitude),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: this.state.unitname
    });

    map.add(marker);

    AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function () {
      var autoOptions = {
        city: "3301", //城市，默认全国
        citylimit: true,
        input: "facilityLocation",//使用联想输入的input的id
      };
      var autocomplete = new AMap.Autocomplete(autoOptions);

      // var clickEventListener = map.on('click', function (e) {
      //     //console.log(e)
      //     document.getElementById('longitudetext').innerHTML = e.lnglat.getLng();
      //     document.getElementById('latitudetext').innerHTML = e.lnglat.getLat();
      //     // alert(e.lnglat.getLng() + ',' + e.lnglat.getLat())
      // });
      var placeSearch = new AMap.PlaceSearch({
        // city: '浙江',
        map: map
      })
      AMap.event.addListener(autocomplete, "select",
        function (e) {
          //console.log(e)
          // //console.log(e.poi.name)
          // //console.log(e.poi.adcode)
          //TODO 针对选中的poi实现自己的功能
          placeSearch.setCity(e.poi.adcode);
          placeSearch.search(e.poi.name);
          that.formRef.current.setFieldsValue({
            address: e.poi.district + e.poi.address,
          })
          that.setState({
            unitname: e.poi.name,
            address: e.poi.district + e.poi.address,
            area: e.poi.district,
            longitude: e.poi.location.lng,
            latitude: e.poi.location.lat,
            areacode: e.poi.adcode,
          })
        },

      );
    })
    // }, 0)


  }

  //关键字录入
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })
  }

  //查看人员详情
  lookdetail = (text, record, index) => {
    this.setState({
      name: record.name,
      unitName: record.unitName,
      doctorId: record.doctorId,
    }, function () {
      if (!this.state.doctorId) {
        message.error('暂无信息')
      } else {
        getByIdBydoctor([
          record.doctorId,
        ]).then(res => {
          if (res.data.data && res.data.message === "success") {
            this.setState({
              personsdetail: true,
              doctorName: res.data.data.doctorName,
              institutionName: res.data.data.institutionName,
              doctorLicence: res.data.data.doctorLicence,
              role: res.data.data.role,

            })
          }
        })
      }
    })

  }


  //编辑单位
  edit = (text, record, index) => {
    //console.log(record)
    setTimeout(() => {
      var that = this
      that.formRef.current.setFieldsValue({
        unitname: record.unit,
        address: record.address,
        phone: record.phone,
      })
    }, 100)
    this.setState({
      unitname: record.unit,
      unitnameago: record.unit,
      address: record.address,
      addressago: record.address,
      unitid: record.id,
      scenetype: record.unitType,
      addresslists: [record.cityId, record.districtId],
      addresslistsago: [record.cityId, record.districtId],
      cityid: record.cityId,
      areaid: record.districtId,
      username: record.userName,
      usernameago: record.userName,
      phone: record.phone,
      phoneago: record.phone,
      latitude: record.latitude,
      longitude: record.longitude,
      unitvisible: true,
      addName: '编辑单位'
    }, function () {
      this.initMap()
    })
  }

  //打开添加单位弹窗
  addunit = () => {

    // var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789';
    // var maxPos = $chars.length;
    // var pwd = '';
    // for (var i = 0; i < 32; i++) {
    //     pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    // }
    this.setState({
      unitvisible: true,
      addName: '添加单位'
      // uuid: pwd
    }, () => {
      this.initMap()
    })
  }

  //关闭添加单位弹窗
  handleCancel = () => {
    // console.log('this.formRef :>> ', this.formRef);
    // setTimeout(() => {
    //   .resetFields()
    // }, 100)
    if (this.formRef.current) {
      setTimeout(() => {
        this.formRef.current.resetFields()
      }, 100)
    }
    this.setState({
      unitvisible: false,
      deleteunitvisible: false,
      realname: undefined,
      allpersonlist: [],
      unitid: undefined,
      unitname: undefined,
      address: undefined,
      scenetype: undefined,
      addresslists: [],
      cityid: undefined,
      areaid: undefined,
      username: undefined,
      phone: undefined,
      peoplevisible: false,
      hotvisible: false,
      devicevisible: false,
      personvisible: false,
    })
  }

  //关闭添加单位弹窗
  handleCancelss = () => {
    this.setState({
      unitvisible: false,
      deleteunitvisible: false,
      unitid: undefined,
      unitname: undefined,
      address: undefined,
      scenetype: undefined,
      addresslists: [],
      cityid: undefined,
      areaid: undefined,
      username: undefined,
      phone: undefined,
      peoplevisible: false,
      hotvisible: false,
      devicevisible: false,
      personvisible: false,
      allpersonlist: [],
      realname: undefined,
    })
  }

  //关闭添加单位弹窗
  personCancel = () => {
    this.setState({
      persondetailvisible: false,
      personsdetail: false,
      allpersonlist: [],
    })
  }




  //设备位置选择
  findArea = (e) => {
    //console.log(e)
    this.setState({
      addresslists: e,
      cityid: e[0] === undefined ? null : e[0],
      areaid: e[1] === undefined ? null : e[1],
    });
  }

  //单位名称
  unitname = (e) => {
    this.setState({
      unitname: e.target.value.replace(/\s*/g, '')
    })
  }

  //单位地址
  address = (e) => {
    this.setState({
      address: e.target.value.replace(/\s*/g, '')
    })
  }

  //负责人姓名
  username = (e) => {
    this.setState({
      username: e.target.value.replace(/\s*/g, '')
    })
  }

  //联系方式
  phone = (e) => {
    this.setState({
      phone: e.target.value.replace(/[^0-9.]/g, '')
    })
  }

  //添加单位
  addOk = () => {
    //console.log(this.state.address)
    if (!this.state.unitname) {
      message.error('请输入单位名称')
    }
    else if (!this.state.address) {
      message.error('请输入详细地址')
    }
    else if (!this.state.cityid || !this.state.areaid) {
      message.error('请选择所属区域')
    }
    else if (!this.state.username) {
      message.error('请输入负责人姓名')
    }
    else if (!this.state.phone) {
      message.error('请输入负责人联系电话')
    } else {
      this.setState({
        addLoading: true
      })
      addunit([
        this.state.unitname,
        this.state.address,
        31,
        this.state.cityid,
        this.state.areaid,
        this.state.username,
        this.state.phone,
        this.state.longitude,
        this.state.latitude,
        this.state.unitid,
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          if (!this.state.unitid) {
            message.success('添加成功')

            addlogoperate([
              localStorage.getItem("realname"),
              `${localStorage.getItem("realname") + "添加了" + this.state.unitname + "，(其中单位名称：" + this.state.unitname
              + "，详细地址：" + this.state.address + "，负责人姓名：" + this.state.username + "，负责人电话：" + this.state.phone + ")。"}`,
              3,
              3,
              moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              "单位管理"
            ]).then(res => {
              if (res.data && res.data.status === 200) {

              } else {
                // message.error(res.data.message)
              }
            });


          } else {
            message.success('修改成功')
            var list = ""
            if (this.state.unitname !== this.state.unitnameago) {
              list += "把" + this.state.unitnameago + "修改为" + this.state.unitname + "，"
            }
            if (this.state.address !== this.state.addressago) {
              list += "把" + this.state.addressago + "修改为" + this.state.address + "，"
            }
            if (JSON.stringify(this.state.addresslists) !== JSON.stringify(this.state.addresslistsago)) {
              list += "把" + this.state.addresslistsago + "修改为" + this.state.addresslists + "，"
            }
            if (this.state.username !== this.state.usernameago) {
              list += "把" + this.state.usernameago + "修改为" + this.state.username + "，"
            }
            if (this.state.phone !== this.state.phoneago) {
              list += "把" + this.state.phoneago + "修改为" + this.state.phone + "，"
            }
            list = list.substring(0, list.length - 1)
            addlogoperate([
              localStorage.getItem("realname"),
              `${localStorage.getItem("realname") + "修改" + this.state.unitnameago + "单位：" + list + "。"}`,
              5,
              3,
              moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              "单位管理"
            ]).then(res => {
              if (res.data && res.data.status === 200) {

              } else {
                // message.error(res.data.message)
              }
            });
          }
          this.setState({
            unitvisible: false,
            unitid: undefined,
            addLoading: false
          })
          this.handleCancel()
          this.unitlist()
        } else {
          message.error(res.data.message)
          this.setState({
            addLoading: false
          })
        }
      });
    }
  }

  //打开删除单位弹窗
  unitdelete = (text, record, index) => {
    this.setState({
      deleteunitvisible: true,
      unitid: record.id,
      unitname: record.unit,
    })
  }

  //删除单位
  deleteunit = () => {
    deleteunit([
      this.state.unitid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        this.unitlist()
        this.setState({
          deleteunitvisible: false,
        })

        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "删除了" + this.state.unitname}`,
          4,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "单位管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });

      } else {
        message.error(res.data.message)
      }
    });
  }


  //搜索框录入
  searchname = (e) => {
    this.setState({
      searchname: e.target.value
    })
  }

  //搜索
  onsearch = () => {
    this.unitlist()

    // addlogoperate([
    //     localStorage.getItem("realname"),
    //     `${"单位管理--" + localStorage.getItem("realname") + "以" + "'" + this.state.searchname + "'" + "为条件查询了单位列表"}`,
    //     6,
    //     3,
    //     moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    // ]).then(res => {
    //     if (res.data && res.data.status === 200) {

    //     } else {
    //         // message.error(res.data.message)
    //     }
    // });
  }

  //场景类型
  scenetype = (value) => {
    //console.log(value)
    this.setState({
      scenetype: value
    })
  }

  //打开负责人详情
  unitdetail = (text, record, index) => {
    this.setState({
      peoplevisible: true,
      userName: record.userName,
      phone: record.phone,
    })
  }




  //打开设备数量弹窗
  devicenum = (text, record, index) => {
    this.setState({
      devicevisible: true,
      ysyDeviceQuantity: record.ysyDeviceQuantity,
      oneNetDeviceQuantity: record.oneNetDeviceQuantity,
    })
  }

  //打开添加人员弹窗
  addperson = () => {
    getdeviceList([
      localStorage.getItem('token'),
      1,
      31,
      this.state.cityid,
      this.state.areaid,
      this.state.unitid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          sensorlist: res.data.data
        })
      }
    });
    this.setState({
      addvisible: true
    })
  }

  //所属设备
  deviceId = (value, b) => {
    //console.log(value, b.children)
    this.setState({
      deviceId: value,
      devicename: b.children
    })
  }

  handleSearch = value => {
    if (value) {
      fetch(value, data => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };

  //输入姓名
  namechange = (value) => {
    //console.log(value)
    this.setState({
      realname: value.replace(/\s*/g, '').slice(0, 30),
    })
    getListByNames([
      value,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          allpersonlist: res.data.data,
        })
      }
    });
  }

  //确认姓名
  namechanges = (value, b) => {
    //console.log(value)
    //console.log(b)
    if (b.realname) {
      this.setState({
        doctorId: value,
        realname: b.realname,
        position: b.name,
      })
    } else {
      this.setState({
        doctorId: null,
        realname: b.realname,
        position: this.state.positionago,
      })
    }

  }

  //输入人脸识别
  cardNo = (e) => {
    this.setState({
      cardNo: e.target.value.replace(/[^0-9.]/g, '')
    })
  }
  //输入卡号
  inputNo = (e) => {
    this.setState({
      rfidTagNo: e.target.value
    })
  }

  //输入职级
  position = (e) => {
    this.setState({
      position: e
    })
  }

  //确认添加人员
  addpersonOk = () => {
    addperson([
      localStorage.getItem('token'),
      this.state.unitid,
      this.state.realname,
      this.state.cardNo,
      this.state.position,
      this.state.deviceId,
      this.state.doctorId,
      this.state.rfidTagNo
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('添加成功')
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "在" + this.state.personunitname + "单位中添加了名为" + this.state.realname + "的人员，(其中人员姓名：" + this.state.realname
          + "，人员卡号：" + this.state.cardNo + "，人员职级：" + this.state.position + "，人员信息录入对应设备：" + this.state.devicename + ")。"}`,
          3,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "人员管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });


        this.setState({
          addvisible: false,
        })
        this.personlist()
        this.unitlist()
        this.handleCancels()
      } else {
        message.error(res.data.message)
      }
    });
  }

  //打开删除人员弹窗
  persondelete = (text, record, index) => {
    this.setState({
      personid: record.id,
      deletepersonvisible: true,
      unitname: record.name,
    })
  }

  //打开编辑人员弹窗
  changeperson = (text, record, index) => {
    getdeviceList([
      localStorage.getItem('token'),
      1,
      31,
      this.state.cityid,
      this.state.areaid,
      this.state.unitid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          sensorlist: res.data.data
        })
      }
    });

    this.setState({
      personid: record.id,
      changevisible: true,
      realname: record.name,
      realnameago: record.name,
      cardNo: record.cardNo,
      cardNoago: record.cardNo,
      doctorId: record.doctorId,
      position: record.position,
      positionago: record.position,
      deviceId: record.deviceId,
      deviceIdago: record.deviceId,
      rfidTagNo: record.rfidTagNo
    })
  }

  //关闭删除人员弹窗
  handleCancels = () => {
    this.setState({
      deletepersonvisible: false,
      changevisible: false,
      name: undefined,
      cardNo: undefined,
      position: undefined,
      deviceId: undefined,
      unbindpeoplevisible: undefined,
      addvisible: false,
      allpersonlist: [],
      realname: undefined,
      rfidTagNo: undefined
    })
  }

  //确认删除
  deleteperson = () => {
    deleteperson([
      localStorage.getItem('token'),
      this.state.personid
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "删除了" + this.state.personunitname + "下面名为" + this.state.unitname + "的人员"}`,
          4,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "人员管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });
        this.setState({
          deletepersonvisible: false,
        })
        this.personlist()
        this.unitlist()
      }
    });
  }

  //确认修改
  changepersonOk = () => {
    addperson([
      localStorage.getItem('token'),
      this.state.unitid,
      this.state.realname,
      this.state.cardNo,
      this.state.position,
      this.state.deviceId,
      this.state.doctorId,
      this.state.rfidTagNo,
      this.state.personid
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('修改成功')

        var list = ""
        if (this.state.realname !== this.state.realnameago) {
          list += "把姓名" + this.state.realnameago + "修改为" + this.state.realname + "，"
        }
        if (this.state.cardNo !== this.state.cardNoago) {
          list += "把卡号" + this.state.cardNoago + "修改为" + this.state.cardNo + "，"
        }
        if (this.state.position !== this.state.positionago) {
          list += "把职级" + this.state.positionago + "修改为" + this.state.position + "，"
        }
        if (this.state.deviceId !== this.state.deviceIdago) {
          list += "把设备编号" + this.state.deviceIdago + "修改为" + this.state.deviceId + "，"
        }
        list = list.substring(0, list.length - 1)
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "修改" + this.state.realname + "人员信息：" + list + "。"}`,
          5,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "人员管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          } else {
            // message.error(res.data.message)
          }
        });

        this.setState({
          changevisible: false,
          allpersonlist: [],
        })
        this.personlist()
        this.unitlist()
        this.handleCancels()
      } else {
        message.error(res.data.message)
      }
    });
  }

  //查询未匹配记录
  openunbind = () => {
    selectNotBach([
      localStorage.getItem('token'),
      this.state.unitid,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        // message.success('修改成功')
        this.setState({
          unbindpeoplevisible: true,
          unbindpersonlist: res.data.data,
        })

      }
    });
  }

  //
  formRef = React.createRef()


  lookmore = () => {
    if (!this.state.doctorId) {
      message.error('暂无信息')
    } else {
      getListByName([
        this.state.name,
        this.state.unitName,
      ]).then(res => {
        if (res.data.data && res.data.message === "success") {
          if (res.data.data.length > 0) {
            this.setState({
              persondetailvisible: true,
              personsdetail: false,
              otherInformation: res.data.data[0].otherInformation.replace(/\s+/g, "").replace(/"'"/g, "").split('##'),
              professionInformation: res.data.data[0].professionInformation.replace(/\s+/g, "").replace(/"'"/g, "").split('##'),
            }
            )
          } else {
            message.error('暂无信息')
          }

        } else {
          message.error('暂无信息')
        }
      });
    }
  }

  render () {
    const sensoroptions = this.state.sensorlist.map((province) => <Option key={province.deviceId}  >{province.location}</Option>);
    const options = this.state.allpersonlist.map(d => <Option key={d.doctorId} name={d.role} realname={d.doctorName}>
      <div className="personstyle">
        <span>
          {d.doctorName}
        </span>
        <span>
          -{d.institutionName}
        </span>
      </div>
    </Option>);

    const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const listion = <img src={require('../images/close.png')} alt="" />

    const components = {
      // body: {
      //     row: EditableFormRow,
      //     cell: EditableCell,
      // },
    };

    // const onFinish = (values) => {
    //     //console.log(values);
    // };




    return (
      <Layout id="alarm" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="单位管理" headStyle={{ color: '#2a2a2a', fontSize: '18px' }}
              extra={
                <div>
                  <Button type="primary" onClick={this.addunit} style={{ display: this.state.typedis }}>
                    {this.state.addName}
                  </Button>
                </div>}
            >
              <div>
                <Search placeholder="搜索单位名称"
                  onSearch={this.onsearch}
                  onChange={this.searchname}
                  value={this.state.searchname}
                  enterButton style={{ marginBottom: '20px', width: '300px' }}
                />
                <Table
                  dataSource={this.state.sitelist}
                  columns={nodeInfoTableColumns}
                  pagination={this.state.page}
                  components={components}
                  rowKey={record => record.id}
                />
              </div>
            </Card>
          </Content>
          <Modal
            title="删除单位"
            open={this.state.deleteunitvisible}
            onOk={this.deleteunit}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该单位吗？
          </Modal>
          <Modal
            title="删除人员"
            open={this.state.deletepersonvisible}
            onOk={this.deleteperson}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancels}
            closeIcon={listion}
          >
            您确定要删除该人员吗？
          </Modal>
          <Modal
            title="添加单位"
            open={this.state.unitvisible}
            // onOk={this.addOk}
            width="900px"
            // okText="确认"
            footer={null}
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <div className="clearfix" style={{ display: 'inline-block' }}>
              <div className="mapcontainer">
                <div id="mapContainer">
                </div>
              </div>
            </div>
            <div style={{ display: 'inline-block', float: 'right', width: '300px' }}>
              <Form
                // onFinish={onFinish}
                ref={this.formRef}
                onFinish={this.addOk}
                layout="vertical"
              >
                {/* <Form.Item
                                    name="unitname"
                                    label="单位名称"

                                    rules={[
                                        {
                                            // required: true,
                                        },
                                    ]}
                                >
                           
                                </Form.Item> */}
                <span>单位名称：</span>
                <Input placeholder="请输入单位名称"
                  style={{ width: '100%' }}
                  id="facilityLocation"
                  autoComplete="off"
                  onChange={this.unitname}
                  value={this.state.unitname}
                />
                {/* <Form.Item
                                    name="address"
                                    label="详细地址"
                                    rules={[
                                        {
                                            // required: true,
                                            // validator: validator.username
                                        },
                                    ]}
                                >
                        
                                </Form.Item> */}
                <span>详细地址：</span>
                <Input placeholder="请输入详细地址"
                  style={{ width: '100%' }}
                  autoComplete="off"
                  onChange={this.address}
                  value={this.state.address}
                />
                <span>所属区域：</span>
                <Cascader
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={this.state.deviceList}
                  onChange={this.findArea}
                  value={this.state.addresslists}
                  changeOnSelect
                  style={{ width: '100%', marginBottom: "7px", marginTop: '7px' }}
                  placeholder="请选择所属区域" />
                <span>负责人：</span>
                <Input placeholder="请输入负责人"
                  style={{ width: '100%', marginBottom: "7px", marginTop: '7px' }}
                  autoComplete="off"
                  onChange={this.username}
                  value={this.state.username}
                />
                <Form.Item
                  name="phone"
                  label="联系电话"
                  rules={[
                    {
                      // required: true,
                      validator: validator.phone
                    },
                  ]}
                >
                  <Input placeholder="请输入联系电话"
                    style={{ width: '100%' }}
                    autoComplete="off"
                    onChange={this.phone}
                    value={this.state.phone}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit" loading={this.state.addLoading}>
                    确认
                  </Button>
                </Form.Item>
              </Form>
              {/* <span>单位名称：</span>
                            <Input placeholder="请输入单位名称"
                                id="facilityLocation"
                                style={{ width: '100%', marginBottom: "7px", marginTop: '7px' }}
                                autoComplete="off"
                                onChange={this.unitname}
                                value={this.state.unitname}
                            />
                            <span>详细地址：</span>
                            <Input placeholder="请输入详细地址"
                                style={{ width: '100%', marginBottom: "7px", marginTop: '7px' }}
                                autoComplete="off"
                                onChange={this.address}
                                value={this.state.address}
                            />
                            <span>所属区域：</span>
                            <Cascader
                                fieldNames={{ label: 'name', value: 'id' }}
                                options={this.state.deviceList}
                                onChange={this.findArea}
                                value={this.state.addresslists}
                                changeOnSelect
                                style={{ width: '100%', marginBottom: "7px", marginTop: '7px' }}
                                placeholder="请选择所属区域" />
                            <span>负责人：</span>
                            <Input placeholder="请输入负责人"
                                style={{ width: '100%', marginBottom: "7px", marginTop: '7px' }}
                                autoComplete="off"
                                onChange={this.username}
                                value={this.state.username}
                            />
                            <span>联系电话：</span>
                   */}
            </div>
          </Modal>
          <Modal
            title="负责人信息"
            open={this.state.peoplevisible}
            // onOk={this.deleteunit}
            footer={null}
            width="300px"
            // okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <div>
              <div className="personinfo">
                <span>负责人姓名：</span><span>{this.state.userName}</span>
              </div>
              <div className="personinfo">
                <span>联系电话：</span><span>{this.state.phone}</span>
              </div>
            </div>
          </Modal>

          <Drawer
            title={this.state.personunitname + '人员列表'}
            width={800}
            onClose={this.handleCancelss}
            open={this.state.personvisible}
            className="chouti"
            bodyStyle={{ paddingBottom: 80 }}
          >
            <div className="modeltable">
              <div style={{ textAlign: "right", marginBottom: '10px' }}>
                <Button type="primary" onClick={this.openunbind} style={{ marginRight: "15px", display: this.state.adddis }}>
                  未匹配卡号记录
                </Button>
                <Button type="primary" onClick={this.addperson} style={{ display: this.state.adddis }}>
                  添加人员
                </Button>
              </div>
              <Table
                dataSource={this.state.personlist}
                columns={this.personColumns}
                // pagination={false}
                bordered
                rowKey={record => record.id}
              />
            </div>
          </Drawer>

          <Modal
            title="未匹配记录列表"
            open={this.state.unbindpeoplevisible}
            // onOk={this.deleteunit}
            footer={null}
            width="500px"
            // okText="删除"
            centered
            onCancel={this.handleCancels}
            closeIcon={listion}
          >
            <div>
              <Table
                dataSource={this.state.unbindpersonlist}
                columns={this.state.unbindColumns}
                pagination={false}
                bordered
              />
            </div>
          </Modal>

          <Modal
            title="更多详情"
            open={this.state.persondetailvisible}
            footer={null}
            width="500px"
            centered
            onCancel={this.personCancel}
            closeIcon={listion}
          >
            <div>
              <div className="detailheader">
                <span className="detailtitle"></span>
                <span>个人基本信息</span>
                <span className="detailtitle"></span>
              </div>
              {/* <div>
                                姓名： {infolist.姓名}
                            </div> */}
              {this.state.otherInformation.map((item) => {
                if (item) {
                  if (item.split("$$$@")[1].indexOf("T") !== -1) {
                    return (
                      <div className="infolist">
                        <span>{item.split("$$$@")[0]}</span>
                        <span>{item.split("$$$@")[1].substring(0, 10)}</span>
                      </div>
                    )
                  } else {
                    return (
                      <div className="infolist">
                        <span>{item.split("$$$@")[0]}</span>
                        <span>{item.split("$$$@")[1]}</span>
                      </div>
                    )
                  }
                } else {
                  return null
                }
              })}
              <div className="detailheader">
                <span className="detailtitle"></span>
                <span>执业信息 </span>
                <span className="detailtitle"></span>
              </div>
              {this.state.professionInformation.map((item) => {
                if (item) {
                  if (item.split("$$$@")[1].indexOf("T") !== -1) {
                    return (
                      <div className="infolist">
                        <span>{item.split("$$$@")[0]}</span>
                        <span>{item.split("$$$@")[1].substring(0, 10)}</span>
                      </div>
                    )
                  } else {
                    return (
                      <div className="infolist">
                        <span>{item.split("$$$@")[0]}</span>
                        <span>{item.split("$$$@")[1]}</span>
                      </div>
                    )
                  }
                } else {
                  return null
                }

              })}
            </div>
          </Modal>
          <Modal
            title="人员详情"
            open={this.state.personsdetail}
            footer={null}
            width="500px"
            centered
            onCancel={this.personCancel}
            closeIcon={listion}
          >
            <div>
              <div className="detailheader">
                <span className="detailtitle"></span>
                <span>个人基本信息</span>
                <span className="detailtitle"></span>
              </div>
              <div className="infolist">
                <span>姓名</span>
                <span>{this.state.doctorName}</span>
              </div>
              <div className="infolist">
                <span>职级</span>
                <span>{this.state.role}</span>
              </div>
              <div className="infolist">
                <span>医生执照</span>
                <span>{this.state.doctorLicence}</span>
              </div>
              <div className="infolist">
                <span>所属单位</span>
                <span>{this.state.institutionName}</span>
              </div>
              <div className="infolist">
                <span>更多详情</span>
                <span className="lookmore" onClick={this.lookmore}>查看>></span>
              </div>
            </div>
          </Modal>
          <Modal
            title="添加人员"
            open={this.state.addvisible}
            onOk={this.addpersonOk}
            width="400px"
            okText="确认"
            centered
            onCancel={this.handleCancels}
            closeIcon={listion}
            destroyOnClose
          >
            <span style={{ display: 'block' }}>姓名：<span style={{ color: 'red' }}>（不能超过30位字符）</span></span>
            {/* <Input placeholder="请输入姓名置"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.name}
                            value={this.state.name}
                        /> */}

            <AutoComplete
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              onSearch={this.namechange}
              onChange={this.namechanges}
              value={this.state.realname}
              placeholder="请输入姓名">
              {options}
            </AutoComplete>
            {/* <Select
                            showSearch
                            placeholder="请输入姓名"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.namechange}
                            onChange={this.namechanges}
                            // value={this.state.realname}
                            notFoundContent={null}
                        >
                            {options}
                        </Select> */}
            <span style={{ display: 'block' }}>人脸识别号： <span style={{ color: 'red' }}>（请输入纯数字卡号）</span> </span>
            <Input placeholder="请输入人脸识别号"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.cardNo}
              value={this.state.cardNo}
            />
            <span style={{ display: 'block' }}>卡号：</span>
            <Input placeholder="请输入卡号"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.inputNo}
              value={this.state.rfidTagNo}
            />
            <span style={{ display: 'block' }}>职级：</span>
            {/* <Input placeholder="请输入职级"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.position}
                            value={this.state.position}
                        /> */}
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择职级"
              onChange={this.position}
              value={this.state.position}
            >
              <Option key="执业医师"  >执业医师</Option>
              <Option key="执业医师(师承)"  >执业医师(师承)</Option>
              <Option key="执业助理医师"  >执业助理医师</Option>
              <Option key="执业助理医师(师承)"  >执业助理医师(师承)</Option>
              <Option key="医师"  >医师</Option>
              <Option key="主治医师"  >主治医师</Option>
              <Option key="主任医师"  >主任医师</Option>
              <Option key="主管医师"  >主管医师</Option>
              <Option key="医士"  >医士</Option>
              <Option key="副主任医师"  >副主任医师</Option>
              <Option key="护师"  >护师</Option>
              <Option key="护士"  >护士</Option>
              <Option key="主管护师"  >主管护师</Option>
              <Option key="副主任护师"  >副主任护师</Option>
              <Option key="主任护师"  >主任护师</Option>
              <Option key="未评定"  >未评定</Option>
            </Select>
            <span>所属设备：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择所属设备"
              onChange={this.deviceId}
              value={this.state.deviceId}
            >
              {sensoroptions}
            </Select>
          </Modal>

          <Modal
            title="修改人员信息"
            open={this.state.changevisible}
            onOk={this.changepersonOk}
            width="400px"
            okText="确认"
            centered
            onCancel={this.handleCancels}
            closeIcon={listion}
            destroyOnClose
          >
            <span style={{ display: 'block' }}>姓名：<span style={{ color: 'red' }}>（不能超过30位字符）</span></span>
            {/* <Input placeholder="请输入姓名置"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.namechange}
                            value={this.state.name}
                        /> */}
            <AutoComplete
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              onSearch={this.namechange}
              onChange={this.namechanges}
              value={this.state.realname}
              placeholder="请输入姓名">
              {options}
            </AutoComplete>
            {/* <Select
                            showSearch
                            placeholder="请输入姓名"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            showArrow={false}
                            value={this.state.realname}
                            filterOption={false}
                            onSearch={this.namechange}
                            onChange={this.namechanges}
                            notFoundContent={null}
                        >
                            {options}
                        </Select> */}
            <span style={{ display: 'block' }}>人脸识别号： <span style={{ color: 'red' }}>（请输入纯数字卡号）</span> </span>
            <Input placeholder="请输入人脸识别号"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.cardNo}
              value={this.state.cardNo}
            />
            <span style={{ display: 'block' }}>卡号：</span>
            <Input placeholder="请输入卡号"
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              autoComplete="off"
              onChange={this.inputNo}
              value={this.state.rfidTagNo}
            />
            <span style={{ display: 'block' }}>职级：</span>
            {/* <Input placeholder="请输入职级"
                            style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
                            autoComplete="off"
                            onChange={this.position}
                            value={this.state.position}
                        /> */}
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择职级"
              onChange={this.position}
              value={this.state.position}
            >
              <Option key="执业医师"  >执业医师</Option>
              <Option key="执业医师(师承)"  >执业医师(师承)</Option>
              <Option key="执业助理医师"  >执业助理医师</Option>
              <Option key="执业助理医师(师承)"  >执业助理医师(师承)</Option>
              <Option key="医师"  >医师</Option>
              <Option key="主治医师"  >主治医师</Option>
              <Option key="主任医师"  >主任医师</Option>
              <Option key="主管医师"  >主管医师</Option>
              <Option key="医士"  >医士</Option>
              <Option key="副主任医师"  >副主任医师</Option>
              <Option key="护师"  >护师</Option>
              <Option key="护士"  >护士</Option>
              <Option key="主管护师"  >主管护师</Option>
              <Option key="副主任护师"  >副主任护师</Option>
              <Option key="主任护师"  >主任护师</Option>
              <Option key="未评定"  >未评定</Option>
            </Select>
            <span>所属设备：</span>
            <Select
              style={{ width: '100%', marginBottom: "10px", marginTop: '10px' }}
              placeholder="请选择所属设备"
              onChange={this.deviceId}
              value={this.state.deviceId}
            >
              {sensoroptions}
            </Select>
          </Modal>
        </Layout>
      </Layout >
    );
  }
}

export default App;
