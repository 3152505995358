const validator = {
  // 用户名验证
  username (rules, value, callback) {
    var reg = new RegExp(/^[\u4e00-\u9fa5_a-zA-Z0-9]{1,30}$/)
    if (!value) {
      return callback(new Error('用户名不能为空'));
    } else {
      if ((value && !reg.test(value))) {
        callback(new Error('用户名不能包含空格和特殊字符，且在1到30个字符之间'))
      } else {
        callback()
      }
    }
  },
  // 密码验证
  password (rules, value, callback) {
    var reg = new RegExp(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{9,}/, 'g')
    let regs = new RegExp(/\s/g)
    if (!value) {
      return callback(new Error('密码不能为空'));
    } else {
      if ((value && !reg.test(value)) || regs.test(value)) {
        callback(new Error('请输入9位以上的大小写字母、数字，以及特殊字符混合表达式，且不能输入空格'))
      } else {
        callback()
      }
    }
  },
  // 输入框字数验证
  inputValidator (rule, value, callback) {
    // //console.log(value, Number)
    const len = value.replace(/[^\x00-\xff]/g, 'aa').length // eslint-disable-line
    if (len > 200) {
      callback(new Error(`最多可填写200个字符`))
    } else {
      callback()
    }
  },
  // 邮箱验证
  email (rule, value, callback) {
    var reg = new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]{2,6})+$/)// eslint-disable-line
    if (!value) {
      return callback(new Error('邮箱不能为空！'));
    } else {
      if ((value && !reg.test(value))) {
        callback(new Error('请输入正确邮箱！'))
      } else {
        callback()
      }
    }
  },
  // 手机号验证
  phone (rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入手机号码'));
    } else {
      if (value && !(/^[1][3-9][0-9]{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码'))
        return false
      } else {
        callback()
      }

    }
  },
  // 身份证号校验
  validateIdCard (rule, idCard, callback) {
    var regIdCard = (/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/)
    if (!idCard) {
      return callback(new Error('请输入身份证号码'));
    }
    if ((idCard && !regIdCard.test(idCard))) {
      callback(new Error('请输入正确身份证号码！'))
    } else {
      callback()
    }
  },
  // 行政区code验证
  areaCode (rule, value, callback) {
    var reg = new RegExp(/^[1-8][0-7]\d{4}$/)
    if (!value) {
      return callback(new Error('请输入行政区号'));
    }
    if ((value && !reg.test(value))) {
      callback(new Error('请输入正确行政区号！'))
    } else {
      callback()
    }
  },
  // 出生日期验证
  Birthdate (rule, value, callback) {
    var reg = new RegExp(/^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/)
    if (!value) {
      return callback(new Error('请输入出生日期'));
    }
    if ((value && reg.test(value))) {
      var year = value.substring(0, 4);
      var month = value.substring(4, 6);
      var date = value.substring(6, 8);
      var date2 = new Date(year + "-" + month + "-" + date);
      if (date2 && date2.getMonth() === (parseInt(month) - 1)) {
        callback()
      } else {
        callback(new Error('请输入正确出生日期 例19900101'))
      }
    } else {
      callback(new Error('请输入正确出生日期 例19900101'))
    }
  },
}

export default validator
