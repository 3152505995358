import React from "react";
import {
  Input, Table, Select, Modal, Tooltip, DatePicker, Tabs, Form, Popconfirm, message
} from "antd";
import moment from 'moment'
import {
  getSocketList, addSocket, addlogoperate, deteleSocket, getLineList, getMergeList, getOriginalList
} from '../axios';
const { Search } = Input;
// const { Option } = Select;
const { RangePicker } = DatePicker;
class Socket extends React.Component {
  detailForm = React.createRef()
  constructor(props) {
    super(props);
    // //console.log('props :>> ', props);
    this.state = {
      // tab切换
      tabActive: '1',
      unitId: null,
      statusVisible: false,
      recordVisible: false,
      socketVisible: false,
      editVisible: false,
      socketList: [],
      lineList: [],
      mergeList: [],
      initList: [],
      linePage: 0,
      mergePage: 0,
      initPage: 0,
      editItem: {},
      socketColumns: [
        {
          title: "所属单位",
          dataIndex: "unitName",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.mac}>
                  {text}
                </Tooltip>
              </div >
            )
          }
        }, {
          title: "所属市",
          dataIndex: "city",
        },
        {
          title: "所属区",
          dataIndex: "area",
        },
        {
          title: "设备位置",
          dataIndex: "position",
        },
        {
          title: "设备状态",
          dataIndex: "onlineStatus",
          filters: [
            { text: "在线", value: true },
            { text: "离线", value: false },
          ],
          onFilter: (value, record) => record.onlineStatus === value,  //eslint-disable-line 
          render: (text, record, index) => {
            return (
              record.onlineStatus
                ?
                <div style={{ color: '#1eb333', cursor: 'pointer' }} onClick={() => this.checkState(text, record, index)}>
                  在线
                </div>
                :
                <div style={{ color: '#f55238', cursor: 'pointer' }} onClick={() => this.checkState(text, record, index)}>
                  离线
                </div>
            )
          }
        },
        {
          title: "信号强度",
          dataIndex: "sig",
        },
        {
          title: "工作记录",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.checkDetail(text, record, index)}
              >
                详情
              </div>
            )
          }
        },
        {
          title: "添加时间",
          dataIndex: "gmtcreate"
        },
        {
          title: "操作",
          dataIndex: "action",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.socketEdit(text, record, index)}
                  style={{ marginRight: '10px' }}
                >
                  <img src={require('../images/edit.png')} alt="" />
                </span>
                <Popconfirm
                  title="您确定要删除该设备吗？"
                  onConfirm={() => this.socketDelete(text, record, index)}
                  okText="确定"
                  cancelText="取消"
                  placement="topRight"
                >
                  <img src={require('../images/delete.png')} alt="" />
                </Popconfirm>
              </div >
            );
          }
        }
      ],
      lineColumns: [
        {
          title: "设备状态",
          dataIndex: "status",
          render: (text, record, index) => {
            return (
              record.status === true
                ?
                <div style={{ color: '#1eb333' }}>
                  上线
                </div>
                :
                record.status === false
                  ?
                  <div style={{ color: '#f55238' }}>
                    下线
                  </div>
                  :
                  <div style={{ color: '#999999' }}>
                    未知
                  </div>
            )
          }
        },
        {
          title: "上报时间",
          dataIndex: "date"
        }
      ],
      mergeColumns: [
        {
          title: "开始时间",
          dataIndex: "begin"
        },
        {
          title: "结束时间",
          dataIndex: "end"
        },
        {
          title: "消毒时长(分)",
          dataIndex: "status",
          render: (text, record, index) => {
            return (
              <span>
                {moment(record.end).diff(moment(record.begin), 'minute')}
              </span>
            )
          }
        }
      ],
      initColumns: [
        {
          title: "MAC",
          dataIndex: "mac"
        },
        {
          title: "功率（W）",
          dataIndex: "power"
        },
        {
          title: "时间",
          dataIndex: "gmtcreate"
        },
        {
          title: "状态",
          dataIndex: "status",
          render: (text, record, index) => {
            return (
              <span>
                {record.loadStatus === true ? '开启' : record.loadStatus === false ? '关闭' : '未知'}
              </span>
            )
          }
        }
      ],
      boardoptions: [
        {
          value: "21",
          label: "灵源插座"
        },
        {
          value: "22",
          label: "钛比插座"
        }
      ],
      // 列表参数
      cityId: '',
      districtId: '',
      // unitId: '',
      imei: '',
      socketPage: 0,
      listPageNum: 1,
      linePageNum: 1,
      initPageNum: 1,
      onlineStatus: null,
      rangeTime: [moment().subtract(1, 'week'), moment()]
    }
  }
  componentDidMount () {
    this.props.onRef && this.props.onRef(this);
    this.init()
  }
  // 插座列表
  init = () => {
    const { listPageNum, cityId, districtId, unitId, imei, onlineStatus } = this.state
    // console.log('onlineStatus :>> ', onlineStatus);
    const params = {
      pageNum: listPageNum,
      pageSize: 10,
      cityId,
      districtId,
      unitId,
      imei,
      onlineStatus
    }
    getSocketList(params).then(res => {
      // console.log('res :>> ', res);
      if (res.data.message === 'success') {
        this.setState({
          socketList: res.data.data.boardList,
          socketPage: res.data.data.total
        })
      }
    })
  }
  // 筛选表格
  tablechange = (a, b, c, d) => {
    // console.log('a,b,c,d :>> ', a, b, c, d);
    this.setState({
      onlineStatus: b.onlineStatus ? b.onlineStatus[0] : null,
      listPageNum: 1
    }, () => {
      this.init()
    })
  }
  // 单位筛选
  socketChangeUint = (value) => {
    // //console.log('value :>> ', value);
    this.setState({
      unitId: value,
      listPageNum: 1
    }, () => {
      this.init()
    })
  }
  //输入关键字
  changeKey = (e) => {
    this.setState({
      imei: e.target.value
    })
  }
  socketQuery = () => {
    this.setState({
      listPageNum: 1
    }, () => {
      this.init()
    })
  }
  // 添加设备
  openSocket = () => {
    this.setState({
      socketVisible: true
    })
  }
  // 修改
  socketEdit = (text, record, index) => {
    this.setState({
      editVisible: true,
      editItem: record,
      position: record.position
    })
  }
  editPosition = (e) => {
    // console.log('e :>> ', e);
    // const { position } = this.state
    // position = 
    this.setState({
      position: e.target.value
    })
  }
  editSumbit = () => {
    //console.log('111 :>> ', 111);
    const { position, editItem } = this.state
    const params = {
      id: editItem.id,
      imei: editItem.imei,
      mac: editItem.imei,
      position,
      type: 4,
      unitId: editItem.unitId
    }
    // values.mac = values.imei
    // values.type = 4
    addSocket(params).then(res => {
      // console.log('res :>> ', res);
      if (res.data && res.data.message === "success") {
        message.success('编辑设备成功')
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname")}修改了${editItem.unitName}单位中插座位置，(其中设备imei号:${editItem.imei}
                   ，设备位置为 ${editItem.position})。`,
          5,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "设备管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          }
        });
        // this.cardCancel()
        this.setState({
          editVisible: false
        })
        this.init()

      } else {
        message.error(res.data.data)
      }
    })
  }
  // 删除
  socketDelete = (text, record, index) => {
    //console.log('111 :>> ', 111);
    deteleSocket(record.id).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除设备成功')
        addlogoperate([
          localStorage.getItem("realname"),
          `${localStorage.getItem("realname") + "删除了" + record.unitName + "下面插座imi号为" + record.imei + "的设备"} `,
          4,
          3,
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          "设备管理"
        ]).then(res => {
          if (res.data && res.data.status === 200) {

          }
        })
        this.init()
      } else {
        message.error(res.data.message)
      }
    });
  }
  // 
  socketCancel = () => {
    this.setState({
      socketVisible: false
    })
  }
  // 确认添加
  socketSumbit = () => {
    // this.setState({
    //   socketVisible: false
    // })

    this.detailForm.current
      .validateFields()
      .then(values => {
        // console.log('values :>> ', values);
        // form.resetFields()
        // const params = {
        //   type: 3,
        //   location: values.location,
        //   unitId: values.unitId,
        //   deviceId: values.deviceId,
        //   scenario: values.scenario
        // }
        const { unitlist } = this.props
        const unitname = unitlist.filter(el => el.id === values.unitId)[0].name
        values.mac = values.imei
        values.type = 4
        addSocket(values).then(res => {
          // console.log('res :>> ', res);
          if (res.data && res.data.message === "success") {
            message.success('添加设备成功')
            addlogoperate([
              localStorage.getItem("realname"),
              `${localStorage.getItem("realname")}在${unitname}单位中添加了一个插座，(其中设备imei号:${values.imei}
                   ，设备位置为 ${values.position})。`,
              3,
              3,
              moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              "设备管理"
            ]).then(res => {
              if (res.data && res.data.status === 200) {

              }
            });
            this.cardCancel()
            this.init()

          } else {
            message.error(res.data.data)
          }
        })
      })
      .catch(info => {
        //console.log('Validate Failed:', info);
      });
  }
  cardCancel = () => {
    this.detailForm.current.resetFields()
    this.setState({
      socketVisible: false
    })
  }
  // 切换分页
  // 切换列表分页
  changePage = (value) => {
    this.setState({
      listPageNum: value
    }, () => {
      this.init()
    })
  }
  changeLinePage = (value) => {
    this.setState({
      linePageNum: value.current
    }, () => {
      this.onlineRecord()
    })
  }
  changeInitPage = (value) => {
    this.setState({
      initPageNum: value.current
    }, () => {
      this.original()
    })
  }
  socketLocationChange = (e) => {
    this.setState({
      socketLocation: e.target.value
    })
  }
  socketIDChange = (e) => {
    this.setState({
      socketId: e.target.value
    })
  }

  // 查看工作记录
  checkDetail = (text, record, index) => {
    //console.log('text, record, index :>> ', text, record, index);
    this.setState({
      detailItem: record,
    }, async () => {
      await this.merge()
      await this.original()
      this.setState({
        recordVisible: true
      })
    })
  }
  merge = () => {
    const { detailItem, rangeTime } = this.state
    const params = {
      pageNum: 1,
      pageSize: 1000,
      start: `${rangeTime[0].format('YYYY-MM-DD')} 00:00:00`,
      stop: `${rangeTime[1].format('YYYY-MM-DD')} 23:59:59`,
      mac: detailItem.imei
    }
    getMergeList(params).then(res => {
      let arr = [];
      let newarr = [];

      if (res.data.message === 'success') {
        const { data } = res.data
        // eslint-disable-next-line
        data.map((el, index) => {
          if (index === 0) {
            if (el.loadStatus) {
              if (moment(new Date(el.gmtcreate)).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
                arr.push("使用中")
              } else {
                arr.push("")
              }
              newarr.push(el.gmtcreate);
            } else {
              arr.push(el.gmtcreate)
            }
          } else {
            if (el.loadStatus !== data[index - 1].loadStatus) {
              if (!el.gmtcreate) {
                if (data[index - 1].loadStatus) {
                  arr.push("")
                } else {
                  newarr.push("")
                }
              } else {
                if (el.loadStatus) {
                  newarr.push(el.gmtcreate)
                } else {
                  arr.push(el.gmtcreate)
                }
              }
            } else {
              if (el.loadStatus) {
                newarr.push(el.gmtcreate);
                arr.push("");
              } else {
                newarr.push("");
                arr.push(el.gmtcreate);
              }
            }
          }
        })
        let arrlist = arr.map((el, index) => {
          return {
            begin: newarr[index],
            end: el
          }
        })
        // arrlist.map(el => {
        //   if (el.begin&&el.end&&el.end!=="使用中") {
        //     el.totaltime = 
        //   }
        // })
        // for (var z in arrlist) {
        //   if (
        //     arrlist[z].begin &&
        //     arrlist[z].end &&
        //     arrlist[z].end !== "使用中"
        //   ) {
        //     arrlist[z].totaltime = (
        //       (new Date(arrlist[z].end.replace(/-/g, "/")).getTime() -
        //         new Date(arrlist[z].begin.replace(/-/g, "/")).getTime()) /
        //       60000
        //     ).toFixed(1);
        //   }
        // }
        // //console.log(arrlist);
        // this.readout = arrlist;
        // this.readoutlength = arrlist.length;
        this.setState({
          mergeList: arrlist,
          mergePage: arrlist.length,
        })
      }
    })
  }
  original = () => {
    const { detailItem, initPageNum, rangeTime } = this.state
    const params = {
      pageNum: initPageNum,
      pageSize: 10,
      start: `${rangeTime[0].format('YYYY-MM-DD')} 00:00:00`,
      stop: `${rangeTime[1].format('YYYY-MM-DD')} 23:59:59`,
      mac: detailItem.imei
    }
    getOriginalList(params).then(res => {
      if (res.data.message === 'success') {
        this.setState({
          initList: res.data.data.boardReadings,
          initPage: res.data.data.total,
        })
      }
    })
  }

  // 查看在线记录
  checkState = (text, record, index) => {
    //console.log('text, record, index :>> ', text, record, index)
    this.setState({
      onlineItem: record
    }, async () => {
      await this.onlineRecord()
      this.setState({
        statusVisible: true
      })
    })
  }
  onlineRecord = () => {
    const { linePageNum, onlineItem } = this.state
    const params = {
      pageNum: linePageNum,
      pageSize: 10,
      mac: onlineItem.imei,
      start: `${moment().subtract(1, 'month').format('YYYY-MM-DD')} 00:00:00`,
      stop: `${moment().format('YYYY-MM-DD')} 23:59:59`
    }
    getLineList(params).then(res => {
      if (res.data.message === 'success') {
        this.setState({
          lineList: res.data.data.boardOnlines,
          linePage: res.data.data.total
        })
      }
    })
  }
  // 记录时间切换
  recordTime = (date) => {
    // console.log('date,dateSting :>> ', date, dateSting);
    this.setState({
      rangeTime: date
    }, () => {
      this.original()
      this.merge()
    })
  }
  // tab切换
  // tabs切换事件
  tabChange = (activeKey) => {
    this.setState({
      tabActive: activeKey
    })
  }
  render () {
    // //console.log('this.props.unitlist :>> ', this.props.unitlist);
    const unitlist = this.props.unitlist.map(el => {
      return {
        value: el.id,
        label: el.name
      }
    })
    const listion = <img src={require('../images/close.png')} alt="" />

    // tabs各项内容定义
    const items = [
      {
        label: '合并数据',
        key: '1',
        children: <Table
          dataSource={this.state.mergeList}
          columns={this.state.mergeColumns}
          pagination={this.state.mergePage > 10 ? true : false}
          rowKey={(record, index) => index}
        />
      },
      {
        label: '原始数据',
        key: '2',
        children: <Table
          dataSource={this.state.initList}
          columns={this.state.initColumns}
          // pagination={this.state.initPage > 10 ? true : false}
          onChange={this.changeInitPage}
          pagination={{ total: this.state.initPage }}
          rowKey={record => record.id}
        />
      }
    ]
    // const [form] = Form.useForm()
    return (
      <>
        <div className="contentmain">
          <span>所属单位：</span>
          <Select
            style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
            placeholder="请选择所属单位"
            onChange={this.socketChangeUint}
            value={this.state.unitId}
            allowClear
            options={unitlist}
          />
          <Search placeholder="请输入设备编号"
            onSearch={this.socketQuery}
            onChange={this.changeKey}
            value={this.state.imei}
            enterButton
            style={{ marginBottom: '20px', width: '300px' }}
          />
          <div>
            <Table
              dataSource={this.state.socketList}
              columns={this.state.socketColumns}
              onChange={this.tablechange}
              // pagination={this.state.socketPage > 10 ? true : false}
              pagination={{ total: this.state.socketPage, onChange: this.changePage }}
              rowKey={record => record.id}
            />
          </div>
        </div>
        <Modal title="最近一个月上下线记录" open={this.state.statusVisible}
          footer={null}
          width="400px"
          onCancel={() => { this.setState({ statusVisible: false }) }}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}>
          <Table
            dataSource={this.state.lineList}
            columns={this.state.lineColumns}
            // pagination={this.state.linePage > 10 ? true : false}
            onChange={this.changeLinePage}
            pagination={{ total: this.state.linePage }}
            rowKey={record => record.id}
          />
        </Modal>
        <Modal title="工作记录" open={this.state.recordVisible}
          footer={null}
          width="600px"
          onCancel={() => { this.setState({ recordVisible: false }) }}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}>
          <RangePicker onChange={this.recordTime} value={this.state.rangeTime} allowClear={false} />
          <Tabs defaultActiveKey="1" onChange={this.tabChange} activeKey={this.state.tabActive} items={items}></Tabs>
        </Modal>
        <Modal
          title="添加设备"
          open={this.state.socketVisible}
          onOk={this.socketSumbit}
          width="400px"
          okText="确认"
          onCancel={this.socketCancel}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}
        >
          <Form
            ref={this.detailForm}
            layout='vertical'
            name="register"
            scrollToFirstError
          >
            <Form.Item
              name="imei"
              label="设备imei号："
              rules={[
                {
                  required: true,
                  message: '请输入设备imei号！',
                },
              ]}
            >
              <Input placeholder="请输入设备imei号" />
            </Form.Item>
            {/* <Form.Item
              name="type"
              label="设备类型"
              rules={[
                {
                  required: true,
                  message: '请选择设备类型!',
                },
              ]}
            >
              <Select placeholder="请选择设备类型" options={this.state.boardoptions} />
            </Form.Item> */}
            <Form.Item
              name="devicetype"
              label="设备类型："
            >
              <Input
                autoComplete="off"
                disabled
                placeholder='钛比插座'
              />
            </Form.Item>
            <Form.Item
              name="unitId"
              label="所属单位"
              rules={[
                {
                  required: true,
                  message: '请选择所属单位!',
                },
              ]}
            >
              <Select placeholder="请选择所属单位" options={unitlist} />
            </Form.Item>
            <Form.Item
              name="position"
              label="安装位置："
              rules={[
                {
                  required: true,
                  message: '请输入安装位置！',
                },
              ]}
            >
              <Input placeholder="请输入安装位置" />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="编辑设备"
          open={this.state.editVisible}
          onOk={this.editSumbit}
          width="400px"
          okText="确认"
          onCancel={() => { this.setState({ editVisible: false, editItem: {} }) }}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}
        >
          <span>安装位置：</span>
          <Input placeholder="请输入安装位置" onChange={this.editPosition} value={this.state.position} style={{ marginTop: '10px' }} />
        </Modal>
      </>
    )
  }
}

export default Socket;
