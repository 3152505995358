import React from "react";
import {
  Table, Select, Modal, Tooltip, DatePicker, Descriptions, message
} from "antd";
// import moment from 'moment'
import {
  getStayList, getStayDetail, getStayMerge
} from '../axios';
const { RangePicker } = DatePicker;
class Socket extends React.Component {
  constructor(props) {
    super(props);
    // //console.log('props :>> ', props);
    this.state = {
      detailsVisible: false,
      staySiteId: '',
      stayList: [],
      detailsList: [],
      totalList: [],
      stayPage: 0,
      detailsPage: 0,
      stayColumns: [
        {
          title: "单位",
          dataIndex: "unitName",
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.rfidTagNo}>
                  {text}
                </Tooltip>
              </div >
            )
          }
        },
        {
          title: "日期",
          dataIndex: "date",
        },
        {
          title: "位置",
          dataIndex: "location",
        },
        {
          title: "人员",
          dataIndex: "person",
        },
        {
          title: '职位',
          dataIndex: 'position'
        },
        {
          title: "本日时长（分）",
          dataIndex: "duration",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.checkDetail(text, record, index)}
              >
                {text ? (text / 60).toFixed(2) : 0}
              </div>
            )
          }
        }
      ],
      detailsColumns: [
        {
          title: "开始时间",
          dataIndex: "startTime"
        },
        {
          title: "结束时间",
          dataIndex: "endTime"
        },
        {
          title: "时长（分）",
          dataIndex: "duration",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7' }}
              >
                {text ? (text / 60).toFixed(2) : 0}
              </div>
            )
          }
        },
        {
          title: (
            < div style={{ cursor: 'pointer' }} >

              <Tooltip placement="topLeft" title='读卡器标签信号强度（RSSI）的方差'>
                信号变化
              </Tooltip>
            </ div>
          ),
          dataIndex: "rssiVariance"
        }
      ],
      detailsTotalColumns: [
        {
          title: "开始时间",
          dataIndex: "startTime"
        },
        {
          title: "结束时间",
          dataIndex: "endTime"
        },
        {
          title: "时长（分）",
          dataIndex: "duration",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7' }}
              >
                {text ? (text / 60).toFixed(2) : 0}
              </div>
            )
          }
        }
      ],
      pageNum: 1,
      pageSize: 10,
      depageNum: 1,
      depageSize: 8
    }
  }
  componentDidMount () {
    this.props.onRef && this.props.onRef(this);
    this.init()
  }
  // 获取列表
  init () {
    // this.setState({
    //   stayList: [],
    //   stayPage: 0
    // })
    const { pageNum, pageSize, unitId, begin, end } = this.state
    getStayList({
      pageNum,
      pageSize,
      unitId,
      begin,
      end,
      scenario: 4
    }).then(res => {
      // //console.log('res :>> ', res);
      if (res.data && res.data.message === 'success') {
        // //console.log('res.data.data :>> ', res.data.data);
        this.setState({
          stayList: res.data.data.list,
          stayPage: res.data.data.total
        })
      } else {
        message.error(res.data.message)
      }
    })
  }
  // 切换列表分页
  changePage = (value) => {
    // //console.log('value :>> ', value);
    this.setState({
      pageNum: value.current
    }, () => {
      this.init()
    })
  }
  changedelPage = (value) => {
    this.setState({
      depageNum: value.current
    }, () => {
      // this.init()
      this.getDelList(this.state.pId)
    })
  }
  // 单位筛选
  stayChangeUint = (value) => {
    // //console.log('value :>> ', value);
    this.setState({
      unitId: value,
      pageNum: 1
    }, () => {
      this.init()
    })
  }
  // 时间筛选
  recordTime = (date, dateSting) => {
    // //console.log('date,dateSting :>> ', date, dateSting);
    this.setState({
      begin: dateSting[0],
      end: dateSting[1],
      pageNum: 1
    }, () => {
      this.init()
    })
  }
  // 查看工作记录
  checkDetail = (text, record, index) => {
    // //console.log('text, record, index :>> ', text, record, index);
    this.getDelList(record.id)
    this.getMergeList(record.id)
    this.setState({

      pId: record.id
    })
  }
  // 获取驻留详情列表
  getDelList = (val) => {
    const params = {
      pId: val,
      pageNum: this.state.depageNum,
      pageSize: this.state.depageSize
    }
    getStayDetail(params).then(res => {
      if (res.data && res.data.message === 'success') {
        //console.log('res.data.data :>> ', res.data.data);
        this.setState({
          detailsList: res.data.data.list,
          detailsPage: res.data.data.total,
          detailsVisible: true,
        })
      } else {
        message.error(res.data.message)
      }
      // //console.log('res :>> ', res);
    })
  }
  // 获取详情合并列表
  getMergeList = (val) => {
    getStayMerge(val).then(res => {
      if (res.data && res.data.message === 'success') {
        //console.log('res.data.data :>> ', res.data.data);
        this.setState({
          totalList: res.data.data
        })
      } else {
        message.error(res.data.message)
      }
      // //console.log('res :>> ', res);
    })
  }
  render () {
    // //console.log('this.props.unitlist :>> ', this.props.unitlist);
    const unitlist = this.props.unitlist.map(el => {
      return {
        value: el.id,
        label: el.name
      }
    })
    const listion = <img src={require('../images/close.png')} alt="" />
    return (
      <>
        <div className="contentmain">
          <span>所属单位：</span>
          <Select
            style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
            placeholder="请选择所属单位"
            onChange={this.stayChangeUint}
            value={this.state.unitId}
            allowClear
            options={unitlist}
          />
          <span>时间：</span>
          <RangePicker onChange={this.recordTime} />
          <div>
            <Table
              dataSource={this.state.stayList}
              columns={this.state.stayColumns}
              onChange={this.changePage}
              pagination={{ total: this.state.stayPage }}
              rowKey={record => record.id + Math.random()}
            />
          </div>
        </div>
        <Modal title="详情" open={this.state.detailsVisible}
          footer={null}
          onCancel={() => { this.setState({ detailsVisible: false, depageNum: 1 }) }}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}>
          {
            this.state.detailsList.length > 0
              ?
              <Descriptions size="small" column={5}>
                <Descriptions.Item label="人员">{this.state.detailsList[0].person}</Descriptions.Item>
                <Descriptions.Item label="职位" span={2}>{this.state.detailsList[0].position}</Descriptions.Item>
                <Descriptions.Item label="胸牌ID号">{this.state.detailsList[0].rfidTagNo}</Descriptions.Item>
              </Descriptions>
              :
              null
          }
          <p className="modeltitle" style={{ marginTop: 0 }}>主要时间段：</p>
          <Table
            dataSource={this.state.totalList}
            columns={this.state.detailsTotalColumns}
            pagination={false}
            rowKey={record => record.id + Math.random()}
            bordered
            size="small"
          />
          <p className="modeltitle">全部驻留记录：</p>
          <Table
            dataSource={this.state.detailsList}
            columns={this.state.detailsColumns}
            onChange={this.changedelPage}
            pagination={{ total: this.state.detailsPage, defaultPageSize: this.state.depageSize }}
            rowKey={record => record.id + Math.random()}
            size="small"
          />
        </Modal>
      </>
    )
  }
}

export default Socket;
