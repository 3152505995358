import React from "react";
import {
  Card, Input, Table, Select, Modal, Tooltip, DatePicker, Tabs
} from "antd";
import moment from 'moment'
import {
  getSterilize, getMergeList, getOriginalList, getunitList
} from '../axios';
const { Search } = Input;
// const { Option } = Select;
const { RangePicker } = DatePicker;
class Socket extends React.Component {
  constructor(props) {
    super(props);
    // //console.log('props :>> ', props);
    this.state = {
      // tab切换
      tabActive: '1',
      unitId: null,
      statusVisible: false,
      recordVisible: false,
      socketVisible: false,
      editVisible: false,
      socketList: [],
      lineList: [],
      unitlist: [],
      mergeList: [],
      initList: [],
      linePage: 0,
      mergePage: 0,
      initPage: 0,
      editItem: {},
      socketColumns: [
        {
          title: "所属单位",
          dataIndex: "siteName",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div >
            )
          }
        }, {
          title: "所属市",
          dataIndex: "city",
        },
        {
          title: "所属区",
          dataIndex: "area",
        },
        {
          title: "设备位置",
          dataIndex: "position",
        },
        // {
        //   title: "信号强度",
        //   dataIndex: "sig",
        // },

        {
          title: "消毒日期",
          dataIndex: "date",
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                {moment(text).format('YYYY-MM-DD')}
              </div >
            )
          }
        },
        {
          title: "工作记录",
          dataIndex: "id",
          align: 'center',
          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.checkDetail(text, record, index)}
              >
                详情
              </div>
            )
          }
        }
      ],
      lineColumns: [
        {
          title: "设备状态",
          dataIndex: "status",
          render: (text, record, index) => {
            return (
              record.status === true
                ?
                <div style={{ color: '#1eb333' }}>
                  上线
                </div>
                :
                record.status === false
                  ?
                  <div style={{ color: '#f55238' }}>
                    下线
                  </div>
                  :
                  <div style={{ color: '#999999' }}>
                    未知
                  </div>
            )
          }
        },
        {
          title: "上报时间",
          dataIndex: "date"
        }
      ],
      mergeColumns: [
        {
          title: "开始时间",
          dataIndex: "begin"
        },
        {
          title: "结束时间",
          dataIndex: "end"
        },
        {
          title: "消毒时长(分)",
          dataIndex: "status",
          render: (text, record, index) => {
            return (
              <span>
                {moment(record.end).diff(moment(record.begin), 'minute')}
              </span>
            )
          }
        }
      ],
      initColumns: [
        {
          title: "MAC",
          dataIndex: "mac"
        },
        {
          title: "功率（W）",
          dataIndex: "power"
        },
        {
          title: "时间",
          dataIndex: "gmtcreate"
        },
        {
          title: "状态",
          dataIndex: "status",
          render: (text, record, index) => {
            return (
              <span>
                {record.loadStatus === true ? '开启' : record.loadStatus === false ? '关闭' : '未知'}
              </span>
            )
          }
        }
      ],
      // 列表参数
      cityId: '',
      districtId: '',
      // unitId: '',
      deviceId: null,
      socketPage: 0,
      listPageNum: 1,
      linePageNum: 1,
      initPageNum: 1,
      onlineStatus: null,
      rangeTime: [null, null]
    }
  }
  componentDidMount () {
    this.props.onRef && this.props.onRef(this);
    this.init()
  }
  // 插座列表
  init = () => {
    const { listPageNum, unitId, deviceId, rangeTime } = this.state
    // console.log('onlineStatus :>> ', onlineStatus);
    const params = {
      pageNum: listPageNum,
      pageSize: 10,
      unitId,
      deviceId,
      start: rangeTime[0] ? rangeTime[0].format('YYYY-MM-DD') : null,
      stop: rangeTime[1] ? rangeTime[1].format('YYYY-MM-DD') : null
    }
    getSterilize(params).then(res => {
      // console.log('res :>> ', res);
      if (res.data.message === 'success') {
        this.setState({
          socketList: res.data.data.detectionVOList,
          socketPage: res.data.data.total
        })
      }
    })
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        let arr = []
        for (let i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }
  // 筛选表格
  tablechange = (value) => {
    // console.log('a,b,c,d :>> ', a, b, c, d);
    this.setState({
      listPageNum: value.current
    }, () => {
      this.init()
    })
  }
  // 单位筛选
  socketChangeUint = (value) => {
    this.setState({
      unitId: value,
      listPageNum: 1
    }, () => {
      this.init()
    })
  }
  //输入关键字
  changeKey = (e) => {
    if (e.target.value) {
      this.setState({
        deviceId: e.target.value
      })
    } else {
      this.setState({
        deviceId: null
      })
    }

  }
  socketQuery = () => {
    this.setState({
      listPageNum: 1
    }, () => {
      this.init()
    })
  }
  // 修改
  socketEdit = (text, record, index) => {
    this.setState({
      editVisible: true,
      editItem: record,
      position: record.position
    })
  }
  changeInitPage = (value) => {
    this.setState({
      initPageNum: value.current
    }, () => {
      this.original()
    })
  }

  // 查看工作记录
  checkDetail = (text, record, index) => {
    //console.log('text, record, index :>> ', text, record, index);
    this.setState({
      detailItem: record,
    }, async () => {
      await this.merge()
      await this.original()
      this.setState({
        recordVisible: true
      })
    })
  }
  merge = () => {
    const { detailItem } = this.state
    const params = {
      pageNum: 1,
      pageSize: 1000,
      start: `${moment(detailItem.date).format('YYYY-MM-DD')} 00:00:00`,
      stop: `${moment(detailItem.date).format('YYYY-MM-DD')} 23:59:59`,
      mac: detailItem.deviceId
    }
    getMergeList(params).then(res => {
      let arr = [];
      let newarr = [];

      if (res.data.message === 'success') {
        const { data } = res.data
        // eslint-disable-next-line
        data.map((el, index) => {
          if (index === 0) {
            if (el.loadStatus) {
              if (moment(new Date(el.gmtcreate)).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
                arr.push("使用中")
              } else {
                arr.push("")
              }
              newarr.push(el.gmtcreate);
            } else {
              arr.push(el.gmtcreate)
            }
          } else {
            if (el.loadStatus !== data[index - 1].loadStatus) {
              if (!el.gmtcreate) {
                if (data[index - 1].loadStatus) {
                  arr.push("")
                } else {
                  newarr.push("")
                }
              } else {
                if (el.loadStatus) {
                  newarr.push(el.gmtcreate)
                } else {
                  arr.push(el.gmtcreate)
                }
              }
            } else {
              if (el.loadStatus) {
                newarr.push(el.gmtcreate);
                arr.push("");
              } else {
                newarr.push("");
                arr.push(el.gmtcreate);
              }
            }
          }
        })
        let arrlist = arr.map((el, index) => {
          return {
            begin: newarr[index],
            end: el
          }
        })
        // arrlist.map(el => {
        //   if (el.begin&&el.end&&el.end!=="使用中") {
        //     el.totaltime = 
        //   }
        // })
        // for (var z in arrlist) {
        //   if (
        //     arrlist[z].begin &&
        //     arrlist[z].end &&
        //     arrlist[z].end !== "使用中"
        //   ) {
        //     arrlist[z].totaltime = (
        //       (new Date(arrlist[z].end.replace(/-/g, "/")).getTime() -
        //         new Date(arrlist[z].begin.replace(/-/g, "/")).getTime()) /
        //       60000
        //     ).toFixed(1);
        //   }
        // }
        // //console.log(arrlist);
        // this.readout = arrlist;
        // this.readoutlength = arrlist.length;
        this.setState({
          mergeList: arrlist,
          mergePage: arrlist.length,
        })
      }
    })
  }
  original = () => {
    const { detailItem, initPageNum } = this.state
    const params = {
      pageNum: initPageNum,
      pageSize: 10,
      start: `${moment(detailItem.date).format('YYYY-MM-DD')} 00:00:00`,
      stop: `${moment(detailItem.date).format('YYYY-MM-DD')} 23:59:59`,
      mac: detailItem.deviceId
    }
    getOriginalList(params).then(res => {
      if (res.data.message === 'success') {
        this.setState({
          initList: res.data.data.boardReadings,
          initPage: res.data.data.total,
        })
      }
    })
  }

  // // 记录时间切换
  recordTime = (date) => {
    // console.log('dat :>> ', date);
    if (date) {
      this.setState({
        rangeTime: date
      }, () => {
        this.init()
        // this.original()
        // this.merge()
      })
    } else {
      this.setState({
        rangeTime: [null, null]
      }, () => {
        this.init()
        // this.original()
        // this.merge()
      })
    }

  }
  // tab切换
  // tabs切换事件
  tabChange = (activeKey) => {
    this.setState({
      tabActive: activeKey
    })
  }
  render () {
    // //console.log('this.props.unitlist :>> ', this.props.unitlist);
    const unitlist = this.state.unitlist.map(el => {
      return {
        value: el.id,
        label: el.name
      }
    })
    const listion = <img src={require('../images/close.png')} alt="" />

    // tabs各项内容定义
    const items = [
      {
        label: '合并数据',
        key: '1',
        children: <Table
          dataSource={this.state.mergeList}
          columns={this.state.mergeColumns}
          pagination={this.state.mergePage > 10 ? true : false}
          rowKey={(record) => record.begin + Math.random()}
        />
      },
      {
        label: '原始数据',
        key: '2',
        children: <Table
          dataSource={this.state.initList}
          columns={this.state.initColumns}
          // pagination={this.state.initPage > 10 ? true : false}
          onChange={this.changeInitPage}
          pagination={{ total: this.state.initPage, showSizeChanger: false }}
          rowKey={record => record.id}
        />
      }
    ]
    // const [form] = Form.useForm()
    return (
      <Card title="空气消毒" headStyle={{ color: '#2a2a2a', fontSize: '18px' }} style={{ margin: "20px 0px" }}>
        <div className="contentmain">
          <span>所属单位：</span>
          <Select
            style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
            placeholder="请选择所属单位"
            onChange={this.socketChangeUint}
            value={this.state.unitId}
            allowClear
            options={unitlist}
          />
          <Search placeholder="请输入设备编号"
            onSearch={this.socketQuery}
            onChange={this.changeKey}
            value={this.state.deviceId}
            enterButton
            style={{ margin: '20px', width: '300px', marginTop: '0' }}
          />
          <span>时间：</span>
          <RangePicker onChange={this.recordTime} value={this.state.rangeTime} />
          <div>
            <Table
              dataSource={this.state.socketList}
              columns={this.state.socketColumns}
              onChange={this.tablechange}
              // pagination={this.state.socketPage > 10 ? true : false}
              pagination={{ total: this.state.socketPage, showSizeChanger: false }}
              rowKey={record => record.id}
            />
          </div>
        </div>

        <Modal title="工作记录" open={this.state.recordVisible}
          footer={null}
          width="600px"
          onCancel={() => { this.setState({ recordVisible: false }) }}
          closeIcon={listion}
          destroyOnClose
          zIndex={1}>

          <Tabs defaultActiveKey="1" onChange={this.tabChange} activeKey={this.state.tabActive} items={items}></Tabs>
        </Modal>
      </Card >
    )
  }
}

export default Socket;
