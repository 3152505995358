import http from "./tools";


// export const url = 'http://192.168.1.228:8081';
// export const url = 'http://192.168.1.224:8088/zs/smoke';
// export const url = 'http://47.110.248.244:8081';
// export const url = 'http://121.41.8.207:8081';
// export const url = 'http://192.168.3.9:8088/zs/smoke';
// export const url = 'http://121.41.8.207:8088/zs/smoke';
export const url = 'https://scym.terabits.cn:8989/zs/beautyClinic';
// export const url = 'http://192.168.1.249:8098/zs/beautyClinic';
// export const url = 'https://filing.terabits.cn:8000';



//登录
export const login = params =>
  http.get(url + "/login", {
    username: params[0],
    password: params[1],
  });


//出入列表查询
export const getinoutList = params =>
  http.get(url + "/api/v1/inOut/getList", {
    access_token: params[0],
    pageNum: params[1],
    pageSize: params[2],
    cityId: params[3],
    districtId: params[4],
    unitId: params[5],
    beginTime: params[6],
    endTime: params[7],
    searchField: params[8],
    type: params[9],
  });


//出入列表查询
export const getinoutLists = params =>
  http.get(url + "/api/v1/inOut/getList", {
    access_token: params[0],
    pageNum: params[1],
    pageSize: params[2],
    unitId: params[3],
    beginTime: params[4],
    endTime: params[5],
    type: params[6],
  });



//删除出入记录
export const deleteinoutlist = params =>
  http.delete(url + "/api/v1/inOut", {
    access_token: localStorage.getItem('token'),
    eventId: params[0],
  });


//获取ISC网点树形列表
export const getIscAreaList = params =>
  http.get(url + "/api/v1/device/getIscAreaList", {
    access_token: localStorage.getItem('token'),
    iscArgsId: params[0],
  });



//根据ISC网点获取设备列表
export const getIscCamerasByIndexCode = params =>
  http.get(url + "/api/v1/device/getIscCamerasByIndexCode", {
    access_token: localStorage.getItem('token'),
    indexCode: params[0],
    iscArgsId: params[1],
  });


//设备关系绑定
export const insertDoorCameraRelation = params =>
  http.get(url + "/api/v1/device/insertDoorCameraRelation", {
    access_token: localStorage.getItem('token'),
    doorDeviceId: params[0],
    cameraDeviceIds: params[1],
  });


//查询ISC门禁设备列表
export const selectDoorDevices = params =>
  http.get(url + "/api/v1/device/selectDoorDevices", {
    access_token: localStorage.getItem('token'),
    iscArgsId: params[0],
    regionIndexCodes: params[1],
  });

//查询设备关系绑定
export const selectDoorCameraRelation = params =>
  http.get(url + "/api/v1/device/selectDoorCameraRelation", {
    access_token: localStorage.getItem('token'),
    doorDeviceId: params[0],
  });


//添加说明
export const addResult = params =>
  http.get(url + "/api/v1/alarm/addResult", {
    access_token: localStorage.getItem('token'),
    id: params[0],
    result: params[1],
  });

//视频看板
export const completeDeviceTree = params =>
  http.get(url + "/api/v1/device/completeDeviceTree", {
    access_token: localStorage.getItem('token'),
  });




//添加日志
export const addlogoperate = params =>
  http.posts("https://ivatest.terabits.cn:9011/log/operate", {
    userName: params[0],
    content: params[1],
    type: params[2],
    platform: params[3],
    operateTime: params[4],
    module: params[5],
  });



//查询日志
export const logoperatelist = params =>
  http.get("https://ivatest.terabits.cn:9011/log/operate", {
    current: params[0],
    size: params[1],
    userName: params[2],
    type: params[3],
    startTime: params[4],
    endTime: params[5],
    platform: params[6],
  });


//根据姓名查询
export const getListByName = params =>
  http.get(url + "/api/v1/physician/getListByName", {
    access_token: localStorage.getItem('token'),
    name: params[0],
    multiagency: params[1],
  });



//根据id查询
export const getById = params =>
  http.get(url + "/api/v1/physician/getById", {
    access_token: localStorage.getItem('token'),
    id: params[0],
  });

//根据姓名查询
export const getListByNames = params =>
  http.get(url + "/api/v1/doctor/getListByName", {
    access_token: localStorage.getItem('token'),
    name: params[0],
    multiagency: params[1],
  });

//根据id查询
export const getByIdBydoctor = params =>
  http.get(url + "/api/v1/doctor/getById", {
    access_token: localStorage.getItem('token'),
    id: params[0],
  });



//获取菜单列表
export const menulist = params =>
  http.get(url + "/api/v1/menu/list", {
    access_token: localStorage.getItem('token'),
    menuName: params[0],
    parentId: params[1],
    url: params[2],
  });

//添加菜单
export const addmenu = params =>
  http.posts(url + "/api/v1/menu/add?access_token=" + localStorage.getItem('token'),
    {
      access_token: localStorage.getItem('token'),
      menuName: params[0],
      path: params[1],
      icon: params[2],
      parentId: params[3],
      menuType: params[4],
      orderNum: params[5],
      remark: params[6],
    });


//修改菜单
export const editmenu = params =>
  http.posts(url + "/api/v1/menu/edit?access_token=" + localStorage.getItem('token'), {
    menuName: params[0],
    path: params[1],
    icon: params[2],
    parentId: params[3],
    menuType: params[4],
    orderNum: params[5],
    menuId: params[6],
  });

//根据菜单编号获取详细信息
export const getmenus = params =>
  http.get(url + "/api/v1/menu/get", {
    access_token: localStorage.getItem('token'),
    menuId: params[0],
  });


//删除菜单
export const deletemenu = params =>
  http.get(url + "/api/v1/menu/delete", {
    access_token: localStorage.getItem('token'),
    menuId: params[0],
  });


//新增角色菜单信息
export const insertRoleMenu = params =>
  http.post(url + "/api/v1/menu/insertRoleMenu", {
    access_token: localStorage.getItem('token'),
    roleId: params[0],
    menuIds: params[1],
  });










//告警管理
export const getalarmList = params =>
  http.get(url + "/api/v1/alarm/getList", {
    access_token: params[0],
    pageNum: params[1],
    pageSize: params[2],
    beginTime: params[3],
    endTime: params[4],
    deviceId: params[5],
    unitId: params[6],
  });


//单位管理
export const getunitList = params =>
  http.get(url + "/api/v1/unit/getList", {
    access_token: params[0],
    unit: params[1],
  });

//设备管理
export const getdeviceList = params =>
  http.get(url + "/api/v1/device/getList", {
    access_token: params[0],
    deviceType: params[1],
    provinceId: params[2],
    cityId: params[3],
    districtId: params[4],
    unitId: params[5],
    deviceId: params[6],
  });

//账号管理
export const getaccount = params =>
  http.get(url + "/api/v1/admin/account/get", {
    access_token: params[0],
    provinceId: params[1],
    cityId: params[2],
    districtId: params[3],
    unitId: params[4],
    keyword: params[5],
  });


//角色管理
export const getrole = params =>
  http.get(url + "/api/v1/admin/role/get", {
    access_token: params[0],
  });

//添加角色
export const addrole = params =>
  http.post(url + "/api/v1/admin/role/add", {
    access_token: params[0],
    name: params[1],
  });

//删除角色
export const deleterole = params =>
  http.post(url + "/api/v1/admin/role/delete", {
    access_token: params[0],
    roleId: params[1],
  });

//获取所有权限
export const getmenu = params =>
  http.get(url + "/api/v1/admin/menu/get", {
    access_token: localStorage.getItem('token'),
    name: params[0],
  });

//获取角色拥有权限
export const getroleMenu = params =>
  http.get(url + "/api/v1/admin/roleMenu/get", {
    access_token: localStorage.getItem('token'),
    name: params[0],
  });

//修改角色权限
export const updateroleMenu = params =>
  http.post(url + "/api/v1/admin/roleMenu/update", {
    access_token: localStorage.getItem('token'),
    menuIds: params[0],
    roleId: params[1],
  });




//获取当前账户级联
export const getunitTree = params =>
  http.get(url + "/api/v1/admin/unitTree/get", {
    access_token: params[0],
  });


//添加用户
export const addaccount = params =>
  http.post(url + "/api/v1/admin/account/add", {
    access_token: params[0],
    roleId: params[1],
    provinceId: params[2],
    cityId: params[3],
    districtId: params[4],
    unitId: params[5],
    userName: params[6],
    password: params[7],
    realName: params[8],
    phone: params[9],
    email: params[10],
  });


//修改用户信息
export const changeaccount = params =>
  http.post(url + "/api/v1/admin/personal/update", {
    access_token: localStorage.getItem('token'),
    id: params[0],
    realName: params[1],
    phone: params[2],
    email: params[3],
  });



//删除用户
export const deleteaccount = params =>
  http.post(url + "/api/v1/admin/account/delete", {
    access_token: params[0],
    id: params[1],
  });

//修改用户状态
export const updateaccount = params =>
  http.post(url + "/api/v1/admin/account/update", {
    access_token: params[0],
    id: params[1],
    sendMsg: params[2],
  });



//添加单位
export const addunit = params =>
  http.post(url + "/api/v1/unit/add", {
    access_token: localStorage.getItem('token'),
    unit: params[0],
    address: params[1],
    provinceId: params[2],
    cityId: params[3],
    districtId: params[4],
    userName: params[5],
    phone: params[6],
    longitude: params[7],
    latitude: params[8],
    id: params[9],
  });

//添加单位
export const deleteunit = params =>
  http.post(url + "/api/v1/unit/delete", {
    access_token: localStorage.getItem('token'),
    id: params[0],
  });


//新增摄像头
export const addcamera = params =>
  http.post(url + "/api/v1/device/add", {
    access_token: localStorage.getItem('token'),
    type: params[0],
    deviceId: params[1],
    unitId: params[2],
    location: params[3],
    iscArgsId: params[4],
    id: params[5],
  });

//新增烟雾传感器
export const addsensor = params =>
  http.post(url + "/api/v1/device/add", {
    access_token: localStorage.getItem('token'),
    type: params[0],
    deviceId: params[1],
    imei: params[2],
    unitId: params[3],
    location: params[4],
    id: params[5],
  });


//删除设备
export const deletedevice = params =>
  http.post(url + "/api/v1/device/delete", {
    access_token: localStorage.getItem('token'),
    id: params[0],
  });

//获取萤石云未添加的设备列表
export const getNotHaveList = params =>
  http.get(url + "/api/v1/device/getNotHaveList", {
    access_token: localStorage.getItem('token'),

  });


//操作日志查询
export const getactivitylog = params =>
  http.get(url + "/api/v1/log/activity/get", {
    access_token: localStorage.getItem('token'),
    pageNum: params[0],
    pageSize: params[1],
    beginTime: params[2],
    endTime: params[3],
  });


//设备日志查询
export const getdevicelog = params =>
  http.get(url + "/api/v1/log/device/get", {
    access_token: localStorage.getItem('token'),
    pageNum: params[0],
    pageSize: params[1],
    deviceId: params[2],
    deviceType: params[3],
    dataType: params[4],
    beginTime: params[5],
    endTime: params[6],
  });



//查看报警视频
export const getAlarmVideoUrl = params =>
  http.get(url + "/api/v1/alarm/getAlarmVideoUrl", {
    access_token: localStorage.getItem('token'),
    deviceId: params[0],
    time: params[1],
  });


//获取当前用户
export const getpersonal = params =>
  http.get(url + "/api/v1/admin/personal/get", {
    access_token: localStorage.getItem('token'),
  });

//修改短信推送状态
export const changestaus = params =>
  http.post(url + "/api/v1/device/add", {
    access_token: localStorage.getItem('token'),
    id: params[0],
    sendMsg: params[1],
  });

//摄像头查看实时画面
export const getRealVideo = params =>
  http.get(url + "/api/v1/device/getRealVideo", {
    access_token: localStorage.getItem('token'),
    id: params[0],
  });

//统计基本信息
export const getBaseInfo = params =>
  http.get(url + "/api/v1/statistics/getBaseInfo", {
    access_token: localStorage.getItem('token'),

  });

//单位报警排行
export const getUnitAlarmList = params =>
  http.get(url + "/api/v1/statistics/getUnitAlarmList", {
    access_token: localStorage.getItem('token'),
    dateKey: params[0],
  });

//设备报警统计
export const getDeviceAlarmList = params =>
  http.get(url + "/api/v1/statistics/getDeviceAlarmList", {
    access_token: localStorage.getItem('token'),
    type: params[0],  // 1:周 2:月 3年
  });

//区域所属单位
export const getDistrictUnit = params =>
  http.get(url + "/api/v1/statistics/getDistrictUnit", {
    access_token: localStorage.getItem('token'),
  });

//获取所有菜单
export const getallmemu = params =>
  http.get(url + "/api/v1/menu/roleMenuTreeselect/get", {
    access_token: localStorage.getItem('token'),
    roleId: params[0],
  });

//激活摄像头设备
export const activeDevice = params =>
  http.post(url + "/api/v1/device/activeDevice", {
    access_token: localStorage.getItem('token'),
    code: params[0],
    id: params[1],
  });


//场景报警总量统计
export const getNearMonthUnitTypeAlarmList = params =>
  http.get(url + "/api/v1/statistics/getNearMonthUnitTypeAlarmList", {
    access_token: localStorage.getItem('token'),
    dateKey: params[0],
  });


//获取所有场景
export const getSceneList = params =>
  http.get(url + "/api/v1/statistics/getSceneList", {
    access_token: localStorage.getItem('token'),
  });


//获取所有场景
export const getSceneUnitAlarmList = params =>
  http.get(url + "/api/v1/statistics/getSceneUnitAlarmList", {
    access_token: localStorage.getItem('token'),
    unitType: params[0],
    dateKey: params[1],
  });

//获取所有场景
export const getUnitAlarmHeatImage = params =>
  http.get(url + "/api/v1/unit/getUnitAlarmHeatImage", {
    access_token: localStorage.getItem('token'),
    id: params[0],
  });


//获取对应单位报警数据
export const getUnitAlarm = params =>
  http.get(url + "/api/v1/statistics/getUnitAlarm", {
    access_token: localStorage.getItem('token'),
    dateKey: params[0],
    unitId: params[1],
  });


//单位告警折线图及热力图
export const getUnitAlarmAndHeat = params =>
  http.get(url + "/api/v1/statistics/getUnitAlarmAndHeat", {
    access_token: localStorage.getItem('token'),
    dateKey: params[0],
    unitId: params[1],
  });

//删除报警记录
export const deletealarm = params =>
  http.delete(url + "/api/v1/alarm", {
    access_token: localStorage.getItem('token'),
    eventId: params[0],
  });


//获取设备最后一次上报事件列表
export const getLatestList = params =>
  http.get(url + "/api/v1/event/getLatestList", {
    access_token: localStorage.getItem('token'),
    pageNum: params[0],
    pageSize: params[1],
    unitId: params[2],
  });


//获取某个设备上报事件列表
export const geteventList = params =>
  http.get(url + "/api/v1/event/getList", {
    access_token: localStorage.getItem('token'),
    pageNum: params[0],
    pageSize: params[1],
    unitId: params[2],
    location: params[3],
    beginTime: params[4],
    endTime: params[5],

  });




//对报警做出说明
export const getInfo = (params) => http.get(url + '/api/v1/admin/account/getInfo', {
  access_token: params[0],
});



//人员列表查询
export const getpersonList = (params) => http.get(url + '/api/v1/person/getList', {
  access_token: params[0],
  unitId: params[1],
  deviceId: params[2],
});

//新增编辑人员
export const addperson = (params) => http.post(url + '/api/v1/person/add', {
  access_token: params[0],
  unitId: params[1],
  name: params[2],
  cardNo: params[3],
  position: params[4],
  deviceId: params[5],
  doctorId: params[6],
  rfidTagNo: params[7],
  id: params[8],

});


//删除人员
export const deleteperson = (params) => http.get(url + '/api/v1/person/delete', {
  access_token: params[0],
  id: params[1],
});

//查询未绑定人员卡号
export const selectNotBach = (params) => http.post(url + '/api/v1/person/selectNotBach', {
  access_token: params[0],
  unitId: params[1],
});


//获取一周统计
export const getStaticsWeek = (params) => http.get(url + '/api/v1/statistics/getStaticsWeek', {
  access_token: params[0],
});


//获取isc
export const getIscArgs = (params) => http.post(url + '/api/v1/device/getIscArgs', {
  access_token: localStorage.getItem('token'),
});

//获取报告
export const getReport = (params) => http.get(url + '/api/v1/report/getReport', {
  access_token: localStorage.getItem('token'),
  pageNum: params[0],
  pageSize: params[1],
  unitId: params[2],
  beginTime: params[3],
  endTime: params[4],
});

//离线设备数量
export const selectOnlineDeviceSum = (params) => http.get(url + '/api/v1/device/selectOnlineDeviceSum', {
  access_token: localStorage.getItem('token'),

});

// 新增读卡器设备
export const addCard = (params) => http.post(url + "/api/v1/device/add", {
  access_token: localStorage.getItem('token'),
  ...params
});
// 获取人员驻留列表
export const getStayList = (params) => http.get(url + '/api/v1/stay/list', {
  access_token: localStorage.getItem('token'),
  ...params
})
// 人员驻留详情
export const getStayDetail = (params) => http.get(url + '/api/v1/stay/detail', {
  access_token: localStorage.getItem('token'),
  ...params
})
// 人员驻留详情合并列表
export const getStayMerge = (pId) => http.get(url + '/api/v1/stay/merge/list', {
  access_token: localStorage.getItem('token'),
  pId
})
// 插座列表
export const getSocketList = (params) => http.get(url + '/board/getList', {
  access_token: localStorage.getItem('token'),
  ...params
})
// 添加/编辑插座
export const addSocket = (params) => http.posts(url + `/board/insert?access_token=${localStorage.getItem('token')}`, {
  ...params
})
// 删除插座
export const deteleSocket = (id) => http.delete(url + `/board/${id}`, {
  access_token: localStorage.getItem('token')
})
// 插座上下线记录
export const getLineList = (params) => http.get(url + '/board/onlinestatusWithPage', {
  access_token: localStorage.getItem('token'),
  ...params
})
// 查看工作记录
// 合并数据
export const getMergeList = (params) => http.get(url + '/reading', {
  access_token: localStorage.getItem('token'),
  ...params
})
// 原始数据
export const getOriginalList = (params) => http.get(url + '/reading/realWithPage', {
  access_token: localStorage.getItem('token'),
  ...params
})

// 空气消毒
export const getSterilize = (params) => http.get(url + '/api/v1/detection/board', {
  access_token: localStorage.getItem('token'),
  ...params
})

