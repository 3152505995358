import React from "react";
import {
  Layout,
  Button,
  Table,
  Modal,
  DatePicker, message, Select, Pagination, Tooltip, Tabs, Input
} from "antd";
import { deleteinoutlist, getinoutList, getunitList, addResult, getalarmList } from '../axios';


import "./workmain.scss";
import moment from 'moment';
// import Stay from "./stay";
const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
// const { TabPane } = Tabs;
const { TextArea } = Input;


function disabledDate (current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 1,
      pageNums: 1,
      recordpageNum: 1,
      pageNumSize: 10,
      doorimg: [],
      cameraimg: [],
      unitlist: [],
      videourl: undefined,
      alarmimg: [],
      remark: [],
    };

    if (localStorage.getItem('menubtnlist').split(',').indexOf("2012") === -1) {
      this.unnormalColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "位置",
          dataIndex: "deviceLocation",
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
        }, {
          title: "抓拍照片",
          dataIndex: "cameraImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },

        {
          title: "人脸照片",
          dataIndex: "doorImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openfaceimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "出入时间",
          dataIndex: "gmtOccur",
          align: 'right',
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
      ]
    } else {
      this.unnormalColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "位置",
          dataIndex: "deviceLocation",
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
        }, {
          title: "抓拍照片",
          dataIndex: "cameraImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },

        {
          title: "人脸照片",
          dataIndex: "doorImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openfaceimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "出入时间",
          dataIndex: "gmtOccur",
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span style={{ marginLeft: '10px', cursor: "pointer" }} onClick={() => this.unitdelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        },

      ]
    }


    if (localStorage.getItem('menubtnlist').split(',').indexOf("2013") === -1) {
      this.nodeInfoTableColumns = [
        {
          title: "所属单位",
          dataIndex: "unitName",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.alarmCamera}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "设备位置",
          dataIndex: "devicePosition",
        }, {
          title: "报警详情",
          dataIndex: "number",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.alarmdetail(text, record, index)}>
                  详情
                </span>
              </div>
            );
          }
        }, {
          title: "出入人员",
          dataIndex: "eventUserName",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "人员身份",
          dataIndex: "eventPosition",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        },
        {
          title: "报警图片",
          dataIndex: "alarmPicture",
          width: 120,
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.openimg(text, record, index)}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "视频回放",
          dataIndex: "statusConnect",

          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.movieback(text, record, index)}
              >
                查看
              </div>
            )
          }
        }, {
          title: "告警时间",
          dataIndex: "gmtOccur",
          align: 'right',
        },
      ];
    } else {
      this.nodeInfoTableColumns = [
        {
          title: "所属单位",
          dataIndex: "unitName",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.alarmCamera}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "设备位置",
          dataIndex: "devicePosition",
        }, {
          title: "报警详情",
          dataIndex: "number",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.alarmdetail(text, record, index)}>
                  详情
                </span>
              </div>
            );
          }
        }, {
          title: "出入人员",
          dataIndex: "eventUserName",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        }, {
          title: "人员身份",
          dataIndex: "eventPosition",
          render: (text, record, index) => {
            return (
              <div>
                {text || "-"}
              </div>
            );
          }
        },
        {
          title: "报警图片",
          dataIndex: "alarmPicture",
          width: 120,
          align: 'center',
          render: (text, record, index) => {
            return (
              <div>
                <span
                  style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.openimg(text, record, index)}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "处置结果",
          dataIndex: "result",
          render: (text, record, index) => {
            if (text) {
              return (
                <div>
                  <span style={{ color: 'rgb(8, 192, 77)', cursor: 'pointer' }} onClick={() => this.dealdetail(text, record, index)}>已处置</span>
                </div>
              )
            } else {
              return (
                <div>
                  <span style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.adddetail(text, record, index)}>添加</span>
                </div>
              )
            }

          }
        },
        {
          title: "视频回放",
          dataIndex: "statusConnect",

          render: (text, record, index) => {
            return (
              <div style={{ color: '#11A7D7', cursor: 'pointer' }}
                onClick={() => this.movieback(text, record, index)}
              >
                查看
              </div>
            )
          }
        }, {
          title: "告警时间",
          dataIndex: "gmtOccur",
          align: 'right',
        },
      ];
    }




    if (localStorage.getItem('menubtnlist').split(',').indexOf("2017") === -1) {
      this.recordColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "位置",
          dataIndex: "deviceLocation",
        }, {
          title: "出入人员",
          dataIndex: "eventUserName",
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
        }, {
          title: "人员身份",
          dataIndex: "eventPosition",
        }, {
          title: "抓拍照片",
          dataIndex: "cameraImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },

        {
          title: "人脸照片",
          dataIndex: "doorImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openfaceimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "出入时间",
          dataIndex: "gmtOccur",
          align: 'right',
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
      ];
    } else {
      this.recordColumns = [
        {
          title: "所属单位",
          dataIndex: "unit",
          align: 'left',
          render: (text, record, index) => {
            return (
              <div style={{ cursor: 'pointer' }}>
                <Tooltip placement="topLeft" title={record.deviceId}>
                  {text}
                </Tooltip>
              </div>
            )
          }
        },
        {
          title: "位置",
          dataIndex: "deviceLocation",
        }, {
          title: "出入人员",
          dataIndex: "eventUserName",
        }, {
          title: "卡号",
          dataIndex: "eventCardNo",
        }, {
          title: "人员身份",
          dataIndex: "eventPosition",
        }, {
          title: "抓拍照片",
          dataIndex: "cameraImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },

        {
          title: "人脸照片",
          dataIndex: "doorImages",
          render: (text, record, index) => {
            return (
              <div>
                <span
                  onClick={() => this.openfaceimg(text, record, index)}
                  style={{ color: '#FD7911', cursor: 'pointer' }}>
                  查看
                </span>
              </div>
            );
          }
        },
        {
          title: "出入时间",
          dataIndex: "gmtOccur",
          // sorter: (a, b) => new Date(a) > new Date(b) ? 1 : -1,
        },
        {
          title: "操作",
          dataIndex: "gmtCreate",
          align: 'right',
          render: (text, record, index) => {
            return (
              <div>
                <span style={{ marginLeft: '10px', cursor: "pointer" }} onClick={() => this.unitdelete(text, record, index)}>
                  <img src={require('../images/delete.png')} alt="" />
                </span>
              </div>
            );
          }
        },

      ];
    }




  }

  componentWillMount () {
    document.title = "工作详情";
    this.getunitList()
    this.unnormallist()
    this.cameraalarm()
    this.getinoutList()
  }

  componentDidMount () {

  }

  unnormallist = () => {
    getinoutList([
      localStorage.getItem('token'),
      this.state.pageNums,
      this.state.pageNumSize,
      this.state.cityid,
      this.state.areaid,
      this.state.siteIds,
      this.state.begintime,
      this.state.endtime,
      this.state.searchname,
      2,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          unnormallist: res.data.data.list,
          unnormatotal: res.data.data.total,
        })
      }
    });


  }

  //关键字录入
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })
  }


  //打开抓拍照片弹窗
  openimg = (text, record, index) => {
    if (text) {
      var arr = []
      for (var i in JSON.parse(text)) {
        arr.push("http://scym.terabits.cn" + JSON.parse(text)[i])
      }
      this.setState({
        imgvisible: true,
        cameraimg: arr,
        alarmimg: arr
      })
    } else {
      message.error('暂无图片')
    }
  }



  //打开人脸照片弹窗
  openfaceimg = (text, record, index) => {
    //console.log(text)
    if (text) {
      this.setState({
        imgvisibles: true,
        doorimg: "http://scym.terabits.cn" + JSON.parse(text)[0]
      })
    } else {
      message.error('暂无图片')
    }
  }



  //关闭添加单位弹窗
  handleCancel = () => {
    this.setState({
      deleteunitvisible: false,
      imgvisible: false,
      imgvisibles: false,
      videovisible: false,
      videourl: undefined,
      alarmdeletevisible: false,
      detailvisible: false,
      chuzhivisible: false,
      describevisible: false,
      remark: [],
    })
  }

  //tab切换
  tabchange = (value) => {
    //console.log(value)
    this.setState({
      begintime: undefined,
      endtime: undefined
    })
  }


  //打开删除出入记录弹窗
  unitdelete = (text, record, index) => {
    this.setState({
      deleteunitvisible: true,
      eventId: record.id
    })
  }

  //删除单位
  deleteunit = () => {
    deleteinoutlist([
      this.state.eventId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        this.setState({
          deleteunitvisible: false,
        })
        this.unnormallist()
      } else {
        message.error(res.data.message)
      }
    });
  }


  //搜索框录入
  searchname = (e) => {
    this.setState({
      searchname: e.target.value
    })
  }

  //搜索
  inoutquery = () => {
    this.setState({
      pageNums: 1,
    }, function () {
      this.unnormallist()
    })

  }





  //分页
  unnormalpagechange = (page, b) => {
    this.setState({
      pageNums: page,
      pageNumSize: b,
    }, function () {
      this.unnormallist()
    })
  }




  //报警时间筛选
  alarmtime = (value, b) => {
    if (!value) {
      this.setState({
        begintime: null,
        endtime: null
      })
    } else {
      this.setState({
        begintime: moment(value[0]).format("YYYY-MM-DD 00:00:00"),
        endtime: moment(value[1]).format("YYYY-MM-DD 23:59:59"),
      })
    }

  }


  //获取单位列表
  getunitList = () => {
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        var arr = []
        for (var i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }


  //选择单位
  changeunits = (value) => {
    this.setState({
      siteIds: value,
      pageNums: 1,
    }, function () {
      this.unnormallist()
    })
  }


  //风险推送相关开始
  //填写备注说明
  remarkchange = (e) => {
    this.setState({
      remark: e.target.value.slice(0, 200),
    })
  }

  //确认报警说明提交
  explainok = () => {
    addResult([
      this.state.eventId,
      this.state.remark,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('添加成功')
        this.setState({
          describevisible: false,
        })
        this.cameraalarm()
      }
    });
  }


  //摄像头页数变化
  pagechange = (page, b) => {
    //console.log(page, b)
    this.setState({
      pageNum: page,
      pageNumSize: b,
    }, function () {
      this.cameraalarm()
    })
  }

  //查询
  cameraquery = () => {
    this.setState({
      pageNum: 1
    }, function () {
      this.cameraalarm()
    })

  }

  //打开报警详情弹窗
  alarmdetail = (text, record, index) => {
    this.setState({
      detailvisible: true,
      number: record.number
    })
  }

  cameraalarm = () => {
    getalarmList([
      localStorage.getItem('token'),
      this.state.pageNum,
      this.state.pageNumSize,
      this.state.begintime,
      this.state.endtime,
      this.state.keytext,
      this.state.siteId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          alarmlist: res.data.data.list,
          alarmtotal: res.data.data.total
        })
      }
    });
  }

  //跳转回放
  movieback = (text, record, index) => {
    var begin = moment((new Date(record.gmtOccur).getTime() - 1000 * 60))
    var end = moment((new Date(record.gmtOccur).getTime() + 1000 * 60 * 10))
    localStorage.setItem('cameraindexcode', record.alarmCamera)
    localStorage.setItem('cameralocation', record.devicePosition)
    localStorage.setItem('alarmbegin', begin)
    localStorage.setItem('alarmend', end)
    localStorage.setItem('backtype', "alarm")
    localStorage.setItem('cameraappkey', record.iscArgsId === 2 ? "27517347" : "21918920")
    localStorage.setItem('cameraappsecret', record.iscArgsId === 2 ? "ehPknvh9I9UGAHY1jGdC" : "J9s2irFNCyhA6TSKosBc")
    localStorage.setItem('camerahost', record.iscArgsId === 2 ? "122.224.50.67" : "118.178.57.33")
    localStorage.setItem('cameraport', record.iscArgsId === 2 ? 81 : 4443)
    window.location.href = '/app/alarmback';
  }



  // //打开抓拍图片弹窗
  // openimg = (text, record, index) => {
  //     var arr = []
  //     for (var i in JSON.parse(record.alarmPicture)) {
  //         arr.push("http://scym.terabits.cn" + JSON.parse(record.alarmPicture)[i])
  //     }
  //     //console.log(arr)
  //     this.setState({
  //         imgvisible: true,
  //         alarmimg: arr
  //     })
  // }

  //打开处置详情弹窗
  dealdetail = (text, record, index) => {
    this.setState({
      chuzhivisible: true,
      result: record.result,
      resulttime: record.gmtDispose,
      resultpeople: record.disposeAdminName,
    })
  }


  //打开删除弹窗
  alarmDelete = (text, record, index) => {
    this.setState({
      eventId: record.id,
      alarmdeletevisible: true,
    })
  }

  //打开添加说明弹窗
  adddetail = (text, record, index) => {
    this.setState({
      describevisible: true,
      eventId: record.id,
    })
  }

  //选择单位
  changeunit = (value) => {
    this.setState({
      siteId: value,
      pageNum: 1,
    }, function () {
      this.cameraalarm()
    })
  }

  //风险推送相关结束

  //出入记录开始
  getinoutList = () => {
    getinoutList([
      localStorage.getItem('token'),
      this.state.recordpageNum,
      this.state.pageNumSize,
      this.state.cityid,
      this.state.areaid,
      this.state.recordsiteId,
      this.state.begintime,
      this.state.endtime,
      this.state.searchname,
      1,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          recordlist: res.data.data.list,
          total: res.data.data.total,
        })
      }
    });
  }


  //分页
  recordpagechange = (page, b) => {
    this.setState({
      recordpageNum: page,
      pageNumSize: b,
    }, function () {
      this.getinoutList()
    })
  }


  //选择单位
  recordchangeunit = (value) => {
    this.setState({
      recordsiteId: value,
      recordpageNum: 1,
    }, function () {
      this.getinoutList()
    })
  }

  //查询
  recordquery = () => {
    this.setState({
      recordpageNum: 1
    }, function () {
      this.getinoutList()
    })

  }


  //出入记录结束

  render () {
    const prooptions = this.state.unitlist.map((province) => <Option key={province.id}  >{province.name}</Option>);

    const unnormalColumns = this.unnormalColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    const recordColumns = this.recordColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });



    const listion = <img src={require('../images/close.png')} alt="" />
    // tabs各项内容定义
    const items = [
      {
        label: '管理提醒',
        key: '1',
        children: <div style={{ background: 'white', padding: '20px' }}>
          &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
          <Select
            style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
            placeholder="请选择所属单位"
            onChange={this.changeunits}
            value={this.state.siteIds}
            allowClear
          >
            {prooptions}
          </Select>
          &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
          <RangePicker style={{ marginRight: '20px' }}
            onChange={this.alarmtime}
            disabledDate={disabledDate}
            ranges={{
              "今日": [moment(), moment()],
              '本月': [moment().startOf('month'), moment().endOf('month')],
            }}
          />
          <Button type="primary" onClick={this.inoutquery}>查询</Button>
          <Table
            dataSource={this.state.unnormallist}
            columns={unnormalColumns}
            pagination={false}
            rowKey={record => record.id}
          />
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Pagination
              onShowSizeChange={this.onShowSizeChange}
              defaultCurrent={1}
              onChange={this.unnormalpagechange}
              total={this.state.unnormatotal}
              hideOnSinglePage={true}
              current={this.state.pageNums}
            />
          </div>
        </div>
      },
      {
        label: '风险推送',
        key: '2',
        children: <div style={{ background: 'white', padding: '20px' }}>
          <div>
            &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
            <Select
              style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
              placeholder="请选择所属单位"
              onChange={this.changeunit}
              value={this.state.siteId}
              allowClear
            >
              {prooptions}
            </Select>
            &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
            <RangePicker style={{ marginRight: '20px' }}
              onChange={this.alarmtime}
              disabledDate={disabledDate}
              ranges={{
                "今日": [moment(), moment()],
                '本月': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <Button type="primary" onClick={this.cameraquery}>查询</Button>
            <div>
              <Table
                dataSource={this.state.alarmlist}
                columns={nodeInfoTableColumns}
                pagination={false}
                rowKey={record => record.id}
              />
            </div>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                onShowSizeChange={this.onShowSizeChange}
                defaultCurrent={1}
                onChange={this.pagechange}
                total={this.state.alarmtotal}
                hideOnSinglePage={true}
                current={this.state.pageNum}
              />
            </div>
          </div>
        </div>
      },
      {
        label: '出入记录',
        key: '3',
        children: <div style={{ background: 'white', padding: '20px' }}>
          &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
          <Select
            style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
            placeholder="请选择所属单位"
            onChange={this.recordchangeunit}
            value={this.state.recordsiteId}
            allowClear
          >
            {prooptions}
          </Select>
          &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
          <RangePicker style={{ marginRight: '20px' }}
            onChange={this.alarmtime}
            disabledDate={disabledDate}
            ranges={{
              "今日": [moment(), moment()],
              '本月': [moment().startOf('month'), moment().endOf('month')],
            }}
          />
          <Button type="primary" onClick={this.recordquery}>查询</Button>
          <Table
            rowKey={record => record.id}
            dataSource={this.state.recordlist}
            columns={recordColumns}
            pagination={false}
          />
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Pagination
              onShowSizeChange={this.onShowSizeChange}
              defaultCurrent={1}
              onChange={this.recordpagechange}
              total={this.state.total}
              hideOnSinglePage={true}
              current={this.state.recordpageNum}
            />
          </div>
        </div>
      }
    ]

    return (
      <Layout id="log" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <div>
              <Tabs defaultActiveKey="1" onChange={this.tabchange} items={items}>
                {/* <TabPane tab="管理提醒" key="1">
                  <div style={{ background: 'white', padding: '20px' }}>
                    &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
                    <Select
                      style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                      placeholder="请选择所属单位"
                      onChange={this.changeunits}
                      value={this.state.siteIds}
                      allowClear
                    >
                      {prooptions}
                    </Select>
                    &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
                    <RangePicker style={{ marginRight: '20px' }}
                      onChange={this.alarmtime}
                      disabledDate={disabledDate}
                      ranges={{
                        "今日": [moment(), moment()],
                        '本月': [moment().startOf('month'), moment().endOf('month')],
                      }}
                    />
                    <Button type="primary" onClick={this.inoutquery}>查询</Button>
                    <Table
                      dataSource={this.state.unnormallist}
                      columns={unnormalColumns}
                      pagination={false}
                      rowKey={record => record.id}
                    />
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                      <Pagination
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={1}
                        onChange={this.unnormalpagechange}
                        total={this.state.unnormatotal}
                        hideOnSinglePage={true}
                        current={this.state.pageNums}
                      />
                    </div>
                  </div>
                </TabPane> */}
                {/* <TabPane tab="风险推送" key="2">
                  <div style={{ background: 'white', padding: '20px' }}>
                    <div>
                      &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
                      <Select
                        style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                        placeholder="请选择所属单位"
                        onChange={this.changeunit}
                        value={this.state.siteId}
                        allowClear
                      >
                        {prooptions}
                      </Select>
                      &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
                      <RangePicker style={{ marginRight: '20px' }}
                        onChange={this.alarmtime}
                        disabledDate={disabledDate}
                        ranges={{
                          "今日": [moment(), moment()],
                          '本月': [moment().startOf('month'), moment().endOf('month')],
                        }}
                      />
                      <Button type="primary" onClick={this.cameraquery}>查询</Button>
                      <div>
                        <Table
                          dataSource={this.state.alarmlist}
                          columns={nodeInfoTableColumns}
                          pagination={false}
                          rowKey={record => record.id}
                        />
                      </div>
                      <div style={{ textAlign: 'right', marginTop: '10px' }}>
                        <Pagination
                          onShowSizeChange={this.onShowSizeChange}
                          defaultCurrent={1}
                          onChange={this.pagechange}
                          total={this.state.alarmtotal}
                          hideOnSinglePage={true}
                          current={this.state.pageNum}
                        />
                      </div>
                    </div>
                  </div>
                </TabPane> */}
                {/* <TabPane tab="出入记录" key="3">
                  <div style={{ background: 'white', padding: '20px' }}>
                    &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
                    <Select
                      style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                      placeholder="请选择所属单位"
                      onChange={this.recordchangeunit}
                      value={this.state.recordsiteId}
                      allowClear
                    >
                      {prooptions}
                    </Select>
                    &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
                    <RangePicker style={{ marginRight: '20px' }}
                      onChange={this.alarmtime}
                      disabledDate={disabledDate}
                      ranges={{
                        "今日": [moment(), moment()],
                        '本月': [moment().startOf('month'), moment().endOf('month')],
                      }}
                    />
                    <Button type="primary" onClick={this.recordquery}>查询</Button>
                    <Table
                      rowKey={record => record.id}
                      dataSource={this.state.recordlist}
                      columns={recordColumns}
                      pagination={false}
                    />
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                      <Pagination
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={1}
                        onChange={this.recordpagechange}
                        total={this.state.total}
                        hideOnSinglePage={true}
                        current={this.state.recordpageNum}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="人员驻留" key="4">
                  <Stay onRef={node => this.Child = node} unitlist={this.state.unitlist} />
                </TabPane> */}
              </Tabs>

            </div>
          </Content>
          <Modal
            title="删除记录"
            open={this.state.deleteunitvisible}
            onOk={this.deleteunit}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该记录吗？
          </Modal>
          <Modal
            title="抓拍照片"
            open={this.state.imgvisible}
            width="500px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            <div>
              {this.state.cameraimg.map((item, index) => {
                return (
                  <img key={index} src={item} alt="" style={{ width: '100%' }} />
                )
              })}
            </div>
          </Modal>
          <Modal
            title="人脸照片"
            open={this.state.imgvisibles}
            width="500px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            <img src={this.state.doorimg} alt="" style={{ width: '100%' }} />
          </Modal>
          <Modal
            title="添加说明"
            destroyOnClose
            onOk={this.explainok}
            open={this.state.describevisible}
            centered
            width='400px'
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <TextArea rows={4} style={{ width: '100%', height: '200px' }}
              onChange={this.remarkchange}
              value={this.state.remark}
              maxLength="200"
              placeholder="请输入说明"
            />
            <span style={{ position: 'relative', left: '295px', bottom: '27px' }}>{this.state.remark.length}/200</span>
          </Modal>
          <Modal
            title="报警详情"
            open={this.state.detailvisible}
            width="300px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            {this.state.number || 1}人进入手术室
          </Modal>
          <Modal
            title="处置详情"
            open={this.state.chuzhivisible}
            width="350px"
            centered
            onCancel={this.handleCancel}
            footer={null}
            closeIcon={listion}
          >
            <div className="resultflex">
              <span>处置详情</span>
              <span className="resultright">{this.state.result}</span>
            </div>
            <div className="resultflex">
              <span>处置人员</span>
              <span className="resultright">{this.state.resultpeople}</span>
            </div>
            <div className="resultflex">
              <span>处置时间</span>
              <span className="resultright">{this.state.resulttime}</span>
            </div>

          </Modal>
          <Modal
            title="删除记录"
            open={this.state.alarmdeletevisible}
            onOk={this.deletedevice}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该条记录吗？
          </Modal>
        </Layout>
      </Layout >
    );
  }
}

export default App;
