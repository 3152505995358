import React from "react";
import {
  Card,
  Layout,
  Button,
  Input,
  Table,
  Modal,
  message, Select
} from "antd";

import { deleteinoutlist, menulist, addmenu, editmenu, deletemenu } from '../axios';

import "./power.scss";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;


const menutype = {
  "C": "菜单",
  "M": "目录",
  "F": "按钮"
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoListDataSource: [],
      device_ip: null,
      scenetimelist: [],
      devicealarmlist: [],
      typenone: "inline-block",
      pageNum: 1,
      pageNumSize: 10,
      doorimg: [],
      cameraimg: [],
      unitlist: [],
      powerlist: [],
      parentlist: [],
    };

    this.nodeInfoTableColumns = [
      {
        title: "权限名称",
        dataIndex: "menuName",
        align: 'left',
      }, {
        title: "父级菜单",
        dataIndex: "parentName",
        render: (text) => {
          if (text) {
            return (
              <div>
                {text}
              </div>
            )

          } else {
            return (
              <div>
                /
              </div>
            )
          }

        }
      }
      // , {
      //     title: "接口地址",
      //     dataIndex: "component",
      // }
      , {
        title: "菜单类型",
        dataIndex: "menuType",
        render: (text) => {
          return (
            <div>
              {menutype[text]}
            </div>
          )
        }
      }, {
        title: "对应路径",
        dataIndex: "path",
        render: (text) => {
          if (text === null || text === "" || text === undefined) {
            return (
              <div>
                /
              </div>
            )
          } else {
            return (
              <div>
                {text}
              </div>
            )
          }

        }
      },
      {
        title: '操作',
        dataIndex: 'id',
        align: 'right',
        render: (text, record, index) => {
          return (
            <div>
              <span onClick={() => this.onEdit(text, record, index)}>
                <img src={require('../images/edit.png')} alt="" />
              </span>
              <span style={{ marginLeft: '10px' }} onClick={() => this.onDelete(text, record, index)}>
                <img src={require('../images/delete.png')} alt="" />
              </span>
            </div>
          );
        }
      }
    ];
  }

  componentWillMount () {
    document.title = "权限管理";
  }

  componentDidMount () {
    this.menulist()
  }



  //关闭添加单位弹窗
  handleCancel = () => {
    this.setState({
      deleteunitvisible: false,
      imgvisible: false,
      visible: false,
      imgvisibles: false,
      changevisible: false,
      deletevisible: false,
      menuName: undefined,
      path: undefined,
      iconcls: undefined,
      ordernum: undefined,
      parentId: undefined,
      menuType: undefined,
      remark: undefined,
    })
  }


  //打开删除出入记录弹窗
  unitdelete = (text, record, index) => {
    this.setState({
      deleteunitvisible: true,
      eventId: record.id
    })
  }

  //删除单位
  deleteunit = () => {
    deleteinoutlist([
      this.state.eventId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        this.setState({
          deleteunitvisible: false,
        })
        this.getinoutList()
      } else {
        message.error(res.data.message)
      }
    });
  }


  //搜索框录入
  searchname = (e) => {
    this.setState({
      searchname: e.target.value
    })
  }





  //打开添加权限按钮
  addmenu = () => {
    this.setState({
      visible: true
    })
  }

  //权限名字录入
  keytext = (e) => {
    this.setState({
      keytext: e.target.value
    })
  }


  //父级菜单权限选择
  menuparentchange = (value) => {
    this.setState({
      parentId: value
    }, function () {
      this.menulist()
    })
  }

  menulist = () => {
    menulist([
      this.state.keytext,
      this.state.parentId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        for (var i in res.data.data) {
          res.data.data[i].children = undefined
        }
        this.setState({
          powerlist: res.data.data
        })
        var arr = [{
          "menuId": '0',
          "menuName": '所有',
        }]
        for (var k in res.data.data) {
          if (res.data.data[k].parentId === 0) {
            arr.push(res.data.data[k])
          }
        }
        //console.log()
        this.setState({
          parentlist: arr
        })
      }
    });
  }

  //查询
  query = () => {
    this.menulist()
  }

  reset = () => {
    this.setState({
      keytext: undefined,
      parentId: undefined,
    }, function () {
      this.menulist()
    })
  }

  //权限名称录入
  menuName = (e) => {
    this.setState({
      menuName: e.target.value.replace(/\s*/g, '')
    })
  }

  //前端路径录入
  path = (e) => {
    this.setState({
      path: e.target.value.replace(/\s*/g, '')
    })
  }

  //图标录入
  iconcls = (e) => {
    this.setState({
      iconcls: e.target.value.replace(/\s*/g, '')
    })
  }

  //菜单类型选择
  menuType = (value) => {
    this.setState({
      menuType: value
    })
  }

  //父级菜单选择
  parentId = (value) => {
    this.setState({
      parentId: value
    })
  }

  //备注信息录入
  remark = (e) => {
    this.setState({
      remark: e.target.value
    })
  }

  //显示顺序录入
  ordernum = (e) => {
    this.setState({
      ordernum: e.target.value.replace(/[^0-9.]/g, '') || ""
    })
  }


  //确认添加权限
  handleOk = () => {
    if (!this.state.menuName) {
      message.error('请输入菜单名称')
    } else {
      addmenu([
        this.state.menuName,
        this.state.path,
        this.state.iconcls,
        this.state.parentId,
        this.state.menuType,
        this.state.ordernum,
        this.state.remark,
      ]).then(res => {
        if (res.data && res.data.message === "success") {
          message.success('添加成功')
          this.menulist()
          this.setState({
            visible: false,
            menuName: undefined,
            path: undefined,
            iconcls: undefined,
            ordernum: undefined,
            parentId: undefined,
            menuType: undefined,
            remark: undefined,
          })
        } else {
          message.error(res.data.message)
        }
      });
    }

  }

  //打开编辑弹窗
  onEdit = (text, record, index) => {
    //console.log(record)
    this.setState({
      menuName: record.menuName,
      path: record.path,
      icon: record.icon,
      parentId: record.parentId.toString(),
      menuType: record.menuType,
      changevisible: true,
      menuId: record.menuId,
      ordernum: record.orderNum,
    })
  }

  //确认修改
  changeOk = () => {
    editmenu([
      this.state.menuName,
      this.state.path,
      this.state.iconcls,
      this.state.parentId,
      this.state.menuType,
      this.state.ordernum,
      this.state.menuId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('修改成功')
        this.menulist()
        this.setState({
          changevisible: false,
        })
      }
    });
  }

  //打开删除弹窗
  onDelete = (text, record, index) => {
    this.setState({
      menuId: record.menuId,
      deletevisible: true,
    })
  }

  //确认删除
  deleteok = () => {
    deletemenu([
      this.state.menuId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        message.success('删除成功')
        this.menulist()
        this.setState({
          deletevisible: false,
        })
      }
    });
  }


  render () {
    const poweroption = this.state.parentlist.map((province) => <Option key={province.menuId}  >{province.menuName}</Option>);

    const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });


    const listion = <img src={require('../images/close.png')} alt="" />



    return (
      <Layout id="alarm" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="权限管理" headStyle={{ fontSize: '18px' }}
              extra={
                <div>
                  <Button type="primary" onClick={this.addmenu} style={{ display: this.state.typedis }} >
                    添加权限
                  </Button>
                </div>}
            >
              <div className="powermain">
                权限搜索&nbsp;: &nbsp;&nbsp;&nbsp;
                <Input placeholder="请输入权限"
                  className="searchmain"
                  value={this.state.keytext}
                  onChange={this.keytext}
                />
                父级权限&nbsp;: &nbsp;&nbsp;&nbsp;
                <Select
                  placeholder="请选择父级菜单"
                  onChange={this.menuparentchange}
                  value={this.state.parentId}
                  allowClear
                  className="selectmain"
                >
                  {poweroption}
                </Select>
                <Button type="primary" onClick={this.query}>查询</Button>
                <Button onClick={this.reset} className="ml20">所有权限</Button>
                <Table
                  rowKey={record => record.menuId}
                  dataSource={this.state.powerlist}
                  columns={nodeInfoTableColumns}
                />
              </div>
            </Card>
          </Content>
          <Modal
            title="删除记录"
            open={this.state.deletevisible}
            onOk={this.deleteok}
            width="300px"
            okText="删除"
            centered
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            您确定要删除该记录吗？
          </Modal>
          <Modal
            title="添加权限"
            open={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            okText="确认"
            destroyOnClose
            width="400px"
            mask={false}
            closeIcon={listion}
          >
            <div>
              <div style={{ display: this.state.twoadd }}>
                <span>菜单名称：</span>
                <Input placeholder="请输入菜单名称"
                  className="inputtype"
                  autoComplete="off"
                  onChange={this.menuName}
                  value={this.state.menuName}
                />
                <span>前端路径：</span>
                <Input placeholder="请输入前端路径"
                  autoComplete="off"
                  onChange={this.path}
                  className="inputtype"
                  value={this.state.path}
                />
                <span>显示顺序：</span>
                <Input placeholder="请输入显示顺序"
                  autoComplete="off"
                  onChange={this.ordernum}
                  className="inputtype"
                  value={this.state.ordernum}
                />
                <span>图标：</span>
                <Input placeholder="请输入图标"
                  autoComplete="off"
                  className="inputtype"
                  onChange={this.iconcls}
                  value={this.state.iconcls}
                />
                {/* <div>
                  <span>是否需要权限：</span>
                  <Switch defaultChecked
                    onChange={this.requireauth}
                  />
                  <span style={{ marginLeft: '20px' }}>是否可用：</span>
                  <Switch defaultChecked
                    onChange={this.keepalive}
                  />
                </div> */}
                <span>父级菜单：</span>
                <Select
                  placeholder="请选择父级菜单"
                  onChange={this.parentId}
                  className="inputtype"
                >
                  {poweroption}
                </Select>
                <span>菜单类型：</span>
                <Select
                  placeholder="请选择菜单类型"
                  onChange={this.menuType}
                  className="inputtype"
                >
                  <Option value="M">目录</Option>
                  <Option value="C">菜单</Option>
                  <Option value="F">按钮</Option>
                </Select>
                <span>描述：</span>
                <TextArea rows={4}
                  className="inputtype"
                  onChange={this.remark}
                  value={this.state.remark}
                  placeholder="请输入描述"
                />
              </div>
            </div>
          </Modal>

          <Modal
            title="修改权限"
            open={this.state.changevisible}
            onOk={this.changeOk}
            onCancel={this.handleCancel}
            okText="确认"
            destroyOnClose
            width="400px"
            closeIcon={listion}
            mask={false}
          >
            <div>
              <div style={{ display: this.state.twoadd }}>
                <span>菜单名称：</span>
                <Input placeholder="请输入菜单名称"
                  className="inputtype"
                  autoComplete="off"
                  onChange={this.menuName}
                  value={this.state.menuName}
                />
                <span>前端路径：</span>
                <Input placeholder="请输入前端路径"
                  className="inputtype"
                  autoComplete="off"
                  onChange={this.path}
                  value={this.state.path}
                />
                <span>显示顺序：</span>
                <Input placeholder="请输入显示顺序"
                  autoComplete="off"
                  onChange={this.ordernum}
                  className="inputtype"
                  value={this.state.ordernum}
                />
                <span>图标：</span>
                <Input placeholder="请输入图标"
                  className="inputtype"
                  autoComplete="off"
                  onChange={this.iconcls}
                  value={this.state.iconcls}
                />
                <span>父级菜单：</span>
                <Select
                  placeholder="请选择父级菜单"
                  onChange={this.parentId}
                  value={this.state.parentId}
                  className="inputtype"
                >
                  {poweroption}
                </Select>
                <span>菜单类型：</span>
                <Select
                  className="inputtype"
                  placeholder="请选择菜单类型"
                  onChange={this.menuType}
                  value={this.state.menuType}
                >
                  <Option value="M">目录</Option>
                  <Option value="C">菜单</Option>
                  <Option value="F">按钮</Option>
                </Select>
              </div>
            </div>
          </Modal>
        </Layout>
      </Layout >
    );
  }
}

export default App;
