import React from "react";
import {
  Table,
  Layout,
  Button,
  Pagination,
  message, Modal, DatePicker, Select, Card
} from "antd";
import { getunitList, getReport, getinoutLists, getalarmList, getinoutList } from '../axios';


import "./workdetail.scss";
import moment from 'moment';
// import { func } from "prop-types";

const { Content } = Layout;
// const { RangePicker } = DatePicker;
const { RangePicker } = DatePicker;
const { Option } = Select;
function disabledDate (current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoListDataSource: [],
      inoutlist: [],
      alarmlist: [],
      device_ip: null,
      typenone: "inline-block",
      pageNum: 1,
      inoutpageNum: 1,
      alarmpageNum: 1,
      anonymouspageNum: 1,
      pageNumSize: 10,
      pageNums: 1,
      pageNumSizes: 10,
      deviceList: JSON.parse(localStorage.getItem('unitTree')),
      deviceLists: JSON.parse(localStorage.getItem('unitTree')),
      imglist: [],
      videourl: undefined,
      alarmimg: [],
      anonymousimg: [],
      remark: [],
      unitlist: [],
    };


    this.nodeInfoTableColumns = [
      {
        title: "单位名称",
        dataIndex: "unitName",
        align: 'left',
        render: (text, record, index) => {
          return (
            <div>
              {text}
            </div>
          )
        }
      },
      {
        title: "工作时间",
        dataIndex: "date",
        align: 'left',
      },
      {
        title: "管理提醒数量",
        dataIndex: "anonymousSum",
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.anonymousdetail(text, record, index)}>
              {text || 0}
            </div>
          );
        }
      }, {
        title: "风险推送数量",
        dataIndex: "alarmSum",
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.alarmdetail(text, record, index)}>
              {text || 0}
            </div>
          );
        }
      }, {
        title: "出入记录数量",
        dataIndex: "inOutSum",
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }} onClick={() => this.inOutdetail(text, record, index)}>
              {text || 0}
            </div>
          );
        }
      }, {
        title: "报告",
        dataIndex: "eventCardNo",
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }}
              onClick={() => this.lookreport(text, record, index)}
            >
              查看
            </div>
          )
        }
      }
    ];


  }

  componentWillMount () {
    document.title = "工作详情";
  }

  componentDidMount () {
    this.reportlist()
    this.getunitList()
  }

  reportlist = () => {
    getReport([
      this.state.pageNum,
      this.state.pageNumSize,
      this.state.siteId,
      this.state.begintime,
      this.state.endtime,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          reportlist: res.data.data.list,
          reporttotal: res.data.data.total
        })
      }
    });
  }




  //关闭
  handleCancel = () => {
    this.setState({
      inoutvisible: false,
      alarmvisible: false,
      siteId: undefined,
      alarmimg: [],
      inoutimg: undefined,
      anonymousvisible: undefined,
      anonymousimg: [],
    })
  }



  //查询
  cameraquery = () => {
    this.setState({
      pageNum: 1
    }, function () {
      this.reportlist()
    })

  }



  //摄像头页数变化
  pagechange = (page, b) => {
    //console.log(page, b)
    this.setState({
      pageNum: page,
      pageNumSize: b,
    }, function () {
      this.reportlist()
    })
  }




  //报警时间筛选
  alarmtime = (value, b) => {
    if (!value) {
      this.setState({
        begintime: null,
        endtime: null
      })
    } else {
      this.setState({
        begintime: moment(value[0]).format("YYYY-MM-DD 00:00:00"),
        endtime: moment(value[1]).format("YYYY-MM-DD 23:59:59"),
      })
    }

  }





  //获取单位列表
  getunitList = () => {
    getunitList([
      localStorage.getItem('token'),
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        var arr = []
        for (var i in res.data.data) {
          arr.push({
            'id': res.data.data[i].id,
            'name': res.data.data[i].unit,
          })
        }
        this.setState({
          unitlist: arr
        })
      }
    });
  }



  //选择单位
  changeunit = (value) => {
    this.setState({
      siteId: value,
      pageNum: 1,
    }, function () {
      this.reportlist()
    })
  }


  //查看出入记录详情
  inOutdetail = (text, record, index) => {
    //console.log(record)
    this.setState({
      time: record.date,
      siteId: record.unitId,
      inoutvisible: true,
    }, function () {
      this.inoutlist()
    })
  }

  inoutlist = () => {
    getinoutLists([
      localStorage.getItem('token'),
      this.state.inoutpageNum,
      this.state.pageNumSize,
      this.state.siteId,
      moment(this.state.time).format('YYYY-MM-DD 00:00:00'),
      moment(this.state.time).format('YYYY-MM-DD 23:59:59'),
      1,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          inoutlist: res.data.data.list,
          inoutotal: res.data.data.total,
        })
      }
    });
  }

  //出入记录页数变化
  inoutpagechange = (page, b) => {
    //console.log(page, b)
    this.setState({
      inoutpageNum: page,
      pageNumSize: b,
    }, function () {
      this.inoutlist()
    })
  }

  //查看出入记录照片
  lookinoutimg = (text, record, index) => {
    this.setState({
      inoutimg: "http://scym.terabits.cn" + JSON.parse(text)[0],
    })
  }

  //查看风险推送记录详情
  alarmdetail = (text, record, index) => {
    this.setState({
      alarmvisible: true,
      time: record.date,
      siteId: record.unitId,
    }, function () {
      this.alarmlist()
    })
  }






  alarmlist = () => {
    getalarmList([
      localStorage.getItem('token'),
      this.state.alarmpageNum,
      this.state.pageNumSize,
      moment(this.state.time).format('YYYY-MM-DD 00:00:00'),
      moment(this.state.time).format('YYYY-MM-DD 23:59:59'),
      null,
      this.state.siteId,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          alarmlist: res.data.data.list,
          alarmtotal: res.data.data.total
        })
      }
    });
  }


  //出入记录页数变化
  alarmpagechange = (page, b) => {
    //console.log(page, b)
    this.setState({
      alarmpageNum: page,
      pageNumSize: b,
    }, function () {
      this.inoutlist()
    })
  }







  //查看风险推送照片
  lookalarmimg = (text, record, index) => {
    var arr = []
    if (JSON.parse(record.alarmPicture).length === 0) {
      message.error('暂无图片')
    } else {
      for (var i in JSON.parse(record.alarmPicture)) {
        arr.push("http://scym.terabits.cn" + JSON.parse(record.alarmPicture)[i])
      }
    }
    //console.log(arr)
    this.setState({
      alarmimg: arr
    })
  }

  //查看管理提醒照片
  lookanonymousimg = (text, record, index) => {
    var arr = []
    if (JSON.parse(record.cameraImages).length === 0) {
      message.error('暂无图片')
    } else {
      for (var i in JSON.parse(record.cameraImages)) {
        arr.push("http://scym.terabits.cn" + JSON.parse(record.cameraImages)[i])
      }
    }
    this.setState({
      anonymousimg: arr
    })
  }





  //管理提醒页数变化
  anonymouspagechange = (page, b) => {
    //console.log(page, b)
    this.setState({
      anonymouspageNum: page,
      pageNumSize: b,
    }, function () {
      this.anonymouslist()
    })
  }

  //查看管理提醒记录详情
  anonymousdetail = (text, record, index) => {
    this.setState({
      anonymousvisible: true,
      time: record.date,
      siteId: record.unitId,
      anonymouspageNum: 1,
    }, function () {
      this.anonymouslist()
    })
  }

  //查看报告
  lookreport = (text, record, index) => {
    //console.log(record)
    localStorage.setItem('unitname', record.unitName)
    localStorage.setItem('unitdate', record.date)
    localStorage.setItem('siteId', record.unitId)
    window.location.href = '/app/workdreport';
  }


  anonymouslist = () => {
    getinoutList([
      localStorage.getItem('token'),
      this.state.anonymouspageNum,
      this.state.pageNumSize,
      this.state.cityid,
      this.state.areaid,
      this.state.siteId,
      moment(this.state.time).format('YYYY-MM-DD 00:00:00'),
      moment(this.state.time).format('YYYY-MM-DD 23:59:59'),
      undefined,
      2,
    ]).then(res => {
      if (res.data && res.data.message === "success") {
        this.setState({
          anonymouslist: res.data.data.list,
          anonymoustotal: res.data.data.total,
        })
      }
    });
  }


  render () {
    const listion = <img src={require('../images/close.png')} alt="" />
    const prooptions = this.state.unitlist.map((province) => <Option key={province.id}  >{province.name}</Option>);
    const nodeInfoTableColumns = this.nodeInfoTableColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });



    const inoutColumns = [
      {
        title: "位置",
        dataIndex: "deviceLocation",
        align: 'left',
      },
      {
        title: "出入人员",
        dataIndex: "eventUserName",
        align: 'left',
      },
      {
        title: "人员身份",
        dataIndex: "eventPosition",
        align: 'left',
      },
      {
        title: "出入时间",
        dataIndex: "gmtOccur",
        align: 'left',
      },
      {
        title: "查看照片",
        dataIndex: "doorImages",
        align: 'right',
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }}
              onClick={() => this.lookinoutimg(text, record, index)}
            >
              查看
            </div>
          )
        }
      }
    ];


    const alarmColumns = [
      {
        title: "位置",
        dataIndex: "devicePosition",
        align: 'left',
      },
      {
        title: "出入人员",
        dataIndex: "eventUserName",
        align: 'left',
      },
      {
        title: "人员身份",
        dataIndex: "eventPosition",
        align: 'left',
      },
      {
        title: "告警时间",
        dataIndex: "gmtOccur",
        align: 'left',
      },
      {
        title: "报警图片",
        dataIndex: "doorImages",
        align: 'right',
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }}
              onClick={() => this.lookalarmimg(text, record, index)}
            >
              查看
            </div>
          )
        }
      }
    ];


    const anonymousColumns = [
      {
        title: "位置",
        dataIndex: "deviceLocation",
        align: 'left',
      },
      {
        title: "出入人员",
        dataIndex: "userName",
        align: 'left',
      },
      {
        title: "卡号",
        dataIndex: "eventCardNo",
        align: 'left',
      },
      {
        title: "告警时间",
        dataIndex: "gmtOccur",
        align: 'left',
      },
      {
        title: "报警图片",
        dataIndex: "doorImages",
        align: 'right',
        render: (text, record, index) => {
          return (
            <div style={{ color: '#11A7D7', cursor: 'pointer' }}
              onClick={() => this.lookanonymousimg(text, record, index)}
            >
              查看
            </div>
          )
        }
      }
    ];



    return (
      <Layout id="alarm" >
        <Layout>
          <Content style={{ margin: "20px 0px" }} >
            <Card title="工作详情" headStyle={{ fontSize: '18px' }}>
              <div>
                <div>
                  &nbsp;&nbsp;&nbsp;选择单位&nbsp;: &nbsp;&nbsp;&nbsp;
                  <Select
                    style={{ width: '250px', marginBottom: "20px", marginRight: '20px' }}
                    placeholder="请选择所属单位"
                    onChange={this.changeunit}
                    value={this.state.siteId}
                    allowClear
                  >
                    {prooptions}
                  </Select>
                  &nbsp;&nbsp;告警时间&nbsp;: &nbsp;&nbsp;&nbsp;
                  <RangePicker style={{ marginRight: '20px' }}
                    onChange={this.alarmtime}
                    disabledDate={disabledDate}
                    ranges={{
                      "今日": [moment(), moment()],
                      '本月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                  />
                  <Button type="primary" onClick={this.cameraquery}>查询</Button>
                  <div>
                    <Table
                      dataSource={this.state.reportlist}
                      columns={nodeInfoTableColumns}
                      pagination={false}
                      rowKey={record => record.unitId + Math.random()}
                    />
                  </div>
                  <div style={{ textAlign: 'right', marginTop: '10px' }}>
                    <Pagination
                      onShowSizeChange={this.onShowSizeChange}
                      defaultCurrent={1}
                      onChange={this.pagechange}
                      total={this.state.reporttotal}
                      hideOnSinglePage={true}
                      current={this.state.pageNum}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </Content>
          <Modal
            title="出入记录详情"
            destroyOnClose
            footer={null}
            open={this.state.inoutvisible}
            centered
            width='600px'
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <div>
              <Table
                dataSource={this.state.inoutlist}
                columns={inoutColumns}
                pagination={false}
                rowKey={record => record.id}
              />
            </div>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                onShowSizeChange={this.onShowSizeChange}
                defaultCurrent={1}
                onChange={this.inoutpagechange}
                total={this.state.inoutotal}
                hideOnSinglePage={true}
                current={this.state.inoutpageNum}
              />
            </div>
            <img src={this.state.inoutimg} alt="" style={{ width: "100%" }} />
          </Modal>

          <Modal
            title="风险推送详情"
            destroyOnClose
            footer={null}
            open={this.state.alarmvisible}
            centered
            width='600px'
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <div>
              <Table
                dataSource={this.state.alarmlist}
                columns={alarmColumns}
                pagination={false}
                rowKey={record => record.id}
              />
            </div>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                defaultCurrent={1}
                onChange={this.alarmpagechange}
                total={this.state.alarmotal}
                hideOnSinglePage={true}
                current={this.state.alarmpageNum}
              />
            </div>
            {/* <Carousel autoplay
                        >
                            {this.state.alarmimg.map((item) => {
                                return (
                                    <div>
                                        <img src={item} alt="" style={{ width: "100%", height: '310px' }} />
                                    </div>
                                )
                            })}
                        </Carousel> */}
            {this.state.alarmimg.map((item) => {
              return (
                <img src={item} alt="" style={{ width: '100%' }} />
              )
            })}

          </Modal>

          <Modal
            title="管理提醒详情"
            destroyOnClose
            footer={null}
            open={this.state.anonymousvisible}
            centered
            width='600px'
            onCancel={this.handleCancel}
            closeIcon={listion}
          >
            <div>
              <Table
                dataSource={this.state.anonymouslist}
                columns={anonymousColumns}
                pagination={false}
                rowKey={record => record.id}
              />
            </div>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Pagination
                defaultCurrent={1}
                onChange={this.anonymouspagechange}
                total={this.state.anonymoustotal}
                hideOnSinglePage={true}
                current={this.state.anonymouspageNum}
              />
            </div>
            {this.state.anonymousimg.map((item, index) => {
              return (
                <img src={item} alt="" key={index} style={{ width: '100%' }} />
              )
            })}

          </Modal>


        </Layout>
      </Layout >
    );
  }
}

export default App;
